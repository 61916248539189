import { useCallback } from "react";
import { FieldPath, FieldValues, useFormContext } from "react-hook-form";

import { RadioInput } from "../RadioInput";
import { RadioInputsGroup, RadioInputsGroupItem, RadioInputsGroupProps } from "../RadioInputsGroup";

type Props<Data extends FieldValues = FieldValues> = {
  name: FieldPath<Data>;
} & RadioInputsGroupProps;

export const RadioControlsGroup = <Data extends FieldValues = FieldValues>({
  name,
  ...props
}: Props<Data>) => {
  const { register, formState, getFieldState } = useFormContext<Data>();
  const fieldState = getFieldState(name, formState);

  const renderItem = useCallback((
    item: RadioInputsGroupItem,
    index: number,
    props?: React.InputHTMLAttributes<HTMLInputElement>
  ) => {
    return (
      <RadioInput
        {...props}
        {...register(name)}
        key={item.value}
        value={item.value}
        label={item.label}
      />
    );
  }, [name]);

  return (
    <RadioInputsGroup
      {...props}
      renderItem={renderItem}
      invalid={!!fieldState.error}
      error={fieldState.error?.message}
    />
  );
};
