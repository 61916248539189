/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostProposalSupportRequestSupport
 */
export interface PostProposalSupportRequestSupport {
    /**
     * 
     * @type {number}
     * @memberof PostProposalSupportRequestSupport
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof PostProposalSupportRequestSupport
     */
    currency?: PostProposalSupportRequestSupportCurrencyEnum;
}


/**
 * @export
 */
export const PostProposalSupportRequestSupportCurrencyEnum = {
    Jpy: 'jpy',
    Usd: 'usd'
} as const;
export type PostProposalSupportRequestSupportCurrencyEnum = typeof PostProposalSupportRequestSupportCurrencyEnum[keyof typeof PostProposalSupportRequestSupportCurrencyEnum];


/**
 * Check if a given object implements the PostProposalSupportRequestSupport interface.
 */
export function instanceOfPostProposalSupportRequestSupport(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amount" in value;

    return isInstance;
}

export function PostProposalSupportRequestSupportFromJSON(json: any): PostProposalSupportRequestSupport {
    return PostProposalSupportRequestSupportFromJSONTyped(json, false);
}

export function PostProposalSupportRequestSupportFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostProposalSupportRequestSupport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
    };
}

export function PostProposalSupportRequestSupportToJSON(value?: PostProposalSupportRequestSupport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'currency': value.currency,
    };
}

