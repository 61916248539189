/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Inquiry
 */
export interface Inquiry {
    /**
     * 
     * @type {string}
     * @memberof Inquiry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Inquiry
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Inquiry
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Inquiry
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof Inquiry
     */
    kind: InquiryKindEnum;
    /**
     * 
     * @type {string}
     * @memberof Inquiry
     */
    status: InquiryStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof Inquiry
     */
    postedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Inquiry
     */
    closedAt?: Date;
}


/**
 * @export
 */
export const InquiryKindEnum = {
    Inquiry: 'inquiry',
    Contact: 'contact',
    BugReport: 'bug_report',
    FeatureRequest: 'feature_request',
    Other: 'other'
} as const;
export type InquiryKindEnum = typeof InquiryKindEnum[keyof typeof InquiryKindEnum];

/**
 * @export
 */
export const InquiryStatusEnum = {
    Waiting: 'waiting',
    Processing: 'processing',
    Closed: 'closed'
} as const;
export type InquiryStatusEnum = typeof InquiryStatusEnum[keyof typeof InquiryStatusEnum];


/**
 * Check if a given object implements the Inquiry interface.
 */
export function instanceOfInquiry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "message" in value;
    isInstance = isInstance && "kind" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "postedAt" in value;

    return isInstance;
}

export function InquiryFromJSON(json: any): Inquiry {
    return InquiryFromJSONTyped(json, false);
}

export function InquiryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Inquiry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'email': json['email'],
        'message': json['message'],
        'kind': json['kind'],
        'status': json['status'],
        'postedAt': (new Date(json['posted_at'])),
        'closedAt': !exists(json, 'closed_at') ? undefined : (new Date(json['closed_at'])),
    };
}

export function InquiryToJSON(value?: Inquiry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'email': value.email,
        'message': value.message,
        'kind': value.kind,
        'status': value.status,
        'posted_at': (value.postedAt.toISOString()),
        'closed_at': value.closedAt === undefined ? undefined : (value.closedAt.toISOString()),
    };
}

