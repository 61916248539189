/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Checkout } from './Checkout';
import {
    CheckoutFromJSON,
    CheckoutFromJSONTyped,
    CheckoutToJSON,
} from './Checkout';
import type { Topic } from './Topic';
import {
    TopicFromJSON,
    TopicFromJSONTyped,
    TopicToJSON,
} from './Topic';

/**
 * 
 * @export
 * @interface CallbackTopicCheckout200ResponseAllOfData
 */
export interface CallbackTopicCheckout200ResponseAllOfData {
    /**
     * 
     * @type {Topic}
     * @memberof CallbackTopicCheckout200ResponseAllOfData
     */
    topic: Topic;
    /**
     * 
     * @type {Checkout}
     * @memberof CallbackTopicCheckout200ResponseAllOfData
     */
    checkout: Checkout;
}

/**
 * Check if a given object implements the CallbackTopicCheckout200ResponseAllOfData interface.
 */
export function instanceOfCallbackTopicCheckout200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "topic" in value;
    isInstance = isInstance && "checkout" in value;

    return isInstance;
}

export function CallbackTopicCheckout200ResponseAllOfDataFromJSON(json: any): CallbackTopicCheckout200ResponseAllOfData {
    return CallbackTopicCheckout200ResponseAllOfDataFromJSONTyped(json, false);
}

export function CallbackTopicCheckout200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CallbackTopicCheckout200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'topic': TopicFromJSON(json['topic']),
        'checkout': CheckoutFromJSON(json['checkout']),
    };
}

export function CallbackTopicCheckout200ResponseAllOfDataToJSON(value?: CallbackTopicCheckout200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'topic': TopicToJSON(value.topic),
        'checkout': CheckoutToJSON(value.checkout),
    };
}

