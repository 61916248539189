/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateStripeAccount201ResponseAllOfData } from './CreateStripeAccount201ResponseAllOfData';
import {
    CreateStripeAccount201ResponseAllOfDataFromJSON,
    CreateStripeAccount201ResponseAllOfDataFromJSONTyped,
    CreateStripeAccount201ResponseAllOfDataToJSON,
} from './CreateStripeAccount201ResponseAllOfData';

/**
 * 
 * @export
 * @interface CreateStripeAccount201Response
 */
export interface CreateStripeAccount201Response {
    /**
     * 
     * @type {CreateStripeAccount201ResponseAllOfData}
     * @memberof CreateStripeAccount201Response
     */
    data: CreateStripeAccount201ResponseAllOfData;
    /**
     * 
     * @type {object}
     * @memberof CreateStripeAccount201Response
     */
    meta: object;
}

/**
 * Check if a given object implements the CreateStripeAccount201Response interface.
 */
export function instanceOfCreateStripeAccount201Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function CreateStripeAccount201ResponseFromJSON(json: any): CreateStripeAccount201Response {
    return CreateStripeAccount201ResponseFromJSONTyped(json, false);
}

export function CreateStripeAccount201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateStripeAccount201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': CreateStripeAccount201ResponseAllOfDataFromJSON(json['data']),
        'meta': json['meta'],
    };
}

export function CreateStripeAccount201ResponseToJSON(value?: CreateStripeAccount201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': CreateStripeAccount201ResponseAllOfDataToJSON(value.data),
        'meta': value.meta,
    };
}

