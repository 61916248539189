import { immerable } from "immer";

import * as api from "shared/services/api/web/models";

export type TopicNoteType = api.TopicNote;

export type TopicNoteBodyType = api.TopicNoteBodyTypeEnum;
export const TopicNoteBodyType = api.TopicNoteBodyTypeEnum;

export class TopicNote implements TopicNoteType {
  [immerable] = true;

  id = "";
  body = "";
  bodyText = "";
  bodyData = {};
  bodyType: TopicNoteBodyType = TopicNoteBodyType.Editorjs;
  postedAt = new Date();

  constructor(data: Partial<TopicNoteType> = {}) {
    Object.assign(this, data);
  }
}
