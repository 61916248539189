/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BankAccount } from './BankAccount';
import {
    BankAccountFromJSON,
    BankAccountFromJSONTyped,
    BankAccountToJSON,
} from './BankAccount';

/**
 * 
 * @export
 * @interface GetUserBankAccounts200ResponseAllOfData
 */
export interface GetUserBankAccounts200ResponseAllOfData {
    /**
     * 
     * @type {Array<BankAccount>}
     * @memberof GetUserBankAccounts200ResponseAllOfData
     */
    bankAccounts: Array<BankAccount>;
}

/**
 * Check if a given object implements the GetUserBankAccounts200ResponseAllOfData interface.
 */
export function instanceOfGetUserBankAccounts200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "bankAccounts" in value;

    return isInstance;
}

export function GetUserBankAccounts200ResponseAllOfDataFromJSON(json: any): GetUserBankAccounts200ResponseAllOfData {
    return GetUserBankAccounts200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetUserBankAccounts200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserBankAccounts200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bankAccounts': ((json['bank_accounts'] as Array<any>).map(BankAccountFromJSON)),
    };
}

export function GetUserBankAccounts200ResponseAllOfDataToJSON(value?: GetUserBankAccounts200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bank_accounts': ((value.bankAccounts as Array<any>).map(BankAccountToJSON)),
    };
}

