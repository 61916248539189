import { forwardRef } from "react";

import { twClassNames } from "shared/lib/tailwind";

import { InputGroup, BaseTextInputGroupProps } from "../InputGroup";
import { TextInput } from "../TextInput";

export type NumberInputGroupProps = Omit<BaseTextInputGroupProps, "input">;

export const NumberInputGroup = forwardRef<HTMLInputElement, NumberInputGroupProps>(function NumberInputGroup(props, ref) {
  const { inputClassName, ...rest } = props;

  return (
    <InputGroup
      {...rest}
      ref={ref}
      type="number"
      inputMode="numeric"
      input={TextInput}
      inputClassName={twClassNames(inputClassName)}
    />
  );
});
