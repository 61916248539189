/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignUpUserRequestUser
 */
export interface SignUpUserRequestUser {
    /**
     * 
     * @type {string}
     * @memberof SignUpUserRequestUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserRequestUser
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserRequestUser
     */
    passwordConfirmation: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserRequestUser
     */
    nickname: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserRequestUser
     */
    gender?: SignUpUserRequestUserGenderEnum;
    /**
     * 
     * @type {Date}
     * @memberof SignUpUserRequestUser
     */
    birthday?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof SignUpUserRequestUser
     */
    emailMarketingAgreed?: boolean;
}


/**
 * @export
 */
export const SignUpUserRequestUserGenderEnum = {
    Unknown: 'unknown',
    Man: 'man',
    Woman: 'woman'
} as const;
export type SignUpUserRequestUserGenderEnum = typeof SignUpUserRequestUserGenderEnum[keyof typeof SignUpUserRequestUserGenderEnum];


/**
 * Check if a given object implements the SignUpUserRequestUser interface.
 */
export function instanceOfSignUpUserRequestUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "password" in value;
    isInstance = isInstance && "passwordConfirmation" in value;
    isInstance = isInstance && "nickname" in value;

    return isInstance;
}

export function SignUpUserRequestUserFromJSON(json: any): SignUpUserRequestUser {
    return SignUpUserRequestUserFromJSONTyped(json, false);
}

export function SignUpUserRequestUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignUpUserRequestUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'password': json['password'],
        'passwordConfirmation': json['password_confirmation'],
        'nickname': json['nickname'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'birthday': !exists(json, 'birthday') ? undefined : (new Date(json['birthday'])),
        'emailMarketingAgreed': !exists(json, 'email_marketing_agreed') ? undefined : json['email_marketing_agreed'],
    };
}

export function SignUpUserRequestUserToJSON(value?: SignUpUserRequestUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'password': value.password,
        'password_confirmation': value.passwordConfirmation,
        'nickname': value.nickname,
        'gender': value.gender,
        'birthday': value.birthday === undefined ? undefined : (value.birthday.toISOString().substring(0,10)),
        'email_marketing_agreed': value.emailMarketingAgreed,
    };
}

