/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetEditorLink200ResponseMeta } from './GetEditorLink200ResponseMeta';
import {
    GetEditorLink200ResponseMetaFromJSON,
    GetEditorLink200ResponseMetaFromJSONTyped,
    GetEditorLink200ResponseMetaToJSON,
} from './GetEditorLink200ResponseMeta';

/**
 * 
 * @export
 * @interface GetEditorLink200Response
 */
export interface GetEditorLink200Response {
    /**
     * 
     * @type {number}
     * @memberof GetEditorLink200Response
     */
    success: GetEditorLink200ResponseSuccessEnum;
    /**
     * 
     * @type {string}
     * @memberof GetEditorLink200Response
     */
    link: string;
    /**
     * 
     * @type {GetEditorLink200ResponseMeta}
     * @memberof GetEditorLink200Response
     */
    meta: GetEditorLink200ResponseMeta;
}


/**
 * @export
 */
export const GetEditorLink200ResponseSuccessEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;
export type GetEditorLink200ResponseSuccessEnum = typeof GetEditorLink200ResponseSuccessEnum[keyof typeof GetEditorLink200ResponseSuccessEnum];


/**
 * Check if a given object implements the GetEditorLink200Response interface.
 */
export function instanceOfGetEditorLink200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "success" in value;
    isInstance = isInstance && "link" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function GetEditorLink200ResponseFromJSON(json: any): GetEditorLink200Response {
    return GetEditorLink200ResponseFromJSONTyped(json, false);
}

export function GetEditorLink200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEditorLink200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'link': json['link'],
        'meta': GetEditorLink200ResponseMetaFromJSON(json['meta']),
    };
}

export function GetEditorLink200ResponseToJSON(value?: GetEditorLink200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'link': value.link,
        'meta': GetEditorLink200ResponseMetaToJSON(value.meta),
    };
}

