/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Checkout } from './Checkout';
import {
    CheckoutFromJSON,
    CheckoutFromJSONTyped,
    CheckoutToJSON,
} from './Checkout';
import type { Payment } from './Payment';
import {
    PaymentFromJSON,
    PaymentFromJSONTyped,
    PaymentToJSON,
} from './Payment';
import type { Position } from './Position';
import {
    PositionFromJSON,
    PositionFromJSONTyped,
    PositionToJSON,
} from './Position';
import type { Topic } from './Topic';
import {
    TopicFromJSON,
    TopicFromJSONTyped,
    TopicToJSON,
} from './Topic';

/**
 * 
 * @export
 * @interface PostTopic201ResponseAllOfData
 */
export interface PostTopic201ResponseAllOfData {
    /**
     * 
     * @type {Topic}
     * @memberof PostTopic201ResponseAllOfData
     */
    topic: Topic;
    /**
     * 
     * @type {Array<Position>}
     * @memberof PostTopic201ResponseAllOfData
     */
    positions: Array<Position>;
    /**
     * 
     * @type {Checkout}
     * @memberof PostTopic201ResponseAllOfData
     */
    checkout?: Checkout;
    /**
     * 
     * @type {Payment}
     * @memberof PostTopic201ResponseAllOfData
     */
    payment?: Payment;
}

/**
 * Check if a given object implements the PostTopic201ResponseAllOfData interface.
 */
export function instanceOfPostTopic201ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "topic" in value;
    isInstance = isInstance && "positions" in value;

    return isInstance;
}

export function PostTopic201ResponseAllOfDataFromJSON(json: any): PostTopic201ResponseAllOfData {
    return PostTopic201ResponseAllOfDataFromJSONTyped(json, false);
}

export function PostTopic201ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostTopic201ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'topic': TopicFromJSON(json['topic']),
        'positions': ((json['positions'] as Array<any>).map(PositionFromJSON)),
        'checkout': !exists(json, 'checkout') ? undefined : CheckoutFromJSON(json['checkout']),
        'payment': !exists(json, 'payment') ? undefined : PaymentFromJSON(json['payment']),
    };
}

export function PostTopic201ResponseAllOfDataToJSON(value?: PostTopic201ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'topic': TopicToJSON(value.topic),
        'positions': ((value.positions as Array<any>).map(PositionToJSON)),
        'checkout': CheckoutToJSON(value.checkout),
        'payment': PaymentToJSON(value.payment),
    };
}

