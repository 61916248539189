/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Category } from './Category';
import {
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
} from './Category';
import type { UserThumbnailImage } from './UserThumbnailImage';
import {
    UserThumbnailImageFromJSON,
    UserThumbnailImageFromJSONTyped,
    UserThumbnailImageToJSON,
} from './UserThumbnailImage';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    type: UserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    handle?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    nickname?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    jobTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    introduction?: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    actionPoints?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    trustPoints?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    topicsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    proposalsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    commentsCount?: number;
    /**
     * 
     * @type {UserThumbnailImage}
     * @memberof User
     */
    thumbnailImage?: UserThumbnailImage;
    /**
     * 
     * @type {Array<Category>}
     * @memberof User
     */
    expertCategories?: Array<Category>;
}


/**
 * @export
 */
export const UserTypeEnum = {
    Normal: 'normal',
    Guest: 'guest',
    Anonymous: 'anonymous'
} as const;
export type UserTypeEnum = typeof UserTypeEnum[keyof typeof UserTypeEnum];


/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': json['type'],
        'handle': !exists(json, 'handle') ? undefined : json['handle'],
        'nickname': !exists(json, 'nickname') ? undefined : json['nickname'],
        'jobTitle': !exists(json, 'job_title') ? undefined : json['job_title'],
        'introduction': !exists(json, 'introduction') ? undefined : json['introduction'],
        'actionPoints': !exists(json, 'action_points') ? undefined : json['action_points'],
        'trustPoints': !exists(json, 'trust_points') ? undefined : json['trust_points'],
        'topicsCount': !exists(json, 'topics_count') ? undefined : json['topics_count'],
        'proposalsCount': !exists(json, 'proposals_count') ? undefined : json['proposals_count'],
        'commentsCount': !exists(json, 'comments_count') ? undefined : json['comments_count'],
        'thumbnailImage': !exists(json, 'thumbnail_image') ? undefined : UserThumbnailImageFromJSON(json['thumbnail_image']),
        'expertCategories': !exists(json, 'expert_categories') ? undefined : ((json['expert_categories'] as Array<any>).map(CategoryFromJSON)),
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'handle': value.handle,
        'nickname': value.nickname,
        'job_title': value.jobTitle,
        'introduction': value.introduction,
        'action_points': value.actionPoints,
        'trust_points': value.trustPoints,
        'topics_count': value.topicsCount,
        'proposals_count': value.proposalsCount,
        'comments_count': value.commentsCount,
        'thumbnail_image': UserThumbnailImageToJSON(value.thumbnailImage),
        'expert_categories': value.expertCategories === undefined ? undefined : ((value.expertCategories as Array<any>).map(CategoryToJSON)),
    };
}

