/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PostProposalSupportRequestComment } from './PostProposalSupportRequestComment';
import {
    PostProposalSupportRequestCommentFromJSON,
    PostProposalSupportRequestCommentFromJSONTyped,
    PostProposalSupportRequestCommentToJSON,
} from './PostProposalSupportRequestComment';
import type { PostProposalSupportRequestSupport } from './PostProposalSupportRequestSupport';
import {
    PostProposalSupportRequestSupportFromJSON,
    PostProposalSupportRequestSupportFromJSONTyped,
    PostProposalSupportRequestSupportToJSON,
} from './PostProposalSupportRequestSupport';

/**
 * 
 * @export
 * @interface PostProposalSupportRequest
 */
export interface PostProposalSupportRequest {
    /**
     * 
     * @type {PostProposalSupportRequestComment}
     * @memberof PostProposalSupportRequest
     */
    comment: PostProposalSupportRequestComment;
    /**
     * 
     * @type {PostProposalSupportRequestSupport}
     * @memberof PostProposalSupportRequest
     */
    support: PostProposalSupportRequestSupport;
}

/**
 * Check if a given object implements the PostProposalSupportRequest interface.
 */
export function instanceOfPostProposalSupportRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "support" in value;

    return isInstance;
}

export function PostProposalSupportRequestFromJSON(json: any): PostProposalSupportRequest {
    return PostProposalSupportRequestFromJSONTyped(json, false);
}

export function PostProposalSupportRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostProposalSupportRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comment': PostProposalSupportRequestCommentFromJSON(json['comment']),
        'support': PostProposalSupportRequestSupportFromJSON(json['support']),
    };
}

export function PostProposalSupportRequestToJSON(value?: PostProposalSupportRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comment': PostProposalSupportRequestCommentToJSON(value.comment),
        'support': PostProposalSupportRequestSupportToJSON(value.support),
    };
}

