import classNames from "classnames";
import { forwardRef, ReactNode, Ref } from "react";
import { useTranslation } from "react-i18next";

import { InputLabel } from "../InputLabel";
import { ToggleInput, ToggleInputProps } from "../ToggleInput";

export type ToggleInputGroupProps = {
  label?: ReactNode;
  renderItem?: (
    props: React.InputHTMLAttributes<HTMLInputElement>
  ) => ReactNode;
  note?: string;
  error?: string;
  invalid?: boolean;
  inputLabel?: ReactNode;
  inputClassName?: string;
  labelContainerClassName?: string;
  className?: string;
} & React.InputHTMLAttributes<HTMLInputElement> & Omit<ToggleInputProps, "label">;

export const ToggleInputGroup = forwardRef(function ToggleInputGroup({
  label,
  position = "left",
  renderItem,
  note,
  error,
  invalid,
  required,
  inputLabel,
  inputClassName,
  labelContainerClassName,
  className,
  ...props
}: ToggleInputGroupProps, ref: Ref<HTMLDivElement>) {
  const { t } = useTranslation();

  return (
    <div ref={ref} className={className}>
      {(label || required) && (
        <InputLabel label={label} required={required} className={classNames("mb-2", labelContainerClassName)} />
      )}
      {renderItem ? renderItem(props) : (
        <ToggleInput
          {...props}
          label={inputLabel}
          className={inputClassName}
        />
      )}
      {error && (
        <div className="mt-2 text-sm text-red-600">
          {t(error, { ns: "validation" })}
        </div>
      )}
      {note && (
        <div className="mt-2 text-sm text-gray-500">{note}</div>
      )}
    </div>
  );
});
