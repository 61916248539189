import { immerable } from "immer";

import * as api from "shared/services/api/web/models";

import { AttachableImageType } from "./attachable_image";
import { CategoryType } from "./category";

export type UserType = api.User;

export type UserTypeType = api.UserTypeEnum;
export const UserTypeType = api.UserTypeEnum;

export type UserThumbnailImageType = AttachableImageType & {
  webp: AttachableImageType;
};

export class User implements UserType {
  [immerable] = true;

  id = "";
  type: UserTypeType = UserTypeType.Normal;
  handle = "";
  nickname = "";
  jobTitle = "";
  introduction = "";
  topicsCount = 0;
  proposalsCount = 0;
  commentsCount = 0;
  actionPoints = 0;
  trustPoints = 0;
  thumbnailImage?: UserThumbnailImageType;
  expertCategories: CategoryType[] = [];

  constructor(data: Partial<UserType> = {}) {
    Object.assign(this, data);
  }

  getNickname() {
    if (this.isAnonymous() || this.isGuest()) return "ゲスト";

    return this.nickname;
  }

  isNormal() {
    return this.type === UserTypeType.Normal;
  }

  isGuest() {
    return this.type === UserTypeType.Guest;
  }

  isAnonymous() {
    return this.type === UserTypeType.Anonymous;
  }
}
