import { FocusEventHandler, ChangeEventHandler, ChangeEvent, FocusEvent, useCallback } from "react";
import { FieldValues, FieldPath, useFormContext } from "react-hook-form";

import { NumberInputGroup, NumberInputGroupProps } from "../NumberInputGroup";

type Props<Data extends FieldValues = FieldValues> = {
  name: FieldPath<Data>;
} & NumberInputGroupProps;

export const NumberControlGroup = <Data extends FieldValues = FieldValues, T extends HTMLInputElement = HTMLInputElement>({
  name,
  onBlur,
  onChange,
  ...props
}: Props<Data>) => {
  const { register, formState, getFieldState } = useFormContext<Data>();
  const fieldState = getFieldState(name, formState);
  const fields = register(name, { valueAsNumber: true });

  const onBlurHandler = useCallback((reactHookOnBlur: FocusEventHandler<T>): FocusEventHandler<T> => (e: FocusEvent<T>) => {
    reactHookOnBlur(e);
    onBlur?.(e);
  }, [onBlur]);

  const onChangeHandler = useCallback((reactHookOnChange: ChangeEventHandler<T>): ChangeEventHandler<T> => (e: ChangeEvent<T>) => {
    reactHookOnChange(e);
    onChange?.(e);
  }, [onChange]);

  return (
    <NumberInputGroup
      {...props}
      {...fields}
      onBlur={onBlurHandler(fields.onBlur)}
      onChange={onChangeHandler(fields.onChange)}
      invalid={!!fieldState.error}
      error={fieldState.error?.message}
    />
  );
};
