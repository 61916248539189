import { immerable } from "immer";

import { ImageType } from "./image";
import { MagazineCategory, MagazineCategoryType } from "./magazine_category";
import { MagazineEditor, MagazineEditorType } from "./magazine_editor";
import { MagazineTag, MagazineTagType } from "./magazine_tag";

export type MagazineArticleType = {
  id: number;
  title: string;
  body: string;
  slug: string;
  cover?: ImageType;
  thumbnail?: ImageType;
  editor?: MagazineEditorType;
  categories: MagazineCategoryType[];
  tags: MagazineTagType[];
  createdAt: Date;
  updatedAt: Date;
  publishAt: Date;
};

export class MagazineArticle implements MagazineArticleType {
  [immerable] = true;

  id = 0;
  title = "";
  body = "";
  slug = "";
  cover?: ImageType = undefined;
  thumbnail?: ImageType = undefined;
  editor?: MagazineEditorType = undefined;
  categories: MagazineCategoryType[] = [];
  tags: MagazineTagType[] = [];
  createdAt = new Date();
  updatedAt = new Date();
  publishAt = new Date();

  constructor(data: Partial<MagazineArticleType> = {}) {
    Object.assign(this, data);
  }

  getBodyJson<T>() {
    try {
      return JSON.parse(this.body) as T;
    } catch (e) {
      return undefined;
    }
  }

  getEditor() {
    return this.editor ? new MagazineEditor(this.editor) : undefined;
  }

  getCategories() {
    return this.categories.map((category) => new MagazineCategory(category));
  }

  getTags() {
    return this.tags.map((tag) => new MagazineTag(tag));
  }

  getPublishDate() {
    return this.publishAt || this.createdAt;
  }
}
