/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Money } from './Money';
import {
    MoneyFromJSON,
    MoneyFromJSONTyped,
    MoneyToJSON,
} from './Money';

/**
 * 
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    id: string;
    /**
     * 
     * @type {Money}
     * @memberof Payment
     */
    amount: Money;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    stripePaymentId: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    stripeClientSecret: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    status: PaymentStatusEnum;
}


/**
 * @export
 */
export const PaymentStatusEnum = {
    Pending: 'pending',
    Completed: 'completed',
    Failed: 'failed'
} as const;
export type PaymentStatusEnum = typeof PaymentStatusEnum[keyof typeof PaymentStatusEnum];


/**
 * Check if a given object implements the Payment interface.
 */
export function instanceOfPayment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "stripePaymentId" in value;
    isInstance = isInstance && "stripeClientSecret" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function PaymentFromJSON(json: any): Payment {
    return PaymentFromJSONTyped(json, false);
}

export function PaymentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Payment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'amount': MoneyFromJSON(json['amount']),
        'stripePaymentId': json['stripe_payment_id'],
        'stripeClientSecret': json['stripe_client_secret'],
        'status': json['status'],
    };
}

export function PaymentToJSON(value?: Payment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'amount': MoneyToJSON(value.amount),
        'stripe_payment_id': value.stripePaymentId,
        'stripe_client_secret': value.stripeClientSecret,
        'status': value.status,
    };
}

