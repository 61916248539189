import { immerable } from "immer";

import * as api from "shared/services/api/web/models";

export type UserProviderProvider = api.UserProviderProviderEnum;
export const UserProviderProvider = api.UserProviderProviderEnum;

export type UserProviderType = api.UserProvider;

export class UserProvider implements UserProviderType {
  [immerable] = true;

  id = "";
  provider: UserProviderProvider = UserProviderProvider.Twitter;

  constructor(data: Partial<UserProviderType> = {}) {
    Object.assign(this, data);
  }

  isTwitter() {
    return this.provider === UserProviderProvider.Twitter;
  }
}
