import { immerable } from "immer";

import * as api from "shared/services/api/web/models";

export type InquiryType = api.Inquiry;

export type InquiryKind = api.InquiryKindEnum;
export const InquiryKind = api.InquiryKindEnum;

export type InquiryStatus = api.InquiryStatusEnum;
export const InquiryStatus = api.InquiryStatusEnum;

export class Inquiry implements InquiryType {
  [immerable] = true;

  id = "";
  name = "";
  email = "";
  message = "";
  kind: InquiryKind = InquiryKind.Inquiry;
  status: InquiryStatus = InquiryStatus.Waiting;
  postedAt = new Date();
  closedAt?: Date;
  lastRepliedAt?: Date;

  constructor(data: Partial<InquiryType> = {}) {
    Object.assign(this, data);
  }

  isClosed() {
    return this.status === InquiryStatus.Closed;
  }
}
