/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentMethod } from './PaymentMethod';
import {
    PaymentMethodFromJSON,
    PaymentMethodFromJSONTyped,
    PaymentMethodToJSON,
} from './PaymentMethod';

/**
 * 
 * @export
 * @interface CreateUserPaymentMethod201ResponseAllOfData
 */
export interface CreateUserPaymentMethod201ResponseAllOfData {
    /**
     * 
     * @type {PaymentMethod}
     * @memberof CreateUserPaymentMethod201ResponseAllOfData
     */
    paymentMethod: PaymentMethod;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof CreateUserPaymentMethod201ResponseAllOfData
     */
    defaultPaymentMethod: PaymentMethod;
}

/**
 * Check if a given object implements the CreateUserPaymentMethod201ResponseAllOfData interface.
 */
export function instanceOfCreateUserPaymentMethod201ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "paymentMethod" in value;
    isInstance = isInstance && "defaultPaymentMethod" in value;

    return isInstance;
}

export function CreateUserPaymentMethod201ResponseAllOfDataFromJSON(json: any): CreateUserPaymentMethod201ResponseAllOfData {
    return CreateUserPaymentMethod201ResponseAllOfDataFromJSONTyped(json, false);
}

export function CreateUserPaymentMethod201ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserPaymentMethod201ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paymentMethod': PaymentMethodFromJSON(json['payment_method']),
        'defaultPaymentMethod': PaymentMethodFromJSON(json['default_payment_method']),
    };
}

export function CreateUserPaymentMethod201ResponseAllOfDataToJSON(value?: CreateUserPaymentMethod201ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'payment_method': PaymentMethodToJSON(value.paymentMethod),
        'default_payment_method': PaymentMethodToJSON(value.defaultPaymentMethod),
    };
}

