/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Comment } from './Comment';
import {
    CommentFromJSON,
    CommentFromJSONTyped,
    CommentToJSON,
} from './Comment';

/**
 * 
 * @export
 * @interface GetUserComments200ResponseAllOfData
 */
export interface GetUserComments200ResponseAllOfData {
    /**
     * 
     * @type {Array<Comment>}
     * @memberof GetUserComments200ResponseAllOfData
     */
    comments: Array<Comment>;
}

/**
 * Check if a given object implements the GetUserComments200ResponseAllOfData interface.
 */
export function instanceOfGetUserComments200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "comments" in value;

    return isInstance;
}

export function GetUserComments200ResponseAllOfDataFromJSON(json: any): GetUserComments200ResponseAllOfData {
    return GetUserComments200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetUserComments200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserComments200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comments': ((json['comments'] as Array<any>).map(CommentFromJSON)),
    };
}

export function GetUserComments200ResponseAllOfDataToJSON(value?: GetUserComments200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comments': ((value.comments as Array<any>).map(CommentToJSON)),
    };
}

