/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostTopicProposalRequestProposal
 */
export interface PostTopicProposalRequestProposal {
    /**
     * 
     * @type {string}
     * @memberof PostTopicProposalRequestProposal
     */
    body: string;
    /**
     * 
     * @type {string}
     * @memberof PostTopicProposalRequestProposal
     */
    bodyType: PostTopicProposalRequestProposalBodyTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PostTopicProposalRequestProposal
     */
    bodyText: string;
    /**
     * 
     * @type {string}
     * @memberof PostTopicProposalRequestProposal
     */
    positionId?: string;
    /**
     * 
     * @type {string}
     * @memberof PostTopicProposalRequestProposal
     */
    threadId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PostTopicProposalRequestProposal
     */
    asAnonymous?: boolean;
}


/**
 * @export
 */
export const PostTopicProposalRequestProposalBodyTypeEnum = {
    Markdown: 'markdown',
    Editorjs: 'editorjs'
} as const;
export type PostTopicProposalRequestProposalBodyTypeEnum = typeof PostTopicProposalRequestProposalBodyTypeEnum[keyof typeof PostTopicProposalRequestProposalBodyTypeEnum];


/**
 * Check if a given object implements the PostTopicProposalRequestProposal interface.
 */
export function instanceOfPostTopicProposalRequestProposal(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "body" in value;
    isInstance = isInstance && "bodyType" in value;
    isInstance = isInstance && "bodyText" in value;

    return isInstance;
}

export function PostTopicProposalRequestProposalFromJSON(json: any): PostTopicProposalRequestProposal {
    return PostTopicProposalRequestProposalFromJSONTyped(json, false);
}

export function PostTopicProposalRequestProposalFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostTopicProposalRequestProposal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'body': json['body'],
        'bodyType': json['body_type'],
        'bodyText': json['body_text'],
        'positionId': !exists(json, 'position_id') ? undefined : json['position_id'],
        'threadId': !exists(json, 'thread_id') ? undefined : json['thread_id'],
        'asAnonymous': !exists(json, 'as_anonymous') ? undefined : json['as_anonymous'],
    };
}

export function PostTopicProposalRequestProposalToJSON(value?: PostTopicProposalRequestProposal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'body': value.body,
        'body_type': value.bodyType,
        'body_text': value.bodyText,
        'position_id': value.positionId,
        'thread_id': value.threadId,
        'as_anonymous': value.asAnonymous,
    };
}

