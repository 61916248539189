import classNames from "classnames";
import { FocusEventHandler, ChangeEventHandler, ChangeEvent, FocusEvent, useCallback } from "react";
import { FieldValues, FieldPath, useFormContext, Controller } from "react-hook-form";

import { SelectInputGroup, SelectInputGroupProps } from "../SelectInputGroup";

type Props<Data extends FieldValues = FieldValues> = {
  name: FieldPath<Data>;
} & SelectInputGroupProps;

export const SelectControlGroup = <Data extends FieldValues = FieldValues, T extends HTMLSelectElement = HTMLSelectElement>({
  name,
  inputClassName,
  onBlur,
  onChange,
  ...props
}: Props<Data>) => {
  const { control, watch } = useFormContext<Data>();
  const value = watch(name);

  const onBlurHandler = useCallback((reactHookOnBlur: FocusEventHandler<T>): FocusEventHandler<T> => (e: FocusEvent<T>) => {
    reactHookOnBlur(e);
    onBlur?.(e);
  }, [onBlur]);

  const onChangeHandler = useCallback((reactHookOnChange: ChangeEventHandler<T>): ChangeEventHandler<T> => (e: ChangeEvent<T>) => {
    reactHookOnChange(e);
    onChange?.(e);
  }, [onChange]);

  const className = classNames(inputClassName, {
    "text-gray-400": !value,
  });

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <SelectInputGroup
          {...props}
          {...field}
          onBlur={onBlurHandler(field.onBlur)}
          onChange={onChangeHandler(field.onChange)}
          inputClassName={className}
          invalid={!!fieldState.error}
          error={fieldState.error?.message}
        />
      )}
    />
  );
};
