/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostProposalSupportRequestComment
 */
export interface PostProposalSupportRequestComment {
    /**
     * 
     * @type {string}
     * @memberof PostProposalSupportRequestComment
     */
    body: string;
    /**
     * 
     * @type {boolean}
     * @memberof PostProposalSupportRequestComment
     */
    asAnonymous?: boolean;
}

/**
 * Check if a given object implements the PostProposalSupportRequestComment interface.
 */
export function instanceOfPostProposalSupportRequestComment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "body" in value;

    return isInstance;
}

export function PostProposalSupportRequestCommentFromJSON(json: any): PostProposalSupportRequestComment {
    return PostProposalSupportRequestCommentFromJSONTyped(json, false);
}

export function PostProposalSupportRequestCommentFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostProposalSupportRequestComment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'body': json['body'],
        'asAnonymous': !exists(json, 'as_anonymous') ? undefined : json['as_anonymous'],
    };
}

export function PostProposalSupportRequestCommentToJSON(value?: PostProposalSupportRequestComment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'body': value.body,
        'as_anonymous': value.asAnonymous,
    };
}

