import { immerable } from "immer";

export type AttachableImageType = {
  url: string;
  width?: number;
  height?: number;
};

export class AttachableImage implements AttachableImageType {
  [immerable] = true;

  url = "";
  height = 0;
  width = 0;

  constructor(data: Partial<AttachableImageType> = {}) {
    Object.assign(this, data);
  }
}
