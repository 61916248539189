import { InquiryKind, InquiryStatus } from "shared/models";

export function formatInquiryKind(kind: InquiryKind): string {
  switch (kind) {
    case InquiryKind.Inquiry:
      return "お問い合わせ";
    case InquiryKind.Contact:
      return "コンタクト";
    case InquiryKind.BugReport:
      return "不具合報告・障害報告";
    case InquiryKind.FeatureRequest:
      return "機能要望";
    case InquiryKind.Other:
      return "その他";
  }
}

export function formatInquiryStatus(status: InquiryStatus): string {
  switch (status) {
    case InquiryStatus.Waiting:
      return "返答待ち";
    case InquiryStatus.Processing:
      return "対応中";
    case InquiryStatus.Closed:
      return "クローズ";
  }
}
