import { immerable } from "immer";

import * as api from "shared/services/api/web/models";

import { Money, MoneyType } from "./money";

export type PayoutType = api.Payout;

export type PayoutStatus = api.PayoutStatusEnum;
export const PayoutStatus = api.PayoutStatusEnum;

export class Payout implements PayoutType {
  [immerable] = true;

  id = "";
  amount: MoneyType = new Money();
  status: PayoutStatus = PayoutStatus.Requested;
  requestedAt: Date = new Date();
  completedAt?: Date;
  failedMessage?: string;
  rejectedMessage?: string;

  constructor(data: Partial<PayoutType> = {}) {
    Object.assign(this, data);
  }
}
