import { immerable } from "immer";

import * as api from "shared/services/api/web/models";

import { CommentType } from "./comment";
import { ProposalType } from "./proposal";
import { User, UserType } from "./user";

export const NotificationKindType = api.NotificationKindEnum;
export type NotificationKindType = api.NotificationKindEnum;

export const NotificationNotifiableType = api.NotificationNotifiableTypeEnum;
export type NotificationNotifiableType = api.NotificationNotifiableTypeEnum;

export const NotificationNotifiableFactorType = api.NotificationNotifiableFactorEnum;
export type NotificationNotifiableFactorType = api.NotificationNotifiableFactorEnum;

export type NotificationType = api.Notification;

export class Notification implements NotificationType {
  [immerable] = true;

  id = "";
  kind: NotificationKindType = NotificationKindType.System;
  notifiableType?: NotificationNotifiableType;
  notifiableFactor: NotificationNotifiableFactorType = NotificationNotifiableFactorType.None;
  message?: string;
  url?: string;
  users?: UserType[];
  proposal?: ProposalType;
  comment?: CommentType;
  usersCount = 0;
  notifiedAt = new Date();
  readAt?: Date;

  constructor(data: Partial<NotificationType> = {}) {
    Object.assign(this, data);
  }

  isRead() {
    return !!this.readAt;
  }

  getCount() {
    return this.usersCount > 0 ? this.usersCount : 1;
  }

  getUsers() {
    if (!this.users) return [];
    return this.users.map((u) => new User(u));
  }
}
