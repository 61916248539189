import { immerable } from "immer";

import * as api from "shared/services/api/web/models";

import { Position, PositionType } from "./position";
import { User, UserType } from "./user";

export type VoteType = api.Vote;

export class Vote implements VoteType {
  [immerable] = true;

  id = "";
  body = "";
  liked = false;
  likesCount = 0;
  position: PositionType = new Position();
  user: UserType = new User();

  constructor(data: Partial<VoteType> = {}) {
    Object.assign(this, data);
  }

  getUser() {
    return new User(this.user);
  }

  getPosition() {
    return new Position(this.position);
  }
}
