import { immerable } from "immer";

import * as api from "shared/services/api/web/models";

import { Money, MoneyType } from "./money";
import { Support } from "./support";
import { User, UserType } from "./user";

export type SupporterType = api.Supporter;

export class Supporter implements SupporterType {
  [immerable] = true;

  user: UserType = new User();
  amounts: MoneyType[] = [];
  sumAmountCents = 0;

  constructor(data: Partial<SupporterType> = {}) {
    Object.assign(this, data);
  }

  getUser() {
    return new User(this.user);
  }

  getAmounts() {
    return this.amounts.map((amount) => new Money(amount));
  }

  getAmountsSupports() {
    return this.amounts.map((amount) => new Support({
      user: this.user,
      amount,
    }));
  }
}
