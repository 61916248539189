/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface GetUser200ResponseAllOfData
 */
export interface GetUser200ResponseAllOfData {
    /**
     * 
     * @type {User}
     * @memberof GetUser200ResponseAllOfData
     */
    user: User;
}

/**
 * Check if a given object implements the GetUser200ResponseAllOfData interface.
 */
export function instanceOfGetUser200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "user" in value;

    return isInstance;
}

export function GetUser200ResponseAllOfDataFromJSON(json: any): GetUser200ResponseAllOfData {
    return GetUser200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetUser200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUser200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': UserFromJSON(json['user']),
    };
}

export function GetUser200ResponseAllOfDataToJSON(value?: GetUser200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UserToJSON(value.user),
    };
}

