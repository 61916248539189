/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Category } from './Category';
import {
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
} from './Category';
import type { Money } from './Money';
import {
    MoneyFromJSON,
    MoneyFromJSONTyped,
    MoneyToJSON,
} from './Money';
import type { Proposal } from './Proposal';
import {
    ProposalFromJSON,
    ProposalFromJSONTyped,
    ProposalToJSON,
} from './Proposal';
import type { TopicCoverImage } from './TopicCoverImage';
import {
    TopicCoverImageFromJSON,
    TopicCoverImageFromJSONTyped,
    TopicCoverImageToJSON,
} from './TopicCoverImage';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Topic
 */
export interface Topic {
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    body?: string;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    bodyText?: string;
    /**
     * 
     * @type {object}
     * @memberof Topic
     */
    bodyData?: object;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    bodyType: TopicBodyTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    rewardType: TopicRewardTypeEnum;
    /**
     * 
     * @type {Money}
     * @memberof Topic
     */
    rewardAmount?: Money;
    /**
     * 
     * @type {number}
     * @memberof Topic
     */
    rewardAnswersAmount: number;
    /**
     * 
     * @type {number}
     * @memberof Topic
     */
    proposalsCount: number;
    /**
     * 
     * @type {number}
     * @memberof Topic
     */
    likesCount: number;
    /**
     * 
     * @type {number}
     * @memberof Topic
     */
    clipsCount: number;
    /**
     * 
     * @type {number}
     * @memberof Topic
     */
    supportsCount: number;
    /**
     * 
     * @type {Date}
     * @memberof Topic
     */
    expiresAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Topic
     */
    publishedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Topic
     */
    publicExpiresAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Topic
     */
    rewardExpiresAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Topic
     */
    fixed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Topic
     */
    clipped: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Topic
     */
    supported: boolean;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    status: TopicStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    visibility: TopicVisibilityEnum;
    /**
     * 
     * @type {User}
     * @memberof Topic
     */
    user: User;
    /**
     * 
     * @type {Array<Category>}
     * @memberof Topic
     */
    categories?: Array<Category>;
    /**
     * 
     * @type {Proposal}
     * @memberof Topic
     */
    proposal?: Proposal;
    /**
     * 
     * @type {TopicCoverImage}
     * @memberof Topic
     */
    coverImage?: TopicCoverImage;
    /**
     * 
     * @type {boolean}
     * @memberof Topic
     */
    isOwner: boolean;
}


/**
 * @export
 */
export const TopicBodyTypeEnum = {
    Markdown: 'markdown',
    Editorjs: 'editorjs'
} as const;
export type TopicBodyTypeEnum = typeof TopicBodyTypeEnum[keyof typeof TopicBodyTypeEnum];

/**
 * @export
 */
export const TopicRewardTypeEnum = {
    None: 'none',
    AllForBest: 'all_for_best',
    DistributeByOwner: 'distribute_by_owner',
    DistributeByAuto: 'distribute_by_auto'
} as const;
export type TopicRewardTypeEnum = typeof TopicRewardTypeEnum[keyof typeof TopicRewardTypeEnum];

/**
 * @export
 */
export const TopicStatusEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected',
    Waiting: 'waiting',
    Canceled: 'canceled'
} as const;
export type TopicStatusEnum = typeof TopicStatusEnum[keyof typeof TopicStatusEnum];

/**
 * @export
 */
export const TopicVisibilityEnum = {
    Open: 'open',
    Closed: 'closed'
} as const;
export type TopicVisibilityEnum = typeof TopicVisibilityEnum[keyof typeof TopicVisibilityEnum];


/**
 * Check if a given object implements the Topic interface.
 */
export function instanceOfTopic(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "bodyType" in value;
    isInstance = isInstance && "rewardType" in value;
    isInstance = isInstance && "rewardAnswersAmount" in value;
    isInstance = isInstance && "proposalsCount" in value;
    isInstance = isInstance && "likesCount" in value;
    isInstance = isInstance && "clipsCount" in value;
    isInstance = isInstance && "supportsCount" in value;
    isInstance = isInstance && "publishedAt" in value;
    isInstance = isInstance && "fixed" in value;
    isInstance = isInstance && "clipped" in value;
    isInstance = isInstance && "supported" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "visibility" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "isOwner" in value;

    return isInstance;
}

export function TopicFromJSON(json: any): Topic {
    return TopicFromJSONTyped(json, false);
}

export function TopicFromJSONTyped(json: any, ignoreDiscriminator: boolean): Topic {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'body': !exists(json, 'body') ? undefined : json['body'],
        'bodyText': !exists(json, 'body_text') ? undefined : json['body_text'],
        'bodyData': !exists(json, 'body_data') ? undefined : json['body_data'],
        'bodyType': json['body_type'],
        'rewardType': json['reward_type'],
        'rewardAmount': !exists(json, 'reward_amount') ? undefined : MoneyFromJSON(json['reward_amount']),
        'rewardAnswersAmount': json['reward_answers_amount'],
        'proposalsCount': json['proposals_count'],
        'likesCount': json['likes_count'],
        'clipsCount': json['clips_count'],
        'supportsCount': json['supports_count'],
        'expiresAt': !exists(json, 'expires_at') ? undefined : (new Date(json['expires_at'])),
        'publishedAt': (new Date(json['published_at'])),
        'publicExpiresAt': !exists(json, 'public_expires_at') ? undefined : (new Date(json['public_expires_at'])),
        'rewardExpiresAt': !exists(json, 'reward_expires_at') ? undefined : (new Date(json['reward_expires_at'])),
        'fixed': json['fixed'],
        'clipped': json['clipped'],
        'supported': json['supported'],
        'status': json['status'],
        'visibility': json['visibility'],
        'user': UserFromJSON(json['user']),
        'categories': !exists(json, 'categories') ? undefined : ((json['categories'] as Array<any>).map(CategoryFromJSON)),
        'proposal': !exists(json, 'proposal') ? undefined : ProposalFromJSON(json['proposal']),
        'coverImage': !exists(json, 'cover_image') ? undefined : TopicCoverImageFromJSON(json['cover_image']),
        'isOwner': json['is_owner'],
    };
}

export function TopicToJSON(value?: Topic | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'body': value.body,
        'body_text': value.bodyText,
        'body_data': value.bodyData,
        'body_type': value.bodyType,
        'reward_type': value.rewardType,
        'reward_amount': MoneyToJSON(value.rewardAmount),
        'reward_answers_amount': value.rewardAnswersAmount,
        'proposals_count': value.proposalsCount,
        'likes_count': value.likesCount,
        'clips_count': value.clipsCount,
        'supports_count': value.supportsCount,
        'expires_at': value.expiresAt === undefined ? undefined : (value.expiresAt.toISOString()),
        'published_at': (value.publishedAt.toISOString()),
        'public_expires_at': value.publicExpiresAt === undefined ? undefined : (value.publicExpiresAt.toISOString()),
        'reward_expires_at': value.rewardExpiresAt === undefined ? undefined : (value.rewardExpiresAt.toISOString()),
        'fixed': value.fixed,
        'clipped': value.clipped,
        'supported': value.supported,
        'status': value.status,
        'visibility': value.visibility,
        'user': UserToJSON(value.user),
        'categories': value.categories === undefined ? undefined : ((value.categories as Array<any>).map(CategoryToJSON)),
        'proposal': ProposalToJSON(value.proposal),
        'cover_image': TopicCoverImageToJSON(value.coverImage),
        'is_owner': value.isOwner,
    };
}

