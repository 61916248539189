/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Proposal } from './Proposal';
import {
    ProposalFromJSON,
    ProposalFromJSONTyped,
    ProposalToJSON,
} from './Proposal';
import type { Support } from './Support';
import {
    SupportFromJSON,
    SupportFromJSONTyped,
    SupportToJSON,
} from './Support';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Comment
 */
export interface Comment {
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    commentableType: string;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    body: string;
    /**
     * 
     * @type {boolean}
     * @memberof Comment
     */
    liked: boolean;
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    likesCount: number;
    /**
     * 
     * @type {boolean}
     * @memberof Comment
     */
    clipped: boolean;
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    clipsCount: number;
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    commentsCount: number;
    /**
     * 
     * @type {Date}
     * @memberof Comment
     */
    prioritizedEndAt?: Date;
    /**
     * 
     * @type {User}
     * @memberof Comment
     */
    user: User;
    /**
     * 
     * @type {Support}
     * @memberof Comment
     */
    support?: Support;
    /**
     * 
     * @type {Proposal}
     * @memberof Comment
     */
    proposal?: Proposal;
}

/**
 * Check if a given object implements the Comment interface.
 */
export function instanceOfComment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "commentableType" in value;
    isInstance = isInstance && "body" in value;
    isInstance = isInstance && "liked" in value;
    isInstance = isInstance && "likesCount" in value;
    isInstance = isInstance && "clipped" in value;
    isInstance = isInstance && "clipsCount" in value;
    isInstance = isInstance && "commentsCount" in value;
    isInstance = isInstance && "user" in value;

    return isInstance;
}

export function CommentFromJSON(json: any): Comment {
    return CommentFromJSONTyped(json, false);
}

export function CommentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Comment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'commentableType': json['commentable_type'],
        'body': json['body'],
        'liked': json['liked'],
        'likesCount': json['likes_count'],
        'clipped': json['clipped'],
        'clipsCount': json['clips_count'],
        'commentsCount': json['comments_count'],
        'prioritizedEndAt': !exists(json, 'prioritized_end_at') ? undefined : (new Date(json['prioritized_end_at'])),
        'user': UserFromJSON(json['user']),
        'support': !exists(json, 'support') ? undefined : SupportFromJSON(json['support']),
        'proposal': !exists(json, 'proposal') ? undefined : ProposalFromJSON(json['proposal']),
    };
}

export function CommentToJSON(value?: Comment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'commentable_type': value.commentableType,
        'body': value.body,
        'liked': value.liked,
        'likes_count': value.likesCount,
        'clipped': value.clipped,
        'clips_count': value.clipsCount,
        'comments_count': value.commentsCount,
        'prioritized_end_at': value.prioritizedEndAt === undefined ? undefined : (value.prioritizedEndAt.toISOString()),
        'user': UserToJSON(value.user),
        'support': SupportToJSON(value.support),
        'proposal': ProposalToJSON(value.proposal),
    };
}

