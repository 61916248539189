import classNames from "classnames";

import { BFC } from "shared/types";

type Props = {
  count?: number;
};

export const NotificationBadge: BFC<Props> = ({
  count,
  className,
}) => {
  const classes = classNames("inline-block bg-primary rounded-full text-xs text-white text-center", className, {
    "w-3 h-3": !count,
    "w-4 h-4": count && count < 10,
    "h-4 px-1": count && count >= 10,
  });

  return (
    <div className={classes}>{count}</div>
  );
};
