import { immerable } from "immer";

import * as api from "shared/services/api/web/models";

import { CategoryType } from "./category";
import { UserThumbnailImageType, UserType } from "./user";
import { UserProvider, UserProviderType } from "./user_provider";

export type UserMeType = api.UserMe;

export type UserMeTypeType = api.UserMeTypeEnum;
export const UserMeTypeType = api.UserMeTypeEnum;

export type UserMeGenderType = api.UserMeGenderEnum;
export const UserMeGenderType = api.UserMeGenderEnum;

export class UserMe implements UserMeType {
  [immerable] = true;

  id = "";
  type: UserMeTypeType = UserMeTypeType.Visitor;
  handle = "";
  email = "";
  emailConfirmed = false;
  emailMarketingAgreed = false;
  stripeAccountVerified = false;
  nickname = "";
  jobTitle = "";
  introduction = "";
  gender: UserMeGenderType = UserMeGenderType.Unknown;
  birthday?: Date;
  thumbnailImage?: UserThumbnailImageType;
  expertCategories: CategoryType[] = [];
  providers: UserProviderType[] = [];

  constructor(data: Partial<UserMeType> = {}) {
    Object.assign(this, data);
  }

  isVisitor() {
    return this.type === UserMeTypeType.Visitor;
  }

  isGuest() {
    return this.type === UserMeTypeType.Guest;
  }

  isNormal() {
    return this.type === UserMeTypeType.Normal;
  }

  isSignedIn() {
    return this.isNormal() && this.id !== "";
  }

  getProviders() {
    return this.providers.map((provider) => new UserProvider(provider));
  }

  isMe(user: UserType) {
    return this.id === user.id && this.type === user.type;
  }
}
