/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Payout } from './Payout';
import {
    PayoutFromJSON,
    PayoutFromJSONTyped,
    PayoutToJSON,
} from './Payout';

/**
 * 
 * @export
 * @interface GetPayouts200ResponseAllOfData
 */
export interface GetPayouts200ResponseAllOfData {
    /**
     * 
     * @type {Array<Payout>}
     * @memberof GetPayouts200ResponseAllOfData
     */
    payouts: Array<Payout>;
}

/**
 * Check if a given object implements the GetPayouts200ResponseAllOfData interface.
 */
export function instanceOfGetPayouts200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "payouts" in value;

    return isInstance;
}

export function GetPayouts200ResponseAllOfDataFromJSON(json: any): GetPayouts200ResponseAllOfData {
    return GetPayouts200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetPayouts200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPayouts200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'payouts': ((json['payouts'] as Array<any>).map(PayoutFromJSON)),
    };
}

export function GetPayouts200ResponseAllOfDataToJSON(value?: GetPayouts200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'payouts': ((value.payouts as Array<any>).map(PayoutToJSON)),
    };
}

