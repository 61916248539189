import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { ChangeEvent, forwardRef, Ref, useCallback } from "react";

export type SelectInputProps = {
  items: Item[];
  invalid?: boolean;
  containerClassName?: string;
  onChangeValue?: (value: string) => void;
} & React.SelectHTMLAttributes<HTMLSelectElement>;

type Item = {
  value: string;
  label?: string;
};

export const SelectInput = forwardRef(function SelectInput({
  name,
  items,
  placeholder,
  invalid,
  className,
  containerClassName,
  onChange,
  onChangeValue,
  ...props
}: SelectInputProps, ref: Ref<HTMLSelectElement>) {
  const classes = classNames(
    "rounded border border-gray-300 shadow-sm w-full pr-10 form-select",
    className,
    {
      "border-red-300": invalid,
    },
  );

  const onChangeHandler = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    onChange?.(e);
    onChangeValue?.(e.target.value);
  }, [onChange, onChangeValue]);

  return (
    <div className={classNames("relative", containerClassName)}>
      <select ref={ref} {...props} name={name} className={classes} onChange={onChangeHandler}>
        {placeholder && <option value="">{placeholder}</option>}
        {items.map((item) => (
          <option key={item.value} value={item.value}>
            {item.label ?? item.value}
          </option>
        ))}
      </select>
      {invalid && (
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
        </div>
      )}
    </div>
  );
});
