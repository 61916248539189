/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResponseSuccessWithPagingAllOfMeta } from './ResponseSuccessWithPagingAllOfMeta';
import {
    ResponseSuccessWithPagingAllOfMetaFromJSON,
    ResponseSuccessWithPagingAllOfMetaFromJSONTyped,
    ResponseSuccessWithPagingAllOfMetaToJSON,
} from './ResponseSuccessWithPagingAllOfMeta';
import type { SearchProposals200ResponseAllOfData } from './SearchProposals200ResponseAllOfData';
import {
    SearchProposals200ResponseAllOfDataFromJSON,
    SearchProposals200ResponseAllOfDataFromJSONTyped,
    SearchProposals200ResponseAllOfDataToJSON,
} from './SearchProposals200ResponseAllOfData';

/**
 * 
 * @export
 * @interface SearchProposals200Response
 */
export interface SearchProposals200Response {
    /**
     * 
     * @type {SearchProposals200ResponseAllOfData}
     * @memberof SearchProposals200Response
     */
    data: SearchProposals200ResponseAllOfData;
    /**
     * 
     * @type {ResponseSuccessWithPagingAllOfMeta}
     * @memberof SearchProposals200Response
     */
    meta: ResponseSuccessWithPagingAllOfMeta;
}

/**
 * Check if a given object implements the SearchProposals200Response interface.
 */
export function instanceOfSearchProposals200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function SearchProposals200ResponseFromJSON(json: any): SearchProposals200Response {
    return SearchProposals200ResponseFromJSONTyped(json, false);
}

export function SearchProposals200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchProposals200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': SearchProposals200ResponseAllOfDataFromJSON(json['data']),
        'meta': ResponseSuccessWithPagingAllOfMetaFromJSON(json['meta']),
    };
}

export function SearchProposals200ResponseToJSON(value?: SearchProposals200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': SearchProposals200ResponseAllOfDataToJSON(value.data),
        'meta': ResponseSuccessWithPagingAllOfMetaToJSON(value.meta),
    };
}

