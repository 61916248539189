/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CarouselItem } from './CarouselItem';
import {
    CarouselItemFromJSON,
    CarouselItemFromJSONTyped,
    CarouselItemToJSON,
} from './CarouselItem';

/**
 * 
 * @export
 * @interface GetCarouselItems200ResponseAllOfData
 */
export interface GetCarouselItems200ResponseAllOfData {
    /**
     * 
     * @type {Array<CarouselItem>}
     * @memberof GetCarouselItems200ResponseAllOfData
     */
    items: Array<CarouselItem>;
}

/**
 * Check if a given object implements the GetCarouselItems200ResponseAllOfData interface.
 */
export function instanceOfGetCarouselItems200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

export function GetCarouselItems200ResponseAllOfDataFromJSON(json: any): GetCarouselItems200ResponseAllOfData {
    return GetCarouselItems200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetCarouselItems200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCarouselItems200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(CarouselItemFromJSON)),
    };
}

export function GetCarouselItems200ResponseAllOfDataToJSON(value?: GetCarouselItems200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(CarouselItemToJSON)),
    };
}

