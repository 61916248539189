/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Comment } from './Comment';
import {
    CommentFromJSON,
    CommentFromJSONTyped,
    CommentToJSON,
} from './Comment';

/**
 * 
 * @export
 * @interface PostProposalComment201ResponseAllOfData
 */
export interface PostProposalComment201ResponseAllOfData {
    /**
     * 
     * @type {Comment}
     * @memberof PostProposalComment201ResponseAllOfData
     */
    comment: Comment;
}

/**
 * Check if a given object implements the PostProposalComment201ResponseAllOfData interface.
 */
export function instanceOfPostProposalComment201ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "comment" in value;

    return isInstance;
}

export function PostProposalComment201ResponseAllOfDataFromJSON(json: any): PostProposalComment201ResponseAllOfData {
    return PostProposalComment201ResponseAllOfDataFromJSONTyped(json, false);
}

export function PostProposalComment201ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostProposalComment201ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comment': CommentFromJSON(json['comment']),
    };
}

export function PostProposalComment201ResponseAllOfDataToJSON(value?: PostProposalComment201ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comment': CommentToJSON(value.comment),
    };
}

