import { immerable } from "immer";

import * as api from "shared/services/api/web/models";

export type BankAccountType = api.BankAccount;

export type BankAccountStatus = api.BankAccountStatusEnum;
export const BankAccountStatus = api.BankAccountStatusEnum;

export class BankAccount implements BankAccountType {
  [immerable] = true;

  id = "";
  bankName = "";
  accountType = "futsu";
  accountHolderName = "";
  last4 = "";
  status: BankAccountStatus = BankAccountStatus.New;

  constructor(data: Partial<BankAccountType> = {}) {
    Object.assign(this, data);
  }

  isAvailable() {
    return this.status === BankAccountStatus.New || this.status === BankAccountStatus.Verified || this.status === BankAccountStatus.Validated;
  }
}
