/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostTopicRequestPositionsInner
 */
export interface PostTopicRequestPositionsInner {
    /**
     * 
     * @type {string}
     * @memberof PostTopicRequestPositionsInner
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof PostTopicRequestPositionsInner
     */
    displayOrder: number;
}

/**
 * Check if a given object implements the PostTopicRequestPositionsInner interface.
 */
export function instanceOfPostTopicRequestPositionsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "displayOrder" in value;

    return isInstance;
}

export function PostTopicRequestPositionsInnerFromJSON(json: any): PostTopicRequestPositionsInner {
    return PostTopicRequestPositionsInnerFromJSONTyped(json, false);
}

export function PostTopicRequestPositionsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostTopicRequestPositionsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'displayOrder': json['display_order'],
    };
}

export function PostTopicRequestPositionsInnerToJSON(value?: PostTopicRequestPositionsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'display_order': value.displayOrder,
    };
}

