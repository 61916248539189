/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Checkout } from './Checkout';
import {
    CheckoutFromJSON,
    CheckoutFromJSONTyped,
    CheckoutToJSON,
} from './Checkout';
import type { Position } from './Position';
import {
    PositionFromJSON,
    PositionFromJSONTyped,
    PositionToJSON,
} from './Position';
import type { Topic } from './Topic';
import {
    TopicFromJSON,
    TopicFromJSONTyped,
    TopicToJSON,
} from './Topic';

/**
 * 
 * @export
 * @interface GetUserTopic200ResponseAllOfData
 */
export interface GetUserTopic200ResponseAllOfData {
    /**
     * 
     * @type {Topic}
     * @memberof GetUserTopic200ResponseAllOfData
     */
    topic: Topic;
    /**
     * 
     * @type {Array<Position>}
     * @memberof GetUserTopic200ResponseAllOfData
     */
    positions: Array<Position>;
    /**
     * 
     * @type {Checkout}
     * @memberof GetUserTopic200ResponseAllOfData
     */
    checkout?: Checkout;
}

/**
 * Check if a given object implements the GetUserTopic200ResponseAllOfData interface.
 */
export function instanceOfGetUserTopic200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "topic" in value;
    isInstance = isInstance && "positions" in value;

    return isInstance;
}

export function GetUserTopic200ResponseAllOfDataFromJSON(json: any): GetUserTopic200ResponseAllOfData {
    return GetUserTopic200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetUserTopic200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserTopic200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'topic': TopicFromJSON(json['topic']),
        'positions': ((json['positions'] as Array<any>).map(PositionFromJSON)),
        'checkout': !exists(json, 'checkout') ? undefined : CheckoutFromJSON(json['checkout']),
    };
}

export function GetUserTopic200ResponseAllOfDataToJSON(value?: GetUserTopic200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'topic': TopicToJSON(value.topic),
        'positions': ((value.positions as Array<any>).map(PositionToJSON)),
        'checkout': CheckoutToJSON(value.checkout),
    };
}

