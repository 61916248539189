import { FocusEventHandler, FocusEvent, useCallback, ChangeEventHandler, ChangeEvent } from "react";
import { FieldValues, FieldPath, useFormContext, Controller } from "react-hook-form";

import { TextAreaInputGroup, TextAreaInputGroupProps } from "../TextAreaInputGroup";

type Props<Data extends FieldValues = FieldValues> = {
  name: FieldPath<Data>;
} & TextAreaInputGroupProps;

export const TextAreaControlGroup = <Data extends FieldValues = FieldValues, T extends HTMLTextAreaElement = HTMLTextAreaElement>({
  name,
  onBlur,
  onChange,
  ...props
}: Props<Data>) => {
  const { control } = useFormContext<Data>();

  const onBlurHandler = useCallback((reactHookOnBlur: FocusEventHandler<T>): FocusEventHandler<T> => (e: FocusEvent<T>) => {
    reactHookOnBlur(e);
    onBlur?.(e);
  }, [onBlur]);

  const onChangeHandler = useCallback((reactHookOnChange: ChangeEventHandler<T>): ChangeEventHandler<T> => (e: ChangeEvent<T>) => {
    reactHookOnChange(e);
    onChange?.(e);
  }, [onChange]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <TextAreaInputGroup
          {...props}
          {...field}
          onBlur={onBlurHandler(field.onBlur)}
          onChange={onChangeHandler(field.onChange)}
          invalid={!!fieldState.error}
          error={fieldState.error?.message}
        />
      )}
    />
  );
};
