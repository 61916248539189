import { forwardRef } from "react";

import { InputGroup, BaseSelectInputGroupProps } from "../InputGroup";
import { SelectInput } from "../SelectInput";

export type SelectInputGroupProps = Omit<BaseSelectInputGroupProps, "input">;

export const SelectInputGroup = forwardRef<HTMLSelectElement, SelectInputGroupProps>(function SelectInputGroup(props, ref) {
  return (
    <InputGroup {...props} ref={ref} input={SelectInput} />
  );
});
