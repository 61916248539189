/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { VoteTopic201ResponseAllOfData } from './VoteTopic201ResponseAllOfData';
import {
    VoteTopic201ResponseAllOfDataFromJSON,
    VoteTopic201ResponseAllOfDataFromJSONTyped,
    VoteTopic201ResponseAllOfDataToJSON,
} from './VoteTopic201ResponseAllOfData';

/**
 * 
 * @export
 * @interface VoteTopic201Response
 */
export interface VoteTopic201Response {
    /**
     * 
     * @type {VoteTopic201ResponseAllOfData}
     * @memberof VoteTopic201Response
     */
    data: VoteTopic201ResponseAllOfData;
    /**
     * 
     * @type {object}
     * @memberof VoteTopic201Response
     */
    meta: object;
}

/**
 * Check if a given object implements the VoteTopic201Response interface.
 */
export function instanceOfVoteTopic201Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function VoteTopic201ResponseFromJSON(json: any): VoteTopic201Response {
    return VoteTopic201ResponseFromJSONTyped(json, false);
}

export function VoteTopic201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VoteTopic201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': VoteTopic201ResponseAllOfDataFromJSON(json['data']),
        'meta': json['meta'],
    };
}

export function VoteTopic201ResponseToJSON(value?: VoteTopic201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': VoteTopic201ResponseAllOfDataToJSON(value.data),
        'meta': value.meta,
    };
}

