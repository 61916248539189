/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserMeGuest
 */
export interface UserMeGuest {
    /**
     * 
     * @type {string}
     * @memberof UserMeGuest
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserMeGuest
     */
    type: UserMeGuestTypeEnum;
}


/**
 * @export
 */
export const UserMeGuestTypeEnum = {
    Normal: 'normal',
    Guest: 'guest',
    Visitor: 'visitor'
} as const;
export type UserMeGuestTypeEnum = typeof UserMeGuestTypeEnum[keyof typeof UserMeGuestTypeEnum];


/**
 * Check if a given object implements the UserMeGuest interface.
 */
export function instanceOfUserMeGuest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function UserMeGuestFromJSON(json: any): UserMeGuest {
    return UserMeGuestFromJSONTyped(json, false);
}

export function UserMeGuestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMeGuest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
    };
}

export function UserMeGuestToJSON(value?: UserMeGuest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
    };
}

