/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateTopicNoteRequestNote
 */
export interface CreateTopicNoteRequestNote {
    /**
     * 
     * @type {string}
     * @memberof CreateTopicNoteRequestNote
     */
    body: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTopicNoteRequestNote
     */
    bodyText?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTopicNoteRequestNote
     */
    bodyType: CreateTopicNoteRequestNoteBodyTypeEnum;
}


/**
 * @export
 */
export const CreateTopicNoteRequestNoteBodyTypeEnum = {
    Markdown: 'markdown',
    Editorjs: 'editorjs'
} as const;
export type CreateTopicNoteRequestNoteBodyTypeEnum = typeof CreateTopicNoteRequestNoteBodyTypeEnum[keyof typeof CreateTopicNoteRequestNoteBodyTypeEnum];


/**
 * Check if a given object implements the CreateTopicNoteRequestNote interface.
 */
export function instanceOfCreateTopicNoteRequestNote(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "body" in value;
    isInstance = isInstance && "bodyType" in value;

    return isInstance;
}

export function CreateTopicNoteRequestNoteFromJSON(json: any): CreateTopicNoteRequestNote {
    return CreateTopicNoteRequestNoteFromJSONTyped(json, false);
}

export function CreateTopicNoteRequestNoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTopicNoteRequestNote {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'body': json['body'],
        'bodyText': !exists(json, 'body_text') ? undefined : json['body_text'],
        'bodyType': json['body_type'],
    };
}

export function CreateTopicNoteRequestNoteToJSON(value?: CreateTopicNoteRequestNote | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'body': value.body,
        'body_text': value.bodyText,
        'body_type': value.bodyType,
    };
}

