/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Supporter } from './Supporter';
import {
    SupporterFromJSON,
    SupporterFromJSONTyped,
    SupporterToJSON,
} from './Supporter';

/**
 * 
 * @export
 * @interface GetProposalSupporters200ResponseAllOfData
 */
export interface GetProposalSupporters200ResponseAllOfData {
    /**
     * 
     * @type {Array<Supporter>}
     * @memberof GetProposalSupporters200ResponseAllOfData
     */
    supporters: Array<Supporter>;
}

/**
 * Check if a given object implements the GetProposalSupporters200ResponseAllOfData interface.
 */
export function instanceOfGetProposalSupporters200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "supporters" in value;

    return isInstance;
}

export function GetProposalSupporters200ResponseAllOfDataFromJSON(json: any): GetProposalSupporters200ResponseAllOfData {
    return GetProposalSupporters200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetProposalSupporters200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProposalSupporters200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'supporters': ((json['supporters'] as Array<any>).map(SupporterFromJSON)),
    };
}

export function GetProposalSupporters200ResponseAllOfDataToJSON(value?: GetProposalSupporters200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'supporters': ((value.supporters as Array<any>).map(SupporterToJSON)),
    };
}

