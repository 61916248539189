import { immerable } from "immer";

import * as api from "shared/services/api/web/models";

type PaymentMethodTypeType = "card";

export type PaymentMethodType = Omit<api.PaymentMethod, "type"> & {
  type: PaymentMethodTypeType;
};

export class PaymentMethod implements PaymentMethodType {
  [immerable] = true;

  id = "";
  stripePaymentMethodId = "";
  type: PaymentMethodTypeType = "card";
  card: PaymentMethodType["card"] = {
    brand: "",
    expMonth: 0,
    expYear: 0,
    last4: "",
    threeDSecureSupported: false,
  };

  constructor(data: Partial<PaymentMethodType> = {}) {
    Object.assign(this, data);
  }
}
