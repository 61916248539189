import { FieldValues, FieldPath, useFormContext } from "react-hook-form";

type Props<Data extends FieldValues = FieldValues> = {
  name: FieldPath<Data>;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const HiddenControl = <Data extends FieldValues = FieldValues>({
  name,
  ...props
}: Props<Data>) => {
  const { register } = useFormContext<Data>();

  return (
    <input
      {...props}
      {...register(name)}
      type="hidden"
    />
  );
};
