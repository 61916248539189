import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { HiExclamationCircle } from "react-icons/hi2";

import { BFC } from "shared/types";

type Props = {
  message: string;
  hideIcon?: boolean;
  i18nNS?: string;
};

export const ErrorMessage: BFC<Props> = ({
  message,
  hideIcon = false,
  i18nNS = "validation",
  className,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames("mt-2 text-sm text-red-600 flex gap-1", className)}>
      {!hideIcon && <HiExclamationCircle size={20} />}
      {t(message, { ns: i18nNS })}
    </div>
  );
};
