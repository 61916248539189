/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Inquiry } from './Inquiry';
import {
    InquiryFromJSON,
    InquiryFromJSONTyped,
    InquiryToJSON,
} from './Inquiry';
import type { InquiryReply } from './InquiryReply';
import {
    InquiryReplyFromJSON,
    InquiryReplyFromJSONTyped,
    InquiryReplyToJSON,
} from './InquiryReply';

/**
 * 
 * @export
 * @interface GetInquiryReplies200ResponseAllOfData
 */
export interface GetInquiryReplies200ResponseAllOfData {
    /**
     * 
     * @type {Inquiry}
     * @memberof GetInquiryReplies200ResponseAllOfData
     */
    inquiry: Inquiry;
    /**
     * 
     * @type {Array<InquiryReply>}
     * @memberof GetInquiryReplies200ResponseAllOfData
     */
    replies: Array<InquiryReply>;
}

/**
 * Check if a given object implements the GetInquiryReplies200ResponseAllOfData interface.
 */
export function instanceOfGetInquiryReplies200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "inquiry" in value;
    isInstance = isInstance && "replies" in value;

    return isInstance;
}

export function GetInquiryReplies200ResponseAllOfDataFromJSON(json: any): GetInquiryReplies200ResponseAllOfData {
    return GetInquiryReplies200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetInquiryReplies200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetInquiryReplies200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inquiry': InquiryFromJSON(json['inquiry']),
        'replies': ((json['replies'] as Array<any>).map(InquiryReplyFromJSON)),
    };
}

export function GetInquiryReplies200ResponseAllOfDataToJSON(value?: GetInquiryReplies200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inquiry': InquiryToJSON(value.inquiry),
        'replies': ((value.replies as Array<any>).map(InquiryReplyToJSON)),
    };
}

