/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttachableImage } from './AttachableImage';
import {
    AttachableImageFromJSON,
    AttachableImageFromJSONTyped,
    AttachableImageToJSON,
} from './AttachableImage';

/**
 * 
 * @export
 * @interface UserThumbnailImage
 */
export interface UserThumbnailImage {
    /**
     * 
     * @type {string}
     * @memberof UserThumbnailImage
     */
    url: string;
    /**
     * 
     * @type {number}
     * @memberof UserThumbnailImage
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof UserThumbnailImage
     */
    height?: number;
    /**
     * 
     * @type {AttachableImage}
     * @memberof UserThumbnailImage
     */
    webp?: AttachableImage;
}

/**
 * Check if a given object implements the UserThumbnailImage interface.
 */
export function instanceOfUserThumbnailImage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "url" in value;

    return isInstance;
}

export function UserThumbnailImageFromJSON(json: any): UserThumbnailImage {
    return UserThumbnailImageFromJSONTyped(json, false);
}

export function UserThumbnailImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserThumbnailImage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': json['url'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'webp': !exists(json, 'webp') ? undefined : AttachableImageFromJSON(json['webp']),
    };
}

export function UserThumbnailImageToJSON(value?: UserThumbnailImage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
        'width': value.width,
        'height': value.height,
        'webp': AttachableImageToJSON(value.webp),
    };
}

