import classNames from "classnames";
import { ColorRing } from "react-loader-spinner";

import { CFC } from "shared/types";

type Props = {
  loading: boolean;
  simple?: boolean;
  size?: number;
};

export const LoadingOverlay: CFC<Props> = ({
  loading,
  simple = false,
  size = 48,
  children,
  className,
}) => {
  if (!loading) return <>{children}</>;

  return (
    <div className={classNames("relative", className)}>
      {children}
      <div className="absolute inset-0 flex items-center justify-center bg-white/50">
        <div className="flex flex-col items-center gap-4 p-4">
          {simple ? (
            <div className="border-t-primary h-8 w-8 animate-spin rounded-full border-4 border-gray-200" />
          ) : (
            <ColorRing width={size} height={size} />
          )}
          <div className="sr-only">読み込み中...</div>
        </div>
      </div>
    </div>
  );
};
