/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Checkout } from './Checkout';
import {
    CheckoutFromJSON,
    CheckoutFromJSONTyped,
    CheckoutToJSON,
} from './Checkout';
import type { Support } from './Support';
import {
    SupportFromJSON,
    SupportFromJSONTyped,
    SupportToJSON,
} from './Support';

/**
 * 
 * @export
 * @interface CallbackSupportCheckout200ResponseAllOfData
 */
export interface CallbackSupportCheckout200ResponseAllOfData {
    /**
     * 
     * @type {Support}
     * @memberof CallbackSupportCheckout200ResponseAllOfData
     */
    support: Support;
    /**
     * 
     * @type {Checkout}
     * @memberof CallbackSupportCheckout200ResponseAllOfData
     */
    checkout: Checkout;
}

/**
 * Check if a given object implements the CallbackSupportCheckout200ResponseAllOfData interface.
 */
export function instanceOfCallbackSupportCheckout200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "support" in value;
    isInstance = isInstance && "checkout" in value;

    return isInstance;
}

export function CallbackSupportCheckout200ResponseAllOfDataFromJSON(json: any): CallbackSupportCheckout200ResponseAllOfData {
    return CallbackSupportCheckout200ResponseAllOfDataFromJSONTyped(json, false);
}

export function CallbackSupportCheckout200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CallbackSupportCheckout200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'support': SupportFromJSON(json['support']),
        'checkout': CheckoutFromJSON(json['checkout']),
    };
}

export function CallbackSupportCheckout200ResponseAllOfDataToJSON(value?: CallbackSupportCheckout200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'support': SupportToJSON(value.support),
        'checkout': CheckoutToJSON(value.checkout),
    };
}

