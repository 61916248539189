/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Money } from './Money';
import {
    MoneyFromJSON,
    MoneyFromJSONTyped,
    MoneyToJSON,
} from './Money';
import type { Proposal } from './Proposal';
import {
    ProposalFromJSON,
    ProposalFromJSONTyped,
    ProposalToJSON,
} from './Proposal';
import type { Support } from './Support';
import {
    SupportFromJSON,
    SupportFromJSONTyped,
    SupportToJSON,
} from './Support';
import type { Topic } from './Topic';
import {
    TopicFromJSON,
    TopicFromJSONTyped,
    TopicToJSON,
} from './Topic';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Revenue
 */
export interface Revenue {
    /**
     * 
     * @type {string}
     * @memberof Revenue
     */
    id: string;
    /**
     * 
     * @type {Money}
     * @memberof Revenue
     */
    amount: Money;
    /**
     * 
     * @type {Money}
     * @memberof Revenue
     */
    fee: Money;
    /**
     * 
     * @type {Money}
     * @memberof Revenue
     */
    netAmount: Money;
    /**
     * 
     * @type {string}
     * @memberof Revenue
     */
    status: RevenueStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof Revenue
     */
    receivedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Revenue
     */
    expiresAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Revenue
     */
    description?: string;
    /**
     * 
     * @type {User}
     * @memberof Revenue
     */
    sourceUser?: User;
    /**
     * 
     * @type {Topic}
     * @memberof Revenue
     */
    topic?: Topic;
    /**
     * 
     * @type {Proposal}
     * @memberof Revenue
     */
    proposal?: Proposal;
    /**
     * 
     * @type {Support}
     * @memberof Revenue
     */
    support?: Support;
}


/**
 * @export
 */
export const RevenueStatusEnum = {
    Received: 'received',
    Accepted: 'accepted',
    Expired: 'expired'
} as const;
export type RevenueStatusEnum = typeof RevenueStatusEnum[keyof typeof RevenueStatusEnum];


/**
 * Check if a given object implements the Revenue interface.
 */
export function instanceOfRevenue(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "fee" in value;
    isInstance = isInstance && "netAmount" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "receivedAt" in value;

    return isInstance;
}

export function RevenueFromJSON(json: any): Revenue {
    return RevenueFromJSONTyped(json, false);
}

export function RevenueFromJSONTyped(json: any, ignoreDiscriminator: boolean): Revenue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'amount': MoneyFromJSON(json['amount']),
        'fee': MoneyFromJSON(json['fee']),
        'netAmount': MoneyFromJSON(json['net_amount']),
        'status': json['status'],
        'receivedAt': (new Date(json['received_at'])),
        'expiresAt': !exists(json, 'expires_at') ? undefined : (new Date(json['expires_at'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'sourceUser': !exists(json, 'source_user') ? undefined : UserFromJSON(json['source_user']),
        'topic': !exists(json, 'topic') ? undefined : TopicFromJSON(json['topic']),
        'proposal': !exists(json, 'proposal') ? undefined : ProposalFromJSON(json['proposal']),
        'support': !exists(json, 'support') ? undefined : SupportFromJSON(json['support']),
    };
}

export function RevenueToJSON(value?: Revenue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'amount': MoneyToJSON(value.amount),
        'fee': MoneyToJSON(value.fee),
        'net_amount': MoneyToJSON(value.netAmount),
        'status': value.status,
        'received_at': (value.receivedAt.toISOString()),
        'expires_at': value.expiresAt === undefined ? undefined : (value.expiresAt.toISOString()),
        'description': value.description,
        'source_user': UserToJSON(value.sourceUser),
        'topic': TopicToJSON(value.topic),
        'proposal': ProposalToJSON(value.proposal),
        'support': SupportToJSON(value.support),
    };
}

