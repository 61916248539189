/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Category } from './Category';
import {
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
} from './Category';
import type { UserMeThumbnailImage } from './UserMeThumbnailImage';
import {
    UserMeThumbnailImageFromJSON,
    UserMeThumbnailImageFromJSONTyped,
    UserMeThumbnailImageToJSON,
} from './UserMeThumbnailImage';
import type { UserProvider } from './UserProvider';
import {
    UserProviderFromJSON,
    UserProviderFromJSONTyped,
    UserProviderToJSON,
} from './UserProvider';

/**
 * 
 * @export
 * @interface UserMe
 */
export interface UserMe {
    /**
     * 
     * @type {string}
     * @memberof UserMe
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserMe
     */
    type: UserMeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserMe
     */
    handle: string;
    /**
     * 
     * @type {string}
     * @memberof UserMe
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserMe
     */
    emailConfirmed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserMe
     */
    emailMarketingAgreed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserMe
     */
    stripeAccountVerified: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserMe
     */
    nickname: string;
    /**
     * 
     * @type {string}
     * @memberof UserMe
     */
    jobTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof UserMe
     */
    introduction?: string;
    /**
     * 
     * @type {string}
     * @memberof UserMe
     */
    gender?: UserMeGenderEnum;
    /**
     * 
     * @type {Date}
     * @memberof UserMe
     */
    birthday?: Date;
    /**
     * 
     * @type {UserMeThumbnailImage}
     * @memberof UserMe
     */
    thumbnailImage?: UserMeThumbnailImage;
    /**
     * 
     * @type {Array<Category>}
     * @memberof UserMe
     */
    expertCategories?: Array<Category>;
    /**
     * 
     * @type {Array<UserProvider>}
     * @memberof UserMe
     */
    providers?: Array<UserProvider>;
}


/**
 * @export
 */
export const UserMeTypeEnum = {
    Normal: 'normal',
    Guest: 'guest',
    Visitor: 'visitor'
} as const;
export type UserMeTypeEnum = typeof UserMeTypeEnum[keyof typeof UserMeTypeEnum];

/**
 * @export
 */
export const UserMeGenderEnum = {
    Unknown: 'unknown',
    Man: 'man',
    Woman: 'woman'
} as const;
export type UserMeGenderEnum = typeof UserMeGenderEnum[keyof typeof UserMeGenderEnum];


/**
 * Check if a given object implements the UserMe interface.
 */
export function instanceOfUserMe(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "handle" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "emailConfirmed" in value;
    isInstance = isInstance && "emailMarketingAgreed" in value;
    isInstance = isInstance && "stripeAccountVerified" in value;
    isInstance = isInstance && "nickname" in value;

    return isInstance;
}

export function UserMeFromJSON(json: any): UserMe {
    return UserMeFromJSONTyped(json, false);
}

export function UserMeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMe {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'handle': json['handle'],
        'email': json['email'],
        'emailConfirmed': json['email_confirmed'],
        'emailMarketingAgreed': json['email_marketing_agreed'],
        'stripeAccountVerified': json['stripe_account_verified'],
        'nickname': json['nickname'],
        'jobTitle': !exists(json, 'job_title') ? undefined : json['job_title'],
        'introduction': !exists(json, 'introduction') ? undefined : json['introduction'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'birthday': !exists(json, 'birthday') ? undefined : (new Date(json['birthday'])),
        'thumbnailImage': !exists(json, 'thumbnail_image') ? undefined : UserMeThumbnailImageFromJSON(json['thumbnail_image']),
        'expertCategories': !exists(json, 'expert_categories') ? undefined : ((json['expert_categories'] as Array<any>).map(CategoryFromJSON)),
        'providers': !exists(json, 'providers') ? undefined : ((json['providers'] as Array<any>).map(UserProviderFromJSON)),
    };
}

export function UserMeToJSON(value?: UserMe | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'handle': value.handle,
        'email': value.email,
        'email_confirmed': value.emailConfirmed,
        'email_marketing_agreed': value.emailMarketingAgreed,
        'stripe_account_verified': value.stripeAccountVerified,
        'nickname': value.nickname,
        'job_title': value.jobTitle,
        'introduction': value.introduction,
        'gender': value.gender,
        'birthday': value.birthday === undefined ? undefined : (value.birthday.toISOString().substring(0,10)),
        'thumbnail_image': UserMeThumbnailImageToJSON(value.thumbnailImage),
        'expert_categories': value.expertCategories === undefined ? undefined : ((value.expertCategories as Array<any>).map(CategoryToJSON)),
        'providers': value.providers === undefined ? undefined : ((value.providers as Array<any>).map(UserProviderToJSON)),
    };
}

