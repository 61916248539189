/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetUserSessionOmniauthValue200ResponseAllOfDataInfo } from './GetUserSessionOmniauthValue200ResponseAllOfDataInfo';
import {
    GetUserSessionOmniauthValue200ResponseAllOfDataInfoFromJSON,
    GetUserSessionOmniauthValue200ResponseAllOfDataInfoFromJSONTyped,
    GetUserSessionOmniauthValue200ResponseAllOfDataInfoToJSON,
} from './GetUserSessionOmniauthValue200ResponseAllOfDataInfo';

/**
 * 
 * @export
 * @interface GetUserSessionOmniauthValue200ResponseAllOfData
 */
export interface GetUserSessionOmniauthValue200ResponseAllOfData {
    /**
     * 
     * @type {string}
     * @memberof GetUserSessionOmniauthValue200ResponseAllOfData
     */
    provider: GetUserSessionOmniauthValue200ResponseAllOfDataProviderEnum;
    /**
     * 
     * @type {GetUserSessionOmniauthValue200ResponseAllOfDataInfo}
     * @memberof GetUserSessionOmniauthValue200ResponseAllOfData
     */
    info: GetUserSessionOmniauthValue200ResponseAllOfDataInfo;
}


/**
 * @export
 */
export const GetUserSessionOmniauthValue200ResponseAllOfDataProviderEnum = {
    Twitter: 'twitter'
} as const;
export type GetUserSessionOmniauthValue200ResponseAllOfDataProviderEnum = typeof GetUserSessionOmniauthValue200ResponseAllOfDataProviderEnum[keyof typeof GetUserSessionOmniauthValue200ResponseAllOfDataProviderEnum];


/**
 * Check if a given object implements the GetUserSessionOmniauthValue200ResponseAllOfData interface.
 */
export function instanceOfGetUserSessionOmniauthValue200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "provider" in value;
    isInstance = isInstance && "info" in value;

    return isInstance;
}

export function GetUserSessionOmniauthValue200ResponseAllOfDataFromJSON(json: any): GetUserSessionOmniauthValue200ResponseAllOfData {
    return GetUserSessionOmniauthValue200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetUserSessionOmniauthValue200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserSessionOmniauthValue200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'provider': json['provider'],
        'info': GetUserSessionOmniauthValue200ResponseAllOfDataInfoFromJSON(json['info']),
    };
}

export function GetUserSessionOmniauthValue200ResponseAllOfDataToJSON(value?: GetUserSessionOmniauthValue200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'provider': value.provider,
        'info': GetUserSessionOmniauthValue200ResponseAllOfDataInfoToJSON(value.info),
    };
}

