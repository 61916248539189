/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Inquiry } from './Inquiry';
import {
    InquiryFromJSON,
    InquiryFromJSONTyped,
    InquiryToJSON,
} from './Inquiry';
import type { InquiryReply } from './InquiryReply';
import {
    InquiryReplyFromJSON,
    InquiryReplyFromJSONTyped,
    InquiryReplyToJSON,
} from './InquiryReply';

/**
 * 
 * @export
 * @interface PostInquiryReply201ResponseAllOfData
 */
export interface PostInquiryReply201ResponseAllOfData {
    /**
     * 
     * @type {Inquiry}
     * @memberof PostInquiryReply201ResponseAllOfData
     */
    inquiry: Inquiry;
    /**
     * 
     * @type {InquiryReply}
     * @memberof PostInquiryReply201ResponseAllOfData
     */
    reply: InquiryReply;
}

/**
 * Check if a given object implements the PostInquiryReply201ResponseAllOfData interface.
 */
export function instanceOfPostInquiryReply201ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "inquiry" in value;
    isInstance = isInstance && "reply" in value;

    return isInstance;
}

export function PostInquiryReply201ResponseAllOfDataFromJSON(json: any): PostInquiryReply201ResponseAllOfData {
    return PostInquiryReply201ResponseAllOfDataFromJSONTyped(json, false);
}

export function PostInquiryReply201ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostInquiryReply201ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inquiry': InquiryFromJSON(json['inquiry']),
        'reply': InquiryReplyFromJSON(json['reply']),
    };
}

export function PostInquiryReply201ResponseAllOfDataToJSON(value?: PostInquiryReply201ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inquiry': InquiryToJSON(value.inquiry),
        'reply': InquiryReplyToJSON(value.reply),
    };
}

