/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from "classnames";
import { ComponentType, forwardRef, Ref, ReactNode, ComponentProps } from "react";

import { DateSelectInput } from "../DateSelectInput";
import { ErrorMessage } from "../ErrorMessage";
import { InputLabel } from "../InputLabel";
import { SelectInput } from "../SelectInput";
import { TextAreaInput } from "../TextAreaInput";
import { TextInput } from "../TextInput";

export type BaseInputGroupProps<T extends ComponentType<any>> = {
  label?: ReactNode;
  error?: string;
  note?: string;
  required?: boolean;
  input: T;
  inputMode?: string;
  inputClassName?: string;
  inputContainerClassName?: string;
  labelContainerClassName?: string;
  className?: string;
} & ComponentProps<T>;

export type BaseTextInputGroupProps = BaseInputGroupProps<typeof TextInput>;
export type BaseTextAreaInputGroupProps = BaseInputGroupProps<typeof TextAreaInput>;
export type BaseSelectInputGroupProps = BaseInputGroupProps<typeof SelectInput>;
export type BaseDateSelectInputGroupProps = BaseInputGroupProps<typeof DateSelectInput>;
export type BaseAnyInputGroupProps = BaseInputGroupProps<ComponentType<any>>;
export type InputGroupProps = BaseTextInputGroupProps | BaseTextAreaInputGroupProps | BaseSelectInputGroupProps | BaseAnyInputGroupProps;

type InputGroupType = {
  (props: BaseTextInputGroupProps): JSX.Element;
  (props: BaseTextAreaInputGroupProps): JSX.Element;
  (props: BaseSelectInputGroupProps): JSX.Element;
  (props: BaseAnyInputGroupProps): JSX.Element;
};

export const InputGroup = forwardRef(function InputGroup({
  label,
  error,
  required,
  note,
  input: Input,
  inputClassName,
  inputContainerClassName,
  labelContainerClassName,
  className,
  ...props
}: InputGroupProps, ref: Ref<HTMLInputElement>) {
  const classes = classNames(
    "disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none",
    inputClassName,
  );

  return (
    <div className={classNames(className)}>
      {(label || required) && (
        <InputLabel label={label} required={required} className={classNames("mb-2", labelContainerClassName)} />
      )}
      <div className={inputContainerClassName}>
        <Input {...props as any} ref={ref} className={classes} />
      </div>
      {error && <ErrorMessage message={error} hideIcon />}
      {note && (
        <div className="mt-2 text-sm text-gray-500">{note}</div>
      )}
    </div>
  );
}) as InputGroupType;
/* eslint-enable @typescript-eslint/no-explicit-any */
