import classNames from "classnames";
import { ReactNode } from "react";

import { BFC } from "shared/types";

type Props = {
  label?: ReactNode;
  required?: boolean;
};

export const InputLabel: BFC<Props> = ({
  label,
  required,
  className,
}) => {
  return (
    <div className={classNames("flex items-center gap-2", className)}>
      {label && (
        <label className="text-sm font-semibold text-gray-700">{label}</label>
      )}
      {required && (
        <span className="bg-primary rounded px-1 py-px text-xs text-white">必須</span>
      )}
    </div>
  );
};
