/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConnectUserProviderRequestUser
 */
export interface ConnectUserProviderRequestUser {
    /**
     * 
     * @type {string}
     * @memberof ConnectUserProviderRequestUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectUserProviderRequestUser
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectUserProviderRequestUser
     */
    passwordConfirmation: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectUserProviderRequestUser
     */
    nickname: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectUserProviderRequestUser
     */
    gender?: ConnectUserProviderRequestUserGenderEnum;
    /**
     * 
     * @type {Date}
     * @memberof ConnectUserProviderRequestUser
     */
    birthday?: Date;
}


/**
 * @export
 */
export const ConnectUserProviderRequestUserGenderEnum = {
    Unknown: 'unknown',
    Man: 'man',
    Woman: 'woman'
} as const;
export type ConnectUserProviderRequestUserGenderEnum = typeof ConnectUserProviderRequestUserGenderEnum[keyof typeof ConnectUserProviderRequestUserGenderEnum];


/**
 * Check if a given object implements the ConnectUserProviderRequestUser interface.
 */
export function instanceOfConnectUserProviderRequestUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "password" in value;
    isInstance = isInstance && "passwordConfirmation" in value;
    isInstance = isInstance && "nickname" in value;

    return isInstance;
}

export function ConnectUserProviderRequestUserFromJSON(json: any): ConnectUserProviderRequestUser {
    return ConnectUserProviderRequestUserFromJSONTyped(json, false);
}

export function ConnectUserProviderRequestUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectUserProviderRequestUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'password': json['password'],
        'passwordConfirmation': json['password_confirmation'],
        'nickname': json['nickname'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'birthday': !exists(json, 'birthday') ? undefined : (new Date(json['birthday'])),
    };
}

export function ConnectUserProviderRequestUserToJSON(value?: ConnectUserProviderRequestUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'password': value.password,
        'password_confirmation': value.passwordConfirmation,
        'nickname': value.nickname,
        'gender': value.gender,
        'birthday': value.birthday === undefined ? undefined : (value.birthday.toISOString().substring(0,10)),
    };
}

