import { immerable } from "immer";

import * as api from "shared/services/api/web/models";

export type InquiryReplyType = api.InquiryReply;

export type InquiryReplyRole = api.InquiryReplyRoleEnum;
export const InquiryReplyRole = api.InquiryReplyRoleEnum;

export class InquiryReply implements InquiryReplyType {
  [immerable] = true;

  id = "";
  message = "";
  role: InquiryReplyRole = InquiryReplyRole.User;
  repliedAt = new Date();

  constructor(data: Partial<InquiryReplyType> = {}) {
    Object.assign(this, data);
  }
}
