/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SignUpUserRequestUser } from './SignUpUserRequestUser';
import {
    SignUpUserRequestUserFromJSON,
    SignUpUserRequestUserFromJSONTyped,
    SignUpUserRequestUserToJSON,
} from './SignUpUserRequestUser';

/**
 * 
 * @export
 * @interface SignUpUserRequest
 */
export interface SignUpUserRequest {
    /**
     * 
     * @type {SignUpUserRequestUser}
     * @memberof SignUpUserRequest
     */
    user: SignUpUserRequestUser;
}

/**
 * Check if a given object implements the SignUpUserRequest interface.
 */
export function instanceOfSignUpUserRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "user" in value;

    return isInstance;
}

export function SignUpUserRequestFromJSON(json: any): SignUpUserRequest {
    return SignUpUserRequestFromJSONTyped(json, false);
}

export function SignUpUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignUpUserRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': SignUpUserRequestUserFromJSON(json['user']),
    };
}

export function SignUpUserRequestToJSON(value?: SignUpUserRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': SignUpUserRequestUserToJSON(value.user),
    };
}

