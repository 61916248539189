/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttachableImage } from './AttachableImage';
import {
    AttachableImageFromJSON,
    AttachableImageFromJSONTyped,
    AttachableImageToJSON,
} from './AttachableImage';

/**
 * 
 * @export
 * @interface CarouselItem
 */
export interface CarouselItem {
    /**
     * 
     * @type {string}
     * @memberof CarouselItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselItem
     */
    url: string;
    /**
     * 
     * @type {number}
     * @memberof CarouselItem
     */
    displayPriority: number;
    /**
     * 
     * @type {AttachableImage}
     * @memberof CarouselItem
     */
    image: AttachableImage;
    /**
     * 
     * @type {Date}
     * @memberof CarouselItem
     */
    startAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CarouselItem
     */
    endAt?: Date;
}

/**
 * Check if a given object implements the CarouselItem interface.
 */
export function instanceOfCarouselItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "displayPriority" in value;
    isInstance = isInstance && "image" in value;

    return isInstance;
}

export function CarouselItemFromJSON(json: any): CarouselItem {
    return CarouselItemFromJSONTyped(json, false);
}

export function CarouselItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarouselItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'url': json['url'],
        'displayPriority': json['display_priority'],
        'image': AttachableImageFromJSON(json['image']),
        'startAt': !exists(json, 'start_at') ? undefined : (new Date(json['start_at'])),
        'endAt': !exists(json, 'end_at') ? undefined : (new Date(json['end_at'])),
    };
}

export function CarouselItemToJSON(value?: CarouselItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'url': value.url,
        'display_priority': value.displayPriority,
        'image': AttachableImageToJSON(value.image),
        'start_at': value.startAt === undefined ? undefined : (value.startAt.toISOString()),
        'end_at': value.endAt === undefined ? undefined : (value.endAt.toISOString()),
    };
}

