/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    topicsCount: number;
    /**
     * 
     * @type {boolean}
     * @memberof Category
     */
    isRoot: boolean;
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    displayPriority: number;
    /**
     * 
     * @type {Category}
     * @memberof Category
     */
    parent?: Category;
}

/**
 * Check if a given object implements the Category interface.
 */
export function instanceOfCategory(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "topicsCount" in value;
    isInstance = isInstance && "isRoot" in value;
    isInstance = isInstance && "displayPriority" in value;

    return isInstance;
}

export function CategoryFromJSON(json: any): Category {
    return CategoryFromJSONTyped(json, false);
}

export function CategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Category {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'topicsCount': json['topics_count'],
        'isRoot': json['is_root'],
        'displayPriority': json['display_priority'],
        'parent': !exists(json, 'parent') ? undefined : CategoryFromJSON(json['parent']),
    };
}

export function CategoryToJSON(value?: Category | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'topics_count': value.topicsCount,
        'is_root': value.isRoot,
        'display_priority': value.displayPriority,
        'parent': CategoryToJSON(value.parent),
    };
}

