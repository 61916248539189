import { RevenueStatus, PayoutStatus } from "shared/models";

export function formatRevenueStatus(status: RevenueStatus) {
  switch (status) {
    case RevenueStatus.Received:
      return "未受取り";
    case RevenueStatus.Accepted:
      return "受取済み";
    case RevenueStatus.Expired:
      return "期限切れ";
    default:
      return "不正なステータスです";
  }
}

export function formatPayoutStatus(status: PayoutStatus) {
  switch (status) {
    case PayoutStatus.Requested:
      return "申請済み";
    case PayoutStatus.InProgress:
      return "手続き中";
    case PayoutStatus.Completed:
      return "手続き完了";
    case PayoutStatus.Canceled:
      return "キャンセル済み";
    case PayoutStatus.Failed:
      return "失敗";
    case PayoutStatus.Rejected:
      return "拒否されました";
    default:
      return "不正なステータスです";
  }
}
