import { useRouter } from "next/navigation";
import { MouseEvent, useCallback } from "react";

import { twClassNames } from "shared/lib/tailwind";
import { CFC } from "shared/types";

type Props = {
  primary?: boolean;
  selected?: boolean;
  href?: string;
  disabled?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const RoundButton: CFC<Props> = ({
  type = "button",
  href,
  primary,
  selected,
  disabled,
  className,
  onClick,
  children,
  ...props
}) => {
  const route = useRouter();

  const onClickHandler = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    if (disabled) return;

    if (href) {
      route.push(href);
    }

    onClick?.(e);
  }, [onClick, href, disabled]);

  const classes = twClassNames(
    "inline-flex items-center rounded-full border px-4 py-2 text-sm font-medium bg-white",
    "disabled:bg-gray-300 disabled:text-black-500 disabled:cursor-not-allowed",
    {
      "bg-neutral-500 text-white border-neutral-500": selected,
    },
    className,
  );

  return (
    <button {...props} type={type} className={classes} disabled={disabled} onClick={onClickHandler}>
      {children}
    </button>
  );
};
