/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetUserBankAccounts200ResponseAllOfData } from './GetUserBankAccounts200ResponseAllOfData';
import {
    GetUserBankAccounts200ResponseAllOfDataFromJSON,
    GetUserBankAccounts200ResponseAllOfDataFromJSONTyped,
    GetUserBankAccounts200ResponseAllOfDataToJSON,
} from './GetUserBankAccounts200ResponseAllOfData';

/**
 * 
 * @export
 * @interface GetUserBankAccounts200Response
 */
export interface GetUserBankAccounts200Response {
    /**
     * 
     * @type {GetUserBankAccounts200ResponseAllOfData}
     * @memberof GetUserBankAccounts200Response
     */
    data: GetUserBankAccounts200ResponseAllOfData;
    /**
     * 
     * @type {object}
     * @memberof GetUserBankAccounts200Response
     */
    meta: object;
}

/**
 * Check if a given object implements the GetUserBankAccounts200Response interface.
 */
export function instanceOfGetUserBankAccounts200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function GetUserBankAccounts200ResponseFromJSON(json: any): GetUserBankAccounts200Response {
    return GetUserBankAccounts200ResponseFromJSONTyped(json, false);
}

export function GetUserBankAccounts200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserBankAccounts200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': GetUserBankAccounts200ResponseAllOfDataFromJSON(json['data']),
        'meta': json['meta'],
    };
}

export function GetUserBankAccounts200ResponseToJSON(value?: GetUserBankAccounts200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': GetUserBankAccounts200ResponseAllOfDataToJSON(value.data),
        'meta': value.meta,
    };
}

