import { immerable } from "immer";

import * as api from "shared/services/api/web/models";

import { AttachableImage, AttachableImageType } from "./attachable_image";

export type CarouselItemType = api.CarouselItem;

export class CarouselItem implements CarouselItemType {
  [immerable] = true;

  id = "";
  url = "";
  image: AttachableImageType = new AttachableImage();
  displayPriority = 0;
  startAt?: Date;
  endAt?: Date;

  constructor(data: Partial<CarouselItemType> = {}) {
    Object.assign(this, data);
  }
}
