/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PostInquiryRequestInquiry } from './PostInquiryRequestInquiry';
import {
    PostInquiryRequestInquiryFromJSON,
    PostInquiryRequestInquiryFromJSONTyped,
    PostInquiryRequestInquiryToJSON,
} from './PostInquiryRequestInquiry';

/**
 * 
 * @export
 * @interface PostInquiryRequest
 */
export interface PostInquiryRequest {
    /**
     * 
     * @type {PostInquiryRequestInquiry}
     * @memberof PostInquiryRequest
     */
    inquiry: PostInquiryRequestInquiry;
}

/**
 * Check if a given object implements the PostInquiryRequest interface.
 */
export function instanceOfPostInquiryRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "inquiry" in value;

    return isInstance;
}

export function PostInquiryRequestFromJSON(json: any): PostInquiryRequest {
    return PostInquiryRequestFromJSONTyped(json, false);
}

export function PostInquiryRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostInquiryRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inquiry': PostInquiryRequestInquiryFromJSON(json['inquiry']),
    };
}

export function PostInquiryRequestToJSON(value?: PostInquiryRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inquiry': PostInquiryRequestInquiryToJSON(value.inquiry),
    };
}

