import { immerable } from "immer";

import * as api from "shared/services/api/web/models";

export type PositionType = api.Position;

export class Position implements PositionType {
  [immerable] = true;

  id = "";
  name = "";
  votesCount = 0;
  votesAmount = 0;
  proposalsCount = 0;

  constructor(data: Partial<PositionType> = {}) {
    Object.assign(this, data);
  }
}
