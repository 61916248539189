import { forwardRef } from "react";

import { InputGroup, BaseTextInputGroupProps } from "../InputGroup";
import { TextInput } from "../TextInput";

export type TextInputGroupProps = Omit<BaseTextInputGroupProps, "input">;

export const TextInputGroup = forwardRef<HTMLInputElement, TextInputGroupProps>(function TextInputGroup(props, ref) {
  return (
    <InputGroup {...props} ref={ref} input={TextInput} />
  );
});
