/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VoteTopicRequestVote
 */
export interface VoteTopicRequestVote {
    /**
     * 
     * @type {string}
     * @memberof VoteTopicRequestVote
     */
    body?: string;
    /**
     * 
     * @type {string}
     * @memberof VoteTopicRequestVote
     */
    positionId: string;
}

/**
 * Check if a given object implements the VoteTopicRequestVote interface.
 */
export function instanceOfVoteTopicRequestVote(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "positionId" in value;

    return isInstance;
}

export function VoteTopicRequestVoteFromJSON(json: any): VoteTopicRequestVote {
    return VoteTopicRequestVoteFromJSONTyped(json, false);
}

export function VoteTopicRequestVoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): VoteTopicRequestVote {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'body': !exists(json, 'body') ? undefined : json['body'],
        'positionId': json['position_id'],
    };
}

export function VoteTopicRequestVoteToJSON(value?: VoteTopicRequestVote | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'body': value.body,
        'position_id': value.positionId,
    };
}

