/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CalculateTopicRequestTopic
 */
export interface CalculateTopicRequestTopic {
    /**
     * 
     * @type {string}
     * @memberof CalculateTopicRequestTopic
     */
    rewardType: CalculateTopicRequestTopicRewardTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CalculateTopicRequestTopic
     */
    rewardAmount: number;
    /**
     * 
     * @type {string}
     * @memberof CalculateTopicRequestTopic
     */
    rewardCurrency: CalculateTopicRequestTopicRewardCurrencyEnum;
    /**
     * 
     * @type {Date}
     * @memberof CalculateTopicRequestTopic
     */
    expiresAt?: Date;
}


/**
 * @export
 */
export const CalculateTopicRequestTopicRewardTypeEnum = {
    None: 'none',
    AllForBest: 'all_for_best',
    DistributeByOwner: 'distribute_by_owner',
    DistributeByAuto: 'distribute_by_auto'
} as const;
export type CalculateTopicRequestTopicRewardTypeEnum = typeof CalculateTopicRequestTopicRewardTypeEnum[keyof typeof CalculateTopicRequestTopicRewardTypeEnum];

/**
 * @export
 */
export const CalculateTopicRequestTopicRewardCurrencyEnum = {
    Jpy: 'jpy',
    Usd: 'usd'
} as const;
export type CalculateTopicRequestTopicRewardCurrencyEnum = typeof CalculateTopicRequestTopicRewardCurrencyEnum[keyof typeof CalculateTopicRequestTopicRewardCurrencyEnum];


/**
 * Check if a given object implements the CalculateTopicRequestTopic interface.
 */
export function instanceOfCalculateTopicRequestTopic(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "rewardType" in value;
    isInstance = isInstance && "rewardAmount" in value;
    isInstance = isInstance && "rewardCurrency" in value;

    return isInstance;
}

export function CalculateTopicRequestTopicFromJSON(json: any): CalculateTopicRequestTopic {
    return CalculateTopicRequestTopicFromJSONTyped(json, false);
}

export function CalculateTopicRequestTopicFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalculateTopicRequestTopic {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rewardType': json['reward_type'],
        'rewardAmount': json['reward_amount'],
        'rewardCurrency': json['reward_currency'],
        'expiresAt': !exists(json, 'expires_at') ? undefined : (new Date(json['expires_at'])),
    };
}

export function CalculateTopicRequestTopicToJSON(value?: CalculateTopicRequestTopic | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reward_type': value.rewardType,
        'reward_amount': value.rewardAmount,
        'reward_currency': value.rewardCurrency,
        'expires_at': value.expiresAt === undefined ? undefined : (value.expiresAt.toISOString().substring(0,10)),
    };
}

