import classNames from "classnames";
import { forwardRef, Ref, ReactNode } from "react";

export type ToggleInputProps = {
  label: string | ReactNode;
  position?: "left" | "right";
  containerClassName?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const ToggleInput = forwardRef(function ToggleInput({
  type,
  label,
  position = "left",
  containerClassName,
  ...props
}: ToggleInputProps, ref: Ref<HTMLInputElement>) {
  return (
    <div className={classNames(containerClassName)}>
      <label className="relative flex w-full cursor-pointer items-center justify-between">
        <input
          {...props}
          ref={ref}
          type="checkbox"
          className="peer sr-only"
        />
        {position === "right" && (
          <span className="font-medium dark:text-gray-300">{label}</span>
        )}
        <div className={classNames(
          "h-6 w-11 rounded-full bg-gray-200",
          "after:absolute after:top-[2px] after:h-5 after:w-5",
          "after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-['']",
          "peer peer-checked:bg-primary peer-checked:after:translate-x-full peer-checked:after:border-white",
          "peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green-300",
          "rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-green-800",
          {
            "after:start-[2px]": position === "left",
            "after:end-[22px]": position === "right",
          }
        )} />
        {position === "left" && (
          <span className="font-medium dark:text-gray-300">{label}</span>
        )}
      </label>
    </div>
  );
});
