import classNames from "classnames";
import NextLink from "next/link";

import { CFC } from "shared/types";

type Props = {
  href: string;
  decoration?: boolean;
};

export const Link: CFC<Props> = ({
  href,
  decoration,
  className,
  children,
}) => {
  const classes = classNames(
    {
      "text-primary hover:underline": decoration,
    },
    className,
  );

  return (
    <NextLink href={href} className={classes}>
      {children}
    </NextLink>
  );
};
