/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PostTopicRequestPositionsInner } from './PostTopicRequestPositionsInner';
import {
    PostTopicRequestPositionsInnerFromJSON,
    PostTopicRequestPositionsInnerFromJSONTyped,
    PostTopicRequestPositionsInnerToJSON,
} from './PostTopicRequestPositionsInner';
import type { PostTopicRequestTopic } from './PostTopicRequestTopic';
import {
    PostTopicRequestTopicFromJSON,
    PostTopicRequestTopicFromJSONTyped,
    PostTopicRequestTopicToJSON,
} from './PostTopicRequestTopic';

/**
 * 
 * @export
 * @interface PostTopicRequest
 */
export interface PostTopicRequest {
    /**
     * 
     * @type {PostTopicRequestTopic}
     * @memberof PostTopicRequest
     */
    topic: PostTopicRequestTopic;
    /**
     * 
     * @type {Array<PostTopicRequestPositionsInner>}
     * @memberof PostTopicRequest
     */
    positions?: Array<PostTopicRequestPositionsInner>;
}

/**
 * Check if a given object implements the PostTopicRequest interface.
 */
export function instanceOfPostTopicRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "topic" in value;

    return isInstance;
}

export function PostTopicRequestFromJSON(json: any): PostTopicRequest {
    return PostTopicRequestFromJSONTyped(json, false);
}

export function PostTopicRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostTopicRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'topic': PostTopicRequestTopicFromJSON(json['topic']),
        'positions': !exists(json, 'positions') ? undefined : ((json['positions'] as Array<any>).map(PostTopicRequestPositionsInnerFromJSON)),
    };
}

export function PostTopicRequestToJSON(value?: PostTopicRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'topic': PostTopicRequestTopicToJSON(value.topic),
        'positions': value.positions === undefined ? undefined : ((value.positions as Array<any>).map(PostTopicRequestPositionsInnerToJSON)),
    };
}

