/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Money } from './Money';
import {
    MoneyFromJSON,
    MoneyFromJSONTyped,
    MoneyToJSON,
} from './Money';
import type { Proposal } from './Proposal';
import {
    ProposalFromJSON,
    ProposalFromJSONTyped,
    ProposalToJSON,
} from './Proposal';

/**
 * 
 * @export
 * @interface TopicBestProposal
 */
export interface TopicBestProposal {
    /**
     * 
     * @type {string}
     * @memberof TopicBestProposal
     */
    id: string;
    /**
     * 
     * @type {Proposal}
     * @memberof TopicBestProposal
     */
    proposal?: Proposal;
    /**
     * 
     * @type {boolean}
     * @memberof TopicBestProposal
     */
    best: boolean;
    /**
     * 
     * @type {Money}
     * @memberof TopicBestProposal
     */
    rewardAmount?: Money;
    /**
     * 
     * @type {string}
     * @memberof TopicBestProposal
     */
    thanksComment?: string;
}

/**
 * Check if a given object implements the TopicBestProposal interface.
 */
export function instanceOfTopicBestProposal(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "best" in value;

    return isInstance;
}

export function TopicBestProposalFromJSON(json: any): TopicBestProposal {
    return TopicBestProposalFromJSONTyped(json, false);
}

export function TopicBestProposalFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopicBestProposal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'proposal': !exists(json, 'proposal') ? undefined : ProposalFromJSON(json['proposal']),
        'best': json['best'],
        'rewardAmount': !exists(json, 'reward_amount') ? undefined : MoneyFromJSON(json['reward_amount']),
        'thanksComment': !exists(json, 'thanks_comment') ? undefined : json['thanks_comment'],
    };
}

export function TopicBestProposalToJSON(value?: TopicBestProposal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'proposal': ProposalToJSON(value.proposal),
        'best': value.best,
        'reward_amount': MoneyToJSON(value.rewardAmount),
        'thanks_comment': value.thanksComment,
    };
}

