import { immerable } from "immer";

import * as api from "shared/services/api/web/models";

import { Money, MoneyType } from "./money";
import { Proposal, ProposalType } from "./proposal";
import { Support, SupportType } from "./support";
import { Topic, TopicType } from "./topic";
import { User, UserType } from "./user";

export type RevenuType = api.Revenue;

export type RevenueStatus = api.RevenueStatusEnum;
export const RevenueStatus = api.RevenueStatusEnum;

export class Revenue implements RevenuType {
  [immerable] = true;

  id = "";
  amount: MoneyType = new Money();
  fee: MoneyType = new Money();
  netAmount: MoneyType = new Money();
  status: RevenueStatus = RevenueStatus.Received;
  receivedAt = new Date();
  expiresAt?: Date;
  description = "";
  sourceUser?: UserType;
  topic?: TopicType;
  proposal?: ProposalType;
  support?: SupportType;

  constructor(data: Partial<RevenuType> = {}) {
    Object.assign(this, data);
  }

  getSourceUser() {
    if (this.sourceUser) {
      return new User(this.sourceUser);
    }
  }

  getTopic() {
    if (this.topic) {
      return new Topic(this.topic);
    }
  }

  getProposal() {
    if (this.proposal) {
      return new Proposal(this.proposal);
    }
  }

  getSupport() {
    if (this.support) {
      return new Support(this.support);
    }
  }

  isReceivedDate(date: Date) {
    return new Date(this.receivedAt).toDateString() === date.toDateString();
  }
}
