import { FocusEventHandler, ChangeEventHandler, ChangeEvent, useCallback, FocusEvent } from "react";
import { FieldValues, FieldPath, useFormContext } from "react-hook-form";

import { TextInputGroup, TextInputGroupProps } from "../TextInputGroup";

type Props<Data extends FieldValues = FieldValues> = {
  name: FieldPath<Data>;
} & TextInputGroupProps;

export const TextControlGroup = <Data extends FieldValues = FieldValues, T extends HTMLInputElement = HTMLInputElement>({
  name,
  error,
  onBlur,
  onChange,
  ...props
}: Props<Data>) => {
  const { register, formState, getFieldState } = useFormContext<Data>();
  const fieldState = getFieldState(name, formState);
  const field = register(name);

  const onBlurHandler = useCallback((hookOnBlur: FocusEventHandler<T>): FocusEventHandler<T> => (e: FocusEvent<T>) => {
    hookOnBlur(e);
    onBlur?.(e);
  }, [onBlur]);

  const onChangeHandler = useCallback((hookOnChange: ChangeEventHandler<T>): ChangeEventHandler<T> => (e: ChangeEvent<T>) => {
    hookOnChange(e);
    onChange?.(e);
  }, [onChange]);

  return (
    <TextInputGroup
      {...props}
      {...field}
      onBlur={onBlurHandler(field.onBlur)}
      onChange={onChangeHandler(field.onChange)}
      invalid={!!fieldState.error}
      error={error || fieldState.error?.message}
    />
  );
};
