import { Component, ErrorInfo, ReactNode } from "react";

type Props = {
  fallback?: (error: Error) => ReactNode;
  children: ReactNode;
};

type State = {
  error: Error | null;
  errorInfo: ErrorInfo | null;
  hasError: boolean;
};

export class ErrorBoundary extends Component<Props, State> {
  state = { error: null, errorInfo: null, hasError: false };

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log("error", error);
    console.log("errorInfo", errorInfo);
    this.setState({
      error,
      errorInfo,
      hasError: true,
    });
  }

  componentDidMount() {
    window.addEventListener("unhandledrejection", this.handleRejection);
  }

  componentWillUnmount() {
    window.removeEventListener("unhandledrejection", this.handleRejection);
  }

  private handleRejection = (event: PromiseRejectionEvent) => {
    event.promise.catch((error) => {
      this.setState(ErrorBoundary.getDerivedStateFromError(error));
    });
  };

  render() {
    const { error } = this.state;
    const { fallback, children } = this.props;

    if (error && fallback) {
      return fallback(error);
    }

    return children;
  }
}
