import { immerable } from "immer";

import * as api from "shared/services/api/web/models";

export type MoneyType = api.Money;

export type MoneyCurrency = api.MoneyCurrencyEnum;
export const MoneyCurrency = api.MoneyCurrencyEnum;

export class Money implements MoneyType {
  [immerable] = true;

  amount = 0;
  amountCents = 0;
  currency = MoneyCurrency.Jpy;

  constructor(data: Partial<MoneyType> = {}) {
    Object.assign(this, data);
    if (data.amount && !data.amountCents && data.currency) {
      this.amountCents = calculateCents(data.amount, data.currency);
    }
  }
}

export function calculateCents(amount: number, currency: MoneyCurrency): number {
  switch (currency) {
    case MoneyCurrency.Jpy:
      return amount;
    default:
      return amount * 100;
  }
}
