import { useRouter } from "next/navigation";
import { MouseEvent, useCallback } from "react";

import { twClassNames } from "shared/lib/tailwind";
import { CFC } from "shared/types";

type Props = {
  large?: boolean;
  small?: boolean;
  block?: boolean;
  primary?: boolean;
  href?: string;
  disabled?: boolean;
  loading?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button: CFC<Props> = ({
  type = "button",
  href,
  large,
  small,
  block,
  primary,
  disabled,
  loading,
  className,
  onClick,
  children,
  ...props
}) => {
  const route = useRouter();

  const onClickHandler = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    if (disabled || loading) return;

    if (href) {
      route.push(href);
    }

    onClick?.(e);
  }, [onClick, href, disabled, loading]);

  const classes = twClassNames(
    "inline-flex items-center rounded border px-4 py-2 text-base font-medium shadow-sm",
    "hover:bg-gray-50",
    "disabled:text-black-300 disabled:cursor-not-allowed",
    {
      "px-6 py-3": large,
      "px-3 py-1 text-sm": small,
      "!flex w-full justify-center": block,
      "bg-white text-gray-700": !primary,
      "bg-primary text-white border-transparent hover:bg-primary-600 font-bold": primary,
      "disabled:bg-gray-300 disabled:text-white disabled:border-transparent": primary,
    },
    className,
  );

  return (
    <button {...props} type={type} className={classes} disabled={disabled || loading} onClick={onClickHandler}>
      {loading && (
        <div role="status" className="border-t-primary mr-2 h-4 w-4 animate-spin rounded-full border-2 border-gray-200" />
      )}
      {children}
    </button>
  );
};
