/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CalculateTopicRequestTopic } from './CalculateTopicRequestTopic';
import {
    CalculateTopicRequestTopicFromJSON,
    CalculateTopicRequestTopicFromJSONTyped,
    CalculateTopicRequestTopicToJSON,
} from './CalculateTopicRequestTopic';

/**
 * 
 * @export
 * @interface CalculateTopicRequest
 */
export interface CalculateTopicRequest {
    /**
     * 
     * @type {CalculateTopicRequestTopic}
     * @memberof CalculateTopicRequest
     */
    topic: CalculateTopicRequestTopic;
}

/**
 * Check if a given object implements the CalculateTopicRequest interface.
 */
export function instanceOfCalculateTopicRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "topic" in value;

    return isInstance;
}

export function CalculateTopicRequestFromJSON(json: any): CalculateTopicRequest {
    return CalculateTopicRequestFromJSONTyped(json, false);
}

export function CalculateTopicRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalculateTopicRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'topic': CalculateTopicRequestTopicFromJSON(json['topic']),
    };
}

export function CalculateTopicRequestToJSON(value?: CalculateTopicRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'topic': CalculateTopicRequestTopicToJSON(value.topic),
    };
}

