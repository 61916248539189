/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetUserSessionOmniauthValue200ResponseAllOfDataInfo
 */
export interface GetUserSessionOmniauthValue200ResponseAllOfDataInfo {
    /**
     * 
     * @type {string}
     * @memberof GetUserSessionOmniauthValue200ResponseAllOfDataInfo
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserSessionOmniauthValue200ResponseAllOfDataInfo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserSessionOmniauthValue200ResponseAllOfDataInfo
     */
    nickname?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserSessionOmniauthValue200ResponseAllOfDataInfo
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserSessionOmniauthValue200ResponseAllOfDataInfo
     */
    image?: string;
}

/**
 * Check if a given object implements the GetUserSessionOmniauthValue200ResponseAllOfDataInfo interface.
 */
export function instanceOfGetUserSessionOmniauthValue200ResponseAllOfDataInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetUserSessionOmniauthValue200ResponseAllOfDataInfoFromJSON(json: any): GetUserSessionOmniauthValue200ResponseAllOfDataInfo {
    return GetUserSessionOmniauthValue200ResponseAllOfDataInfoFromJSONTyped(json, false);
}

export function GetUserSessionOmniauthValue200ResponseAllOfDataInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserSessionOmniauthValue200ResponseAllOfDataInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'nickname': !exists(json, 'nickname') ? undefined : json['nickname'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'image': !exists(json, 'image') ? undefined : json['image'],
    };
}

export function GetUserSessionOmniauthValue200ResponseAllOfDataInfoToJSON(value?: GetUserSessionOmniauthValue200ResponseAllOfDataInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'name': value.name,
        'nickname': value.nickname,
        'description': value.description,
        'image': value.image,
    };
}

