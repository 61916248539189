/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TopicBestProposal } from './TopicBestProposal';
import {
    TopicBestProposalFromJSON,
    TopicBestProposalFromJSONTyped,
    TopicBestProposalToJSON,
} from './TopicBestProposal';

/**
 * 
 * @export
 * @interface CreateBestProposals201ResponseAllOfData
 */
export interface CreateBestProposals201ResponseAllOfData {
    /**
     * 
     * @type {Array<TopicBestProposal>}
     * @memberof CreateBestProposals201ResponseAllOfData
     */
    bestProposals: Array<TopicBestProposal>;
}

/**
 * Check if a given object implements the CreateBestProposals201ResponseAllOfData interface.
 */
export function instanceOfCreateBestProposals201ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "bestProposals" in value;

    return isInstance;
}

export function CreateBestProposals201ResponseAllOfDataFromJSON(json: any): CreateBestProposals201ResponseAllOfData {
    return CreateBestProposals201ResponseAllOfDataFromJSONTyped(json, false);
}

export function CreateBestProposals201ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBestProposals201ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bestProposals': ((json['best_proposals'] as Array<any>).map(TopicBestProposalFromJSON)),
    };
}

export function CreateBestProposals201ResponseAllOfDataToJSON(value?: CreateBestProposals201ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'best_proposals': ((value.bestProposals as Array<any>).map(TopicBestProposalToJSON)),
    };
}

