import { useCallback } from "react";
import { FieldPath, FieldValues, useFormContext } from "react-hook-form";

import { RadioInput } from "../RadioInput";
import { RadioInputGroup, RadioInputGroupProps } from "../RadioInputGroup";

type Props<Data extends FieldValues = FieldValues> = {
  name: FieldPath<Data>;
} & RadioInputGroupProps;

export const RadioControlGroup = <Data extends FieldValues = FieldValues>({
  name,
  value,
  inputLable,
  ...props
}: Props<Data>) => {
  const { register, formState, getFieldState } = useFormContext<Data>();
  const fieldState = getFieldState(name, formState);

  const renderItem = useCallback(() => {
    return (
      <RadioInput
        {...register(name)}
        value={value}
        label={inputLable}
      />
    );
  }, [name, value, inputLable]);

  return (
    <RadioInputGroup
      {...props}
      renderItem={renderItem}
      invalid={!!fieldState.error}
      error={fieldState.error?.message}
    />
  );
};
