/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PutProposalRequestProposal
 */
export interface PutProposalRequestProposal {
    /**
     * 
     * @type {string}
     * @memberof PutProposalRequestProposal
     */
    body: string;
    /**
     * 
     * @type {string}
     * @memberof PutProposalRequestProposal
     */
    bodyText: string;
    /**
     * 
     * @type {string}
     * @memberof PutProposalRequestProposal
     */
    positionId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PutProposalRequestProposal
     */
    asAnonymous?: boolean;
}

/**
 * Check if a given object implements the PutProposalRequestProposal interface.
 */
export function instanceOfPutProposalRequestProposal(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "body" in value;
    isInstance = isInstance && "bodyText" in value;

    return isInstance;
}

export function PutProposalRequestProposalFromJSON(json: any): PutProposalRequestProposal {
    return PutProposalRequestProposalFromJSONTyped(json, false);
}

export function PutProposalRequestProposalFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutProposalRequestProposal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'body': json['body'],
        'bodyText': json['body_text'],
        'positionId': !exists(json, 'position_id') ? undefined : json['position_id'],
        'asAnonymous': !exists(json, 'as_anonymous') ? undefined : json['as_anonymous'],
    };
}

export function PutProposalRequestProposalToJSON(value?: PutProposalRequestProposal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'body': value.body,
        'body_text': value.bodyText,
        'position_id': value.positionId,
        'as_anonymous': value.asAnonymous,
    };
}

