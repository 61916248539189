/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PutProposalRequestProposal } from './PutProposalRequestProposal';
import {
    PutProposalRequestProposalFromJSON,
    PutProposalRequestProposalFromJSONTyped,
    PutProposalRequestProposalToJSON,
} from './PutProposalRequestProposal';

/**
 * 
 * @export
 * @interface PutProposalRequest
 */
export interface PutProposalRequest {
    /**
     * 
     * @type {PutProposalRequestProposal}
     * @memberof PutProposalRequest
     */
    proposal: PutProposalRequestProposal;
}

/**
 * Check if a given object implements the PutProposalRequest interface.
 */
export function instanceOfPutProposalRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "proposal" in value;

    return isInstance;
}

export function PutProposalRequestFromJSON(json: any): PutProposalRequest {
    return PutProposalRequestFromJSONTyped(json, false);
}

export function PutProposalRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutProposalRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'proposal': PutProposalRequestProposalFromJSON(json['proposal']),
    };
}

export function PutProposalRequestToJSON(value?: PutProposalRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'proposal': PutProposalRequestProposalToJSON(value.proposal),
    };
}

