/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserMe } from './UserMe';
import {
    UserMeFromJSON,
    UserMeFromJSONTyped,
    UserMeToJSON,
} from './UserMe';

/**
 * 
 * @export
 * @interface UpdateUserProfile200ResponseAllOfData
 */
export interface UpdateUserProfile200ResponseAllOfData {
    /**
     * 
     * @type {UserMe}
     * @memberof UpdateUserProfile200ResponseAllOfData
     */
    user?: UserMe;
}

/**
 * Check if a given object implements the UpdateUserProfile200ResponseAllOfData interface.
 */
export function instanceOfUpdateUserProfile200ResponseAllOfData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateUserProfile200ResponseAllOfDataFromJSON(json: any): UpdateUserProfile200ResponseAllOfData {
    return UpdateUserProfile200ResponseAllOfDataFromJSONTyped(json, false);
}

export function UpdateUserProfile200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserProfile200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': !exists(json, 'user') ? undefined : UserMeFromJSON(json['user']),
    };
}

export function UpdateUserProfile200ResponseAllOfDataToJSON(value?: UpdateUserProfile200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UserMeToJSON(value.user),
    };
}

