/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetTopicVotes200ResponseAllOfData } from './GetTopicVotes200ResponseAllOfData';
import {
    GetTopicVotes200ResponseAllOfDataFromJSON,
    GetTopicVotes200ResponseAllOfDataFromJSONTyped,
    GetTopicVotes200ResponseAllOfDataToJSON,
} from './GetTopicVotes200ResponseAllOfData';
import type { ResponseSuccessWithPagingAllOfMeta } from './ResponseSuccessWithPagingAllOfMeta';
import {
    ResponseSuccessWithPagingAllOfMetaFromJSON,
    ResponseSuccessWithPagingAllOfMetaFromJSONTyped,
    ResponseSuccessWithPagingAllOfMetaToJSON,
} from './ResponseSuccessWithPagingAllOfMeta';

/**
 * 
 * @export
 * @interface GetTopicVotes200Response
 */
export interface GetTopicVotes200Response {
    /**
     * 
     * @type {GetTopicVotes200ResponseAllOfData}
     * @memberof GetTopicVotes200Response
     */
    data: GetTopicVotes200ResponseAllOfData;
    /**
     * 
     * @type {ResponseSuccessWithPagingAllOfMeta}
     * @memberof GetTopicVotes200Response
     */
    meta: ResponseSuccessWithPagingAllOfMeta;
}

/**
 * Check if a given object implements the GetTopicVotes200Response interface.
 */
export function instanceOfGetTopicVotes200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function GetTopicVotes200ResponseFromJSON(json: any): GetTopicVotes200Response {
    return GetTopicVotes200ResponseFromJSONTyped(json, false);
}

export function GetTopicVotes200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTopicVotes200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': GetTopicVotes200ResponseAllOfDataFromJSON(json['data']),
        'meta': ResponseSuccessWithPagingAllOfMetaFromJSON(json['meta']),
    };
}

export function GetTopicVotes200ResponseToJSON(value?: GetTopicVotes200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': GetTopicVotes200ResponseAllOfDataToJSON(value.data),
        'meta': ResponseSuccessWithPagingAllOfMetaToJSON(value.meta),
    };
}

