/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Revenue } from './Revenue';
import {
    RevenueFromJSON,
    RevenueFromJSONTyped,
    RevenueToJSON,
} from './Revenue';

/**
 * 
 * @export
 * @interface GetUserRevenues200ResponseAllOfData
 */
export interface GetUserRevenues200ResponseAllOfData {
    /**
     * 
     * @type {Array<Revenue>}
     * @memberof GetUserRevenues200ResponseAllOfData
     */
    revenues: Array<Revenue>;
}

/**
 * Check if a given object implements the GetUserRevenues200ResponseAllOfData interface.
 */
export function instanceOfGetUserRevenues200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "revenues" in value;

    return isInstance;
}

export function GetUserRevenues200ResponseAllOfDataFromJSON(json: any): GetUserRevenues200ResponseAllOfData {
    return GetUserRevenues200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetUserRevenues200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserRevenues200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'revenues': ((json['revenues'] as Array<any>).map(RevenueFromJSON)),
    };
}

export function GetUserRevenues200ResponseAllOfDataToJSON(value?: GetUserRevenues200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'revenues': ((value.revenues as Array<any>).map(RevenueToJSON)),
    };
}

