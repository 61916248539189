import { MoneyCurrency, MoneyType } from "shared/models";

import { formatNumber } from "./number";

export function formatCurrency(currency: string) {
  switch (currency.toLowerCase()) {
    case "jpy":
      return "¥";
    case "usd":
      return "$";
    case "eur":
      return "€";
  }
}

export function formatPriceWithCurrency(
  number: number,
  currency: string = MoneyCurrency.Jpy,
) {
  return `${formatCurrency(currency)}${formatNumber(number)}`;
}

export function formatMoney(
  money: MoneyType,
) {
  return formatPriceWithCurrency(money.amount, money.currency);
}

export function formatCardBrand(brand: string): string {
  switch (brand) {
    case "visa":
      return "Visa";
    case "mastercard":
      return "MasterCard";
    case "jcb":
      return "JCB";
    case "amex":
      return "American Express";
    case "diners":
      return "Diners Club";
    case "discover":
      return "Discover";
    case "unionpay":
      return "UnionPay";
    default:
      return brand;
  }
}
