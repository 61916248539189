/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CallbackTopicCheckout200ResponseAllOfData } from './CallbackTopicCheckout200ResponseAllOfData';
import {
    CallbackTopicCheckout200ResponseAllOfDataFromJSON,
    CallbackTopicCheckout200ResponseAllOfDataFromJSONTyped,
    CallbackTopicCheckout200ResponseAllOfDataToJSON,
} from './CallbackTopicCheckout200ResponseAllOfData';

/**
 * 
 * @export
 * @interface CallbackTopicCheckout200Response
 */
export interface CallbackTopicCheckout200Response {
    /**
     * 
     * @type {CallbackTopicCheckout200ResponseAllOfData}
     * @memberof CallbackTopicCheckout200Response
     */
    data: CallbackTopicCheckout200ResponseAllOfData;
    /**
     * 
     * @type {object}
     * @memberof CallbackTopicCheckout200Response
     */
    meta: object;
}

/**
 * Check if a given object implements the CallbackTopicCheckout200Response interface.
 */
export function instanceOfCallbackTopicCheckout200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function CallbackTopicCheckout200ResponseFromJSON(json: any): CallbackTopicCheckout200Response {
    return CallbackTopicCheckout200ResponseFromJSONTyped(json, false);
}

export function CallbackTopicCheckout200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CallbackTopicCheckout200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': CallbackTopicCheckout200ResponseAllOfDataFromJSON(json['data']),
        'meta': json['meta'],
    };
}

export function CallbackTopicCheckout200ResponseToJSON(value?: CallbackTopicCheckout200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': CallbackTopicCheckout200ResponseAllOfDataToJSON(value.data),
        'meta': value.meta,
    };
}

