import classNames from "classnames";
import { useEffect, useState } from "react";

import { getDateByYearMonthDay } from "shared/helpers";
import { BFC } from "shared/types";

const selectableYears = [...Array(100)].map((_, i) => {
  return new Date().getFullYear() - i;
});

const selectableMonths = [...Array(12)].map((_, i) => {
  return i + 1;
});

const selectableDays = [...Array(31)].map((_, i) => {
  return i + 1;
});

type Props = {
  inputClassName?: string;
  invalid?: boolean;
  value?: Date;
  onChange?: (value: Date) => void;
  onBlur?: () => void;
};

/**
 * Select タグの組み合わせにより日付を選択するコンポーネント
 * 年月日すべてが選択されたとき初めて onChange が呼び出される
 */
export const DateSelectInput: BFC<Props> = ({
  inputClassName,
  invalid,
  value,
  onChange,
  onBlur,
}) => {
  const [selectedYear, setSelectedYear] = useState<string>(
    value ? `${value.getFullYear()}` : ""
  );
  const [selectedMonth, setSelectedMonth] = useState<string>(
    value ? `${value.getMonth() + 1}` : ""
  );
  const [selectedDay, setSelectedDay] = useState<string>(
    value ? `${value.getDate()}` : ""
  );

  // value prop の変更 -> selectedYear, selectedMonth, selectedDay への反映
  useEffect(() => {
    if (value) {
      setSelectedYear(`${value.getFullYear()}`);
      setSelectedMonth(`${value.getMonth() + 1}`);
      setSelectedDay(`${value.getDate()}`);
    }
  }, [value]);

  // selectedYear, selectedMonth, selectedDay の変更 -> onChange の呼び出し
  useEffect(() => {
    if (selectedYear && selectedMonth && selectedDay) {
      onChange?.(
        getDateByYearMonthDay(selectedYear, selectedMonth, selectedDay)
      );
    }
  }, [selectedYear, selectedMonth, selectedDay]);

  const inputClasses = classNames(
    "rounded border border-gray-300 shadow-sm form-select flex-1",
    inputClassName,
    {
      "border-red-300": invalid,
    }
  );

  return (
    <div className="flex items-center gap-2">
      <label className="flex flex-1 items-center gap-2">
        <select
          className={inputClasses}
          value={selectedYear}
          onChange={(e) => setSelectedYear(e.target.value)}
          onBlur={onBlur}
        >
          <option value="" hidden />
          {selectableYears.map((year) => (
            <option key={year} value={`${year}`}>
              {year}
            </option>
          ))}
        </select>
        <span className="font-semibold text-gray-700">年</span>
      </label>

      <label className="flex flex-1 items-center gap-2">
        <select
          className={inputClasses}
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(e.target.value)}
          onBlur={onBlur}
        >
          <option value="" hidden />
          {selectableMonths.map((month) => (
            <option key={month} value={`${month}`}>
              {month}
            </option>
          ))}
        </select>
        <span className="font-semibold text-gray-700">月</span>
      </label>

      <label className="flex flex-1 items-center gap-2">
        <select
          className={inputClasses}
          value={selectedDay}
          onChange={(e) => setSelectedDay(e.target.value)}
          onBlur={onBlur}
        >
          <option value="" hidden />
          {selectableDays.map((day) => (
            <option key={day} value={`${day}`}>
              {day}
            </option>
          ))}
        </select>
        <span className="font-semibold text-gray-700">日</span>
      </label>
    </div>
  );
};
