import classNames from "classnames";
import { forwardRef, ReactNode, Ref } from "react";
import { useTranslation } from "react-i18next";

import { RadioInput } from "../RadioInput";

export type RadioInputGroupProps = {
  label?: ReactNode;
  renderItem?: () => ReactNode;
  error?: string;
  invalid?: boolean;
  inputLable?: ReactNode;
  inputClassName?: string;
  className?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const RadioInputGroup = forwardRef(function RadioInputGroup({
  label,
  renderItem,
  error,
  invalid,
  inputLable,
  inputClassName,
  className,
  ...props
}: RadioInputGroupProps, ref: Ref<HTMLDivElement>) {
  const { t } = useTranslation();

  return (
    <div ref={ref} className={classNames("mt-3 first:mt-0", className)}>
      {label && (
        <label className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <div className="mt-1 flex gap-x-4 gap-y-3 flex-wrap">
        {renderItem ? renderItem() : (
          <RadioInput {...props} label={inputLable} className={inputClassName} />
        )}
      </div>
      {error && (
        <div className="mt-2 text-sm text-red-600">
          {t(error, { ns: "validation" })}
        </div>
      )}
    </div>
  );
});
