/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Money } from './Money';
import {
    MoneyFromJSON,
    MoneyFromJSONTyped,
    MoneyToJSON,
} from './Money';

/**
 * 
 * @export
 * @interface Checkout
 */
export interface Checkout {
    /**
     * 
     * @type {string}
     * @memberof Checkout
     */
    id: string;
    /**
     * 
     * @type {Money}
     * @memberof Checkout
     */
    subtotalAmount: Money;
    /**
     * 
     * @type {Money}
     * @memberof Checkout
     */
    fee: Money;
    /**
     * 
     * @type {number}
     * @memberof Checkout
     */
    feeRate: number;
    /**
     * 
     * @type {Money}
     * @memberof Checkout
     */
    tax: Money;
    /**
     * 
     * @type {number}
     * @memberof Checkout
     */
    taxRate: number;
    /**
     * 
     * @type {Money}
     * @memberof Checkout
     */
    totalAmount: Money;
    /**
     * 
     * @type {Date}
     * @memberof Checkout
     */
    checkedOutAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Checkout
     */
    cancellableAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Checkout
     */
    status: CheckoutStatusEnum;
}


/**
 * @export
 */
export const CheckoutStatusEnum = {
    Waiting: 'waiting',
    Paid: 'paid',
    Completed: 'completed',
    Canceled: 'canceled',
    Failed: 'failed'
} as const;
export type CheckoutStatusEnum = typeof CheckoutStatusEnum[keyof typeof CheckoutStatusEnum];


/**
 * Check if a given object implements the Checkout interface.
 */
export function instanceOfCheckout(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "subtotalAmount" in value;
    isInstance = isInstance && "fee" in value;
    isInstance = isInstance && "feeRate" in value;
    isInstance = isInstance && "tax" in value;
    isInstance = isInstance && "taxRate" in value;
    isInstance = isInstance && "totalAmount" in value;
    isInstance = isInstance && "checkedOutAt" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function CheckoutFromJSON(json: any): Checkout {
    return CheckoutFromJSONTyped(json, false);
}

export function CheckoutFromJSONTyped(json: any, ignoreDiscriminator: boolean): Checkout {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'subtotalAmount': MoneyFromJSON(json['subtotal_amount']),
        'fee': MoneyFromJSON(json['fee']),
        'feeRate': json['fee_rate'],
        'tax': MoneyFromJSON(json['tax']),
        'taxRate': json['tax_rate'],
        'totalAmount': MoneyFromJSON(json['total_amount']),
        'checkedOutAt': (new Date(json['checked_out_at'])),
        'cancellableAt': !exists(json, 'cancellable_at') ? undefined : (new Date(json['cancellable_at'])),
        'status': json['status'],
    };
}

export function CheckoutToJSON(value?: Checkout | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'subtotal_amount': MoneyToJSON(value.subtotalAmount),
        'fee': MoneyToJSON(value.fee),
        'fee_rate': value.feeRate,
        'tax': MoneyToJSON(value.tax),
        'tax_rate': value.taxRate,
        'total_amount': MoneyToJSON(value.totalAmount),
        'checked_out_at': (value.checkedOutAt.toISOString()),
        'cancellable_at': value.cancellableAt === undefined ? undefined : (value.cancellableAt.toISOString()),
        'status': value.status,
    };
}

