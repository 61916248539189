/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Comment } from './Comment';
import {
    CommentFromJSON,
    CommentFromJSONTyped,
    CommentToJSON,
} from './Comment';
import type { Money } from './Money';
import {
    MoneyFromJSON,
    MoneyFromJSONTyped,
    MoneyToJSON,
} from './Money';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Support
 */
export interface Support {
    /**
     * 
     * @type {string}
     * @memberof Support
     */
    id: string;
    /**
     * 
     * @type {Money}
     * @memberof Support
     */
    amount: Money;
    /**
     * 
     * @type {Date}
     * @memberof Support
     */
    prioritizedStartAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Support
     */
    prioritizedEndAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Support
     */
    status: SupportStatusEnum;
    /**
     * 
     * @type {User}
     * @memberof Support
     */
    user: User;
    /**
     * 
     * @type {Comment}
     * @memberof Support
     */
    comment?: Comment;
}


/**
 * @export
 */
export const SupportStatusEnum = {
    Pending: 'pending',
    Paid: 'paid',
    Failed: 'failed'
} as const;
export type SupportStatusEnum = typeof SupportStatusEnum[keyof typeof SupportStatusEnum];


/**
 * Check if a given object implements the Support interface.
 */
export function instanceOfSupport(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "user" in value;

    return isInstance;
}

export function SupportFromJSON(json: any): Support {
    return SupportFromJSONTyped(json, false);
}

export function SupportFromJSONTyped(json: any, ignoreDiscriminator: boolean): Support {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'amount': MoneyFromJSON(json['amount']),
        'prioritizedStartAt': !exists(json, 'prioritized_start_at') ? undefined : (new Date(json['prioritized_start_at'])),
        'prioritizedEndAt': !exists(json, 'prioritized_end_at') ? undefined : (new Date(json['prioritized_end_at'])),
        'status': json['status'],
        'user': UserFromJSON(json['user']),
        'comment': !exists(json, 'comment') ? undefined : CommentFromJSON(json['comment']),
    };
}

export function SupportToJSON(value?: Support | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'amount': MoneyToJSON(value.amount),
        'prioritized_start_at': value.prioritizedStartAt === undefined ? undefined : (value.prioritizedStartAt.toISOString()),
        'prioritized_end_at': value.prioritizedEndAt === undefined ? undefined : (value.prioritizedEndAt.toISOString()),
        'status': value.status,
        'user': UserToJSON(value.user),
        'comment': CommentToJSON(value.comment),
    };
}

