/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Support } from './Support';
import {
    SupportFromJSON,
    SupportFromJSONTyped,
    SupportToJSON,
} from './Support';

/**
 * 
 * @export
 * @interface GetProposalSupports200ResponseAllOfData
 */
export interface GetProposalSupports200ResponseAllOfData {
    /**
     * 
     * @type {Array<Support>}
     * @memberof GetProposalSupports200ResponseAllOfData
     */
    supports: Array<Support>;
}

/**
 * Check if a given object implements the GetProposalSupports200ResponseAllOfData interface.
 */
export function instanceOfGetProposalSupports200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "supports" in value;

    return isInstance;
}

export function GetProposalSupports200ResponseAllOfDataFromJSON(json: any): GetProposalSupports200ResponseAllOfData {
    return GetProposalSupports200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetProposalSupports200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProposalSupports200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'supports': ((json['supports'] as Array<any>).map(SupportFromJSON)),
    };
}

export function GetProposalSupports200ResponseAllOfDataToJSON(value?: GetProposalSupports200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'supports': ((value.supports as Array<any>).map(SupportToJSON)),
    };
}

