/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UpdateUserPasswordRequestUser } from './UpdateUserPasswordRequestUser';
import {
    UpdateUserPasswordRequestUserFromJSON,
    UpdateUserPasswordRequestUserFromJSONTyped,
    UpdateUserPasswordRequestUserToJSON,
} from './UpdateUserPasswordRequestUser';

/**
 * 
 * @export
 * @interface UpdateUserPasswordRequest
 */
export interface UpdateUserPasswordRequest {
    /**
     * 
     * @type {UpdateUserPasswordRequestUser}
     * @memberof UpdateUserPasswordRequest
     */
    user: UpdateUserPasswordRequestUser;
}

/**
 * Check if a given object implements the UpdateUserPasswordRequest interface.
 */
export function instanceOfUpdateUserPasswordRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "user" in value;

    return isInstance;
}

export function UpdateUserPasswordRequestFromJSON(json: any): UpdateUserPasswordRequest {
    return UpdateUserPasswordRequestFromJSONTyped(json, false);
}

export function UpdateUserPasswordRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserPasswordRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': UpdateUserPasswordRequestUserFromJSON(json['user']),
    };
}

export function UpdateUserPasswordRequestToJSON(value?: UpdateUserPasswordRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UpdateUserPasswordRequestUserToJSON(value.user),
    };
}

