/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CalculateProposalSupportRequestSupport
 */
export interface CalculateProposalSupportRequestSupport {
    /**
     * 
     * @type {number}
     * @memberof CalculateProposalSupportRequestSupport
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof CalculateProposalSupportRequestSupport
     */
    currency?: CalculateProposalSupportRequestSupportCurrencyEnum;
}


/**
 * @export
 */
export const CalculateProposalSupportRequestSupportCurrencyEnum = {
    Jpy: 'jpy',
    Usd: 'usd'
} as const;
export type CalculateProposalSupportRequestSupportCurrencyEnum = typeof CalculateProposalSupportRequestSupportCurrencyEnum[keyof typeof CalculateProposalSupportRequestSupportCurrencyEnum];


/**
 * Check if a given object implements the CalculateProposalSupportRequestSupport interface.
 */
export function instanceOfCalculateProposalSupportRequestSupport(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amount" in value;

    return isInstance;
}

export function CalculateProposalSupportRequestSupportFromJSON(json: any): CalculateProposalSupportRequestSupport {
    return CalculateProposalSupportRequestSupportFromJSONTyped(json, false);
}

export function CalculateProposalSupportRequestSupportFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalculateProposalSupportRequestSupport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
    };
}

export function CalculateProposalSupportRequestSupportToJSON(value?: CalculateProposalSupportRequestSupport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'currency': value.currency,
    };
}

