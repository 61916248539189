/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetEditorLink200ResponseMetaImage } from './GetEditorLink200ResponseMetaImage';
import {
    GetEditorLink200ResponseMetaImageFromJSON,
    GetEditorLink200ResponseMetaImageFromJSONTyped,
    GetEditorLink200ResponseMetaImageToJSON,
} from './GetEditorLink200ResponseMetaImage';

/**
 * 
 * @export
 * @interface GetEditorLink200ResponseMeta
 */
export interface GetEditorLink200ResponseMeta {
    /**
     * 
     * @type {string}
     * @memberof GetEditorLink200ResponseMeta
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof GetEditorLink200ResponseMeta
     */
    description: string;
    /**
     * 
     * @type {GetEditorLink200ResponseMetaImage}
     * @memberof GetEditorLink200ResponseMeta
     */
    image?: GetEditorLink200ResponseMetaImage;
}

/**
 * Check if a given object implements the GetEditorLink200ResponseMeta interface.
 */
export function instanceOfGetEditorLink200ResponseMeta(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function GetEditorLink200ResponseMetaFromJSON(json: any): GetEditorLink200ResponseMeta {
    return GetEditorLink200ResponseMetaFromJSONTyped(json, false);
}

export function GetEditorLink200ResponseMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEditorLink200ResponseMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'description': json['description'],
        'image': !exists(json, 'image') ? undefined : GetEditorLink200ResponseMetaImageFromJSON(json['image']),
    };
}

export function GetEditorLink200ResponseMetaToJSON(value?: GetEditorLink200ResponseMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'description': value.description,
        'image': GetEditorLink200ResponseMetaImageToJSON(value.image),
    };
}

