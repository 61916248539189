/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostTopicRequestTopic
 */
export interface PostTopicRequestTopic {
    /**
     * 
     * @type {string}
     * @memberof PostTopicRequestTopic
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof PostTopicRequestTopic
     */
    body?: string;
    /**
     * 
     * @type {string}
     * @memberof PostTopicRequestTopic
     */
    bodyText?: string;
    /**
     * 
     * @type {string}
     * @memberof PostTopicRequestTopic
     */
    bodyType: PostTopicRequestTopicBodyTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PostTopicRequestTopic
     */
    rewardType?: PostTopicRequestTopicRewardTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof PostTopicRequestTopic
     */
    rewardAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof PostTopicRequestTopic
     */
    rewardCurrency?: PostTopicRequestTopicRewardCurrencyEnum;
    /**
     * 
     * @type {number}
     * @memberof PostTopicRequestTopic
     */
    rewardAnswersAmount?: number;
    /**
     * 
     * @type {Date}
     * @memberof PostTopicRequestTopic
     */
    expiresAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof PostTopicRequestTopic
     */
    coverImage?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PostTopicRequestTopic
     */
    asAnonymous?: boolean;
}


/**
 * @export
 */
export const PostTopicRequestTopicBodyTypeEnum = {
    Markdown: 'markdown',
    Editorjs: 'editorjs'
} as const;
export type PostTopicRequestTopicBodyTypeEnum = typeof PostTopicRequestTopicBodyTypeEnum[keyof typeof PostTopicRequestTopicBodyTypeEnum];

/**
 * @export
 */
export const PostTopicRequestTopicRewardTypeEnum = {
    None: 'none',
    AllForBest: 'all_for_best',
    DistributeByOwner: 'distribute_by_owner',
    DistributeByAuto: 'distribute_by_auto'
} as const;
export type PostTopicRequestTopicRewardTypeEnum = typeof PostTopicRequestTopicRewardTypeEnum[keyof typeof PostTopicRequestTopicRewardTypeEnum];

/**
 * @export
 */
export const PostTopicRequestTopicRewardCurrencyEnum = {
    Jpy: 'jpy',
    Usd: 'usd'
} as const;
export type PostTopicRequestTopicRewardCurrencyEnum = typeof PostTopicRequestTopicRewardCurrencyEnum[keyof typeof PostTopicRequestTopicRewardCurrencyEnum];


/**
 * Check if a given object implements the PostTopicRequestTopic interface.
 */
export function instanceOfPostTopicRequestTopic(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "bodyType" in value;

    return isInstance;
}

export function PostTopicRequestTopicFromJSON(json: any): PostTopicRequestTopic {
    return PostTopicRequestTopicFromJSONTyped(json, false);
}

export function PostTopicRequestTopicFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostTopicRequestTopic {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'body': !exists(json, 'body') ? undefined : json['body'],
        'bodyText': !exists(json, 'body_text') ? undefined : json['body_text'],
        'bodyType': json['body_type'],
        'rewardType': !exists(json, 'reward_type') ? undefined : json['reward_type'],
        'rewardAmount': !exists(json, 'reward_amount') ? undefined : json['reward_amount'],
        'rewardCurrency': !exists(json, 'reward_currency') ? undefined : json['reward_currency'],
        'rewardAnswersAmount': !exists(json, 'reward_answers_amount') ? undefined : json['reward_answers_amount'],
        'expiresAt': !exists(json, 'expires_at') ? undefined : (new Date(json['expires_at'])),
        'coverImage': !exists(json, 'cover_image') ? undefined : json['cover_image'],
        'asAnonymous': !exists(json, 'as_anonymous') ? undefined : json['as_anonymous'],
    };
}

export function PostTopicRequestTopicToJSON(value?: PostTopicRequestTopic | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'body': value.body,
        'body_text': value.bodyText,
        'body_type': value.bodyType,
        'reward_type': value.rewardType,
        'reward_amount': value.rewardAmount,
        'reward_currency': value.rewardCurrency,
        'reward_answers_amount': value.rewardAnswersAmount,
        'expires_at': value.expiresAt === undefined ? undefined : (value.expiresAt.toISOString()),
        'cover_image': value.coverImage,
        'as_anonymous': value.asAnonymous,
    };
}

