/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Comment } from './Comment';
import {
    CommentFromJSON,
    CommentFromJSONTyped,
    CommentToJSON,
} from './Comment';
import type { Proposal } from './Proposal';
import {
    ProposalFromJSON,
    ProposalFromJSONTyped,
    ProposalToJSON,
} from './Proposal';
import type { Support } from './Support';
import {
    SupportFromJSON,
    SupportFromJSONTyped,
    SupportToJSON,
} from './Support';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    kind: NotificationKindEnum;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    notifiableType?: NotificationNotifiableTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    notifiableFactor: NotificationNotifiableFactorEnum;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    url?: string;
    /**
     * 
     * @type {Array<User>}
     * @memberof Notification
     */
    users?: Array<User>;
    /**
     * 
     * @type {Proposal}
     * @memberof Notification
     */
    proposal?: Proposal;
    /**
     * 
     * @type {Comment}
     * @memberof Notification
     */
    comment?: Comment;
    /**
     * 
     * @type {Support}
     * @memberof Notification
     */
    support?: Support;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    usersCount: number;
    /**
     * 
     * @type {Date}
     * @memberof Notification
     */
    notifiedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Notification
     */
    readAt?: Date;
}


/**
 * @export
 */
export const NotificationKindEnum = {
    Like: 'like',
    Comment: 'comment',
    Proposal: 'proposal',
    Support: 'support',
    System: 'system'
} as const;
export type NotificationKindEnum = typeof NotificationKindEnum[keyof typeof NotificationKindEnum];

/**
 * @export
 */
export const NotificationNotifiableTypeEnum = {
    Proposal: 'Proposal',
    Comment: 'Comment',
    Support: 'Support'
} as const;
export type NotificationNotifiableTypeEnum = typeof NotificationNotifiableTypeEnum[keyof typeof NotificationNotifiableTypeEnum];

/**
 * @export
 */
export const NotificationNotifiableFactorEnum = {
    None: 'none',
    Owner: 'owner',
    Clipped: 'clipped'
} as const;
export type NotificationNotifiableFactorEnum = typeof NotificationNotifiableFactorEnum[keyof typeof NotificationNotifiableFactorEnum];


/**
 * Check if a given object implements the Notification interface.
 */
export function instanceOfNotification(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "kind" in value;
    isInstance = isInstance && "notifiableFactor" in value;
    isInstance = isInstance && "usersCount" in value;
    isInstance = isInstance && "notifiedAt" in value;

    return isInstance;
}

export function NotificationFromJSON(json: any): Notification {
    return NotificationFromJSONTyped(json, false);
}

export function NotificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Notification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'kind': json['kind'],
        'notifiableType': !exists(json, 'notifiable_type') ? undefined : json['notifiable_type'],
        'notifiableFactor': json['notifiable_factor'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'users': !exists(json, 'users') ? undefined : ((json['users'] as Array<any>).map(UserFromJSON)),
        'proposal': !exists(json, 'proposal') ? undefined : ProposalFromJSON(json['proposal']),
        'comment': !exists(json, 'comment') ? undefined : CommentFromJSON(json['comment']),
        'support': !exists(json, 'support') ? undefined : SupportFromJSON(json['support']),
        'usersCount': json['users_count'],
        'notifiedAt': (new Date(json['notified_at'])),
        'readAt': !exists(json, 'read_at') ? undefined : (new Date(json['read_at'])),
    };
}

export function NotificationToJSON(value?: Notification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'kind': value.kind,
        'notifiable_type': value.notifiableType,
        'notifiable_factor': value.notifiableFactor,
        'message': value.message,
        'url': value.url,
        'users': value.users === undefined ? undefined : ((value.users as Array<any>).map(UserToJSON)),
        'proposal': ProposalToJSON(value.proposal),
        'comment': CommentToJSON(value.comment),
        'support': SupportToJSON(value.support),
        'users_count': value.usersCount,
        'notified_at': (value.notifiedAt.toISOString()),
        'read_at': value.readAt === undefined ? undefined : (value.readAt.toISOString()),
    };
}

