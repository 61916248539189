/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Token } from './Token';
import {
    TokenFromJSON,
    TokenFromJSONTyped,
    TokenToJSON,
} from './Token';
import type { UserMe } from './UserMe';
import {
    UserMeFromJSON,
    UserMeFromJSONTyped,
    UserMeToJSON,
} from './UserMe';

/**
 * 
 * @export
 * @interface ConnectUserProvider200ResponseAllOfData
 */
export interface ConnectUserProvider200ResponseAllOfData {
    /**
     * 
     * @type {UserMe}
     * @memberof ConnectUserProvider200ResponseAllOfData
     */
    user: UserMe;
    /**
     * 
     * @type {Token}
     * @memberof ConnectUserProvider200ResponseAllOfData
     */
    token: Token;
}

/**
 * Check if a given object implements the ConnectUserProvider200ResponseAllOfData interface.
 */
export function instanceOfConnectUserProvider200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "token" in value;

    return isInstance;
}

export function ConnectUserProvider200ResponseAllOfDataFromJSON(json: any): ConnectUserProvider200ResponseAllOfData {
    return ConnectUserProvider200ResponseAllOfDataFromJSONTyped(json, false);
}

export function ConnectUserProvider200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectUserProvider200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': UserMeFromJSON(json['user']),
        'token': TokenFromJSON(json['token']),
    };
}

export function ConnectUserProvider200ResponseAllOfDataToJSON(value?: ConnectUserProvider200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UserMeToJSON(value.user),
        'token': TokenToJSON(value.token),
    };
}

