/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetTopicDraftProposals200ResponseAllOfData } from './GetTopicDraftProposals200ResponseAllOfData';
import {
    GetTopicDraftProposals200ResponseAllOfDataFromJSON,
    GetTopicDraftProposals200ResponseAllOfDataFromJSONTyped,
    GetTopicDraftProposals200ResponseAllOfDataToJSON,
} from './GetTopicDraftProposals200ResponseAllOfData';

/**
 * 
 * @export
 * @interface GetTopicDraftProposals200Response
 */
export interface GetTopicDraftProposals200Response {
    /**
     * 
     * @type {GetTopicDraftProposals200ResponseAllOfData}
     * @memberof GetTopicDraftProposals200Response
     */
    data: GetTopicDraftProposals200ResponseAllOfData;
    /**
     * 
     * @type {object}
     * @memberof GetTopicDraftProposals200Response
     */
    meta: object;
}

/**
 * Check if a given object implements the GetTopicDraftProposals200Response interface.
 */
export function instanceOfGetTopicDraftProposals200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function GetTopicDraftProposals200ResponseFromJSON(json: any): GetTopicDraftProposals200Response {
    return GetTopicDraftProposals200ResponseFromJSONTyped(json, false);
}

export function GetTopicDraftProposals200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTopicDraftProposals200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': GetTopicDraftProposals200ResponseAllOfDataFromJSON(json['data']),
        'meta': json['meta'],
    };
}

export function GetTopicDraftProposals200ResponseToJSON(value?: GetTopicDraftProposals200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': GetTopicDraftProposals200ResponseAllOfDataToJSON(value.data),
        'meta': value.meta,
    };
}

