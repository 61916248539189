/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Proposal } from './Proposal';
import {
    ProposalFromJSON,
    ProposalFromJSONTyped,
    ProposalToJSON,
} from './Proposal';

/**
 * 
 * @export
 * @interface SearchProposals200ResponseAllOfData
 */
export interface SearchProposals200ResponseAllOfData {
    /**
     * 
     * @type {Array<Proposal>}
     * @memberof SearchProposals200ResponseAllOfData
     */
    proposals: Array<Proposal>;
}

/**
 * Check if a given object implements the SearchProposals200ResponseAllOfData interface.
 */
export function instanceOfSearchProposals200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "proposals" in value;

    return isInstance;
}

export function SearchProposals200ResponseAllOfDataFromJSON(json: any): SearchProposals200ResponseAllOfData {
    return SearchProposals200ResponseAllOfDataFromJSONTyped(json, false);
}

export function SearchProposals200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchProposals200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'proposals': ((json['proposals'] as Array<any>).map(ProposalFromJSON)),
    };
}

export function SearchProposals200ResponseAllOfDataToJSON(value?: SearchProposals200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'proposals': ((value.proposals as Array<any>).map(ProposalToJSON)),
    };
}

