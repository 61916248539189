/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUserPaymentMethodRequest
 */
export interface UpdateUserPaymentMethodRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserPaymentMethodRequest
     */
    isDefault?: boolean;
}

/**
 * Check if a given object implements the UpdateUserPaymentMethodRequest interface.
 */
export function instanceOfUpdateUserPaymentMethodRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateUserPaymentMethodRequestFromJSON(json: any): UpdateUserPaymentMethodRequest {
    return UpdateUserPaymentMethodRequestFromJSONTyped(json, false);
}

export function UpdateUserPaymentMethodRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserPaymentMethodRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isDefault': !exists(json, 'is_default') ? undefined : json['is_default'],
    };
}

export function UpdateUserPaymentMethodRequestToJSON(value?: UpdateUserPaymentMethodRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_default': value.isDefault,
    };
}

