/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Notification } from './Notification';
import {
    NotificationFromJSON,
    NotificationFromJSONTyped,
    NotificationToJSON,
} from './Notification';

/**
 * 
 * @export
 * @interface GetNotifications200ResponseAllOfData
 */
export interface GetNotifications200ResponseAllOfData {
    /**
     * 
     * @type {Array<Notification>}
     * @memberof GetNotifications200ResponseAllOfData
     */
    notifications: Array<Notification>;
}

/**
 * Check if a given object implements the GetNotifications200ResponseAllOfData interface.
 */
export function instanceOfGetNotifications200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "notifications" in value;

    return isInstance;
}

export function GetNotifications200ResponseAllOfDataFromJSON(json: any): GetNotifications200ResponseAllOfData {
    return GetNotifications200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetNotifications200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetNotifications200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'notifications': ((json['notifications'] as Array<any>).map(NotificationFromJSON)),
    };
}

export function GetNotifications200ResponseAllOfDataToJSON(value?: GetNotifications200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'notifications': ((value.notifications as Array<any>).map(NotificationToJSON)),
    };
}

