import classNames from "classnames";
import { forwardRef, ReactNode, Ref } from "react";
import { useTranslation } from "react-i18next";

import { CheckboxInput } from "../CheckboxInput";
import { InputLabel } from "../InputLabel";

export type CheckboxInputGroupProps = {
  label?: ReactNode;
  renderItem?: (
    props: React.InputHTMLAttributes<HTMLInputElement>
  ) => ReactNode;
  error?: string;
  invalid?: boolean;
  inputLabel?: ReactNode;
  inputClassName?: string;
  labelContainerClassName?: string;
  className?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const CheckboxInputGroup = forwardRef(function CheckboxInputGroup({
  label,
  renderItem,
  error,
  invalid,
  required,
  inputLabel,
  inputClassName,
  labelContainerClassName,
  className,
  ...props
}: CheckboxInputGroupProps, ref: Ref<HTMLDivElement>) {
  const { t } = useTranslation();

  return (
    <div ref={ref} className={className}>
      {(label || required) && (
        <InputLabel label={label} required={required} className={classNames("mb-2", labelContainerClassName)} />
      )}
      <div className="flex flex-wrap gap-x-4 gap-y-3">
        {renderItem ? renderItem(props) : (
          <CheckboxInput
            {...props}
            label={inputLabel}
            className={inputClassName}
          />
        )}
      </div>
      {error && (
        <div className="mt-2 text-sm text-red-600">
          {t(error, { ns: "validation" })}
        </div>
      )}
    </div>
  );
});
