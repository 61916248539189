/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Position } from './Position';
import {
    PositionFromJSON,
    PositionFromJSONTyped,
    PositionToJSON,
} from './Position';
import type { Support } from './Support';
import {
    SupportFromJSON,
    SupportFromJSONTyped,
    SupportToJSON,
} from './Support';
import type { Topic } from './Topic';
import {
    TopicFromJSON,
    TopicFromJSONTyped,
    TopicToJSON,
} from './Topic';
import type { TopicBestProposal } from './TopicBestProposal';
import {
    TopicBestProposalFromJSON,
    TopicBestProposalFromJSONTyped,
    TopicBestProposalToJSON,
} from './TopicBestProposal';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Proposal
 */
export interface Proposal {
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    topicId: string;
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    threadId?: string;
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    body: string;
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    bodyText: string;
    /**
     * 
     * @type {object}
     * @memberof Proposal
     */
    bodyData: object;
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    bodyType: ProposalBodyTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Proposal
     */
    liked: boolean;
    /**
     * 
     * @type {number}
     * @memberof Proposal
     */
    likesCount: number;
    /**
     * 
     * @type {number}
     * @memberof Proposal
     */
    commentsCount: number;
    /**
     * 
     * @type {boolean}
     * @memberof Proposal
     */
    supported: boolean;
    /**
     * 
     * @type {number}
     * @memberof Proposal
     */
    supportsCount: number;
    /**
     * 
     * @type {boolean}
     * @memberof Proposal
     */
    clipped: boolean;
    /**
     * 
     * @type {number}
     * @memberof Proposal
     */
    clipsCount: number;
    /**
     * 
     * @type {Date}
     * @memberof Proposal
     */
    prioritizedStartAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Proposal
     */
    prioritizedEndAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Proposal
     */
    asAnonymous: boolean;
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    best: ProposalBestEnum;
    /**
     * 
     * @type {TopicBestProposal}
     * @memberof Proposal
     */
    topicBestProposal?: TopicBestProposal;
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    thanksComment?: string;
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    status: ProposalStatusEnum;
    /**
     * 
     * @type {Topic}
     * @memberof Proposal
     */
    topic?: Topic;
    /**
     * 
     * @type {Array<Support>}
     * @memberof Proposal
     */
    supports?: Array<Support>;
    /**
     * 
     * @type {User}
     * @memberof Proposal
     */
    user: User;
    /**
     * 
     * @type {Position}
     * @memberof Proposal
     */
    position?: Position;
    /**
     * 
     * @type {Array<Proposal>}
     * @memberof Proposal
     */
    threadProposals?: Array<Proposal>;
}


/**
 * @export
 */
export const ProposalBodyTypeEnum = {
    Markdown: 'markdown',
    Editorjs: 'editorjs'
} as const;
export type ProposalBodyTypeEnum = typeof ProposalBodyTypeEnum[keyof typeof ProposalBodyTypeEnum];

/**
 * @export
 */
export const ProposalBestEnum = {
    Normal: 'normal',
    Better: 'better',
    Best: 'best'
} as const;
export type ProposalBestEnum = typeof ProposalBestEnum[keyof typeof ProposalBestEnum];

/**
 * @export
 */
export const ProposalStatusEnum = {
    Draft: 'draft',
    Published: 'published',
    Rejected: 'rejected'
} as const;
export type ProposalStatusEnum = typeof ProposalStatusEnum[keyof typeof ProposalStatusEnum];


/**
 * Check if a given object implements the Proposal interface.
 */
export function instanceOfProposal(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "topicId" in value;
    isInstance = isInstance && "body" in value;
    isInstance = isInstance && "bodyText" in value;
    isInstance = isInstance && "bodyData" in value;
    isInstance = isInstance && "bodyType" in value;
    isInstance = isInstance && "liked" in value;
    isInstance = isInstance && "likesCount" in value;
    isInstance = isInstance && "commentsCount" in value;
    isInstance = isInstance && "supported" in value;
    isInstance = isInstance && "supportsCount" in value;
    isInstance = isInstance && "clipped" in value;
    isInstance = isInstance && "clipsCount" in value;
    isInstance = isInstance && "asAnonymous" in value;
    isInstance = isInstance && "best" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "user" in value;

    return isInstance;
}

export function ProposalFromJSON(json: any): Proposal {
    return ProposalFromJSONTyped(json, false);
}

export function ProposalFromJSONTyped(json: any, ignoreDiscriminator: boolean): Proposal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'topicId': json['topic_id'],
        'threadId': !exists(json, 'thread_id') ? undefined : json['thread_id'],
        'body': json['body'],
        'bodyText': json['body_text'],
        'bodyData': json['body_data'],
        'bodyType': json['body_type'],
        'liked': json['liked'],
        'likesCount': json['likes_count'],
        'commentsCount': json['comments_count'],
        'supported': json['supported'],
        'supportsCount': json['supports_count'],
        'clipped': json['clipped'],
        'clipsCount': json['clips_count'],
        'prioritizedStartAt': !exists(json, 'prioritized_start_at') ? undefined : (new Date(json['prioritized_start_at'])),
        'prioritizedEndAt': !exists(json, 'prioritized_end_at') ? undefined : (new Date(json['prioritized_end_at'])),
        'asAnonymous': json['as_anonymous'],
        'best': json['best'],
        'topicBestProposal': !exists(json, 'topic_best_proposal') ? undefined : TopicBestProposalFromJSON(json['topic_best_proposal']),
        'thanksComment': !exists(json, 'thanks_comment') ? undefined : json['thanks_comment'],
        'status': json['status'],
        'topic': !exists(json, 'topic') ? undefined : TopicFromJSON(json['topic']),
        'supports': !exists(json, 'supports') ? undefined : ((json['supports'] as Array<any>).map(SupportFromJSON)),
        'user': UserFromJSON(json['user']),
        'position': !exists(json, 'position') ? undefined : PositionFromJSON(json['position']),
        'threadProposals': !exists(json, 'thread_proposals') ? undefined : ((json['thread_proposals'] as Array<any>).map(ProposalFromJSON)),
    };
}

export function ProposalToJSON(value?: Proposal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'topic_id': value.topicId,
        'thread_id': value.threadId,
        'body': value.body,
        'body_text': value.bodyText,
        'body_data': value.bodyData,
        'body_type': value.bodyType,
        'liked': value.liked,
        'likes_count': value.likesCount,
        'comments_count': value.commentsCount,
        'supported': value.supported,
        'supports_count': value.supportsCount,
        'clipped': value.clipped,
        'clips_count': value.clipsCount,
        'prioritized_start_at': value.prioritizedStartAt === undefined ? undefined : (value.prioritizedStartAt.toISOString()),
        'prioritized_end_at': value.prioritizedEndAt === undefined ? undefined : (value.prioritizedEndAt.toISOString()),
        'as_anonymous': value.asAnonymous,
        'best': value.best,
        'topic_best_proposal': TopicBestProposalToJSON(value.topicBestProposal),
        'thanks_comment': value.thanksComment,
        'status': value.status,
        'topic': TopicToJSON(value.topic),
        'supports': value.supports === undefined ? undefined : ((value.supports as Array<any>).map(SupportToJSON)),
        'user': UserToJSON(value.user),
        'position': PositionToJSON(value.position),
        'thread_proposals': value.threadProposals === undefined ? undefined : ((value.threadProposals as Array<any>).map(ProposalToJSON)),
    };
}

