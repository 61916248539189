import { differenceInMinutes, isBefore } from "date-fns";
import { immerable } from "immer";

import * as api from "shared/services/api/web/models";

import { Comment, CommentType } from "./comment";
import { Money, MoneyType } from "./money";
import { User, UserType } from "./user";

export type SupportType = api.Support;

export const SupportStatus = api.SupportStatusEnum;
export type SupportStatus = api.SupportStatusEnum;

export class Support implements SupportType {
  [immerable] = true;

  id = "";
  amount: MoneyType = new Money();
  user: UserType = new User();
  prioritizedStartAt?: Date;
  prioritizedEndAt?: Date;
  comment?: CommentType;
  status: SupportStatus = SupportStatus.Pending;

  constructor(data: Partial<SupportType> = {}) {
    Object.assign(this, data);
  }

  getUser() {
    return new User(this.user);
  }

  getComment() {
    return new Comment(this.comment);
  }

  getPrioritizedStartAt() {
    return this.prioritizedStartAt || new Date();
  }

  getPrioritizedEndAt() {
    return this.prioritizedEndAt || new Date();
  }

  getPrioritizedMinutes() {
    return differenceInMinutes(this.getPrioritizedEndAt(), this.getPrioritizedStartAt());
  }

  isPrioritized() {
    return this.prioritizedEndAt && isBefore(new Date(), this.prioritizedEndAt);
  }
}
