import { immerable } from "immer";

import * as api from "shared/services/api/web/models";

import { Money, MoneyType } from "./money";

export type CheckoutType = api.Checkout;

export const CheckoutStatus = api.CheckoutStatusEnum;
export type CheckoutStatus = api.CheckoutStatusEnum;

export class Checkout implements CheckoutType {
  [immerable] = true;

  id = "";
  subtotalAmount: MoneyType = new Money();
  fee: MoneyType = new Money();
  feeRate = 0;
  tax: MoneyType = new Money();
  taxRate = 0;
  totalAmount: MoneyType = new Money();
  checkedOutAt: Date = new Date();
  cancellableAt?: Date;
  status: CheckoutStatus = CheckoutStatus.Waiting;

  constructor(data: Partial<CheckoutType> = {}) {
    Object.assign(this, data);
  }

  isCancellable(): this is Checkout & { cancellableAt: Date } {
    return !!this.cancellableAt && this.cancellableAt > new Date() && this.status === CheckoutStatus.Paid;
  }

  isCanceled() {
    return this.status === CheckoutStatus.Canceled;
  }

  isCompleted() {
    return this.status === CheckoutStatus.Completed;
  }
}
