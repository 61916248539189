/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Proposal } from './Proposal';
import {
    ProposalFromJSON,
    ProposalFromJSONTyped,
    ProposalToJSON,
} from './Proposal';

/**
 * 
 * @export
 * @interface GetProposal200ResponseAllOfData
 */
export interface GetProposal200ResponseAllOfData {
    /**
     * 
     * @type {Proposal}
     * @memberof GetProposal200ResponseAllOfData
     */
    proposal: Proposal;
    /**
     * 
     * @type {boolean}
     * @memberof GetProposal200ResponseAllOfData
     */
    isOwner: boolean;
}

/**
 * Check if a given object implements the GetProposal200ResponseAllOfData interface.
 */
export function instanceOfGetProposal200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "proposal" in value;
    isInstance = isInstance && "isOwner" in value;

    return isInstance;
}

export function GetProposal200ResponseAllOfDataFromJSON(json: any): GetProposal200ResponseAllOfData {
    return GetProposal200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetProposal200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProposal200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'proposal': ProposalFromJSON(json['proposal']),
        'isOwner': json['is_owner'],
    };
}

export function GetProposal200ResponseAllOfDataToJSON(value?: GetProposal200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'proposal': ProposalToJSON(value.proposal),
        'is_owner': value.isOwner,
    };
}

