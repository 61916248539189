import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { forwardRef, ReactNode, Ref } from "react";

import { twClassNames } from "shared/lib/tailwind";

export type TextInputProps = {
  unit?: ReactNode;
  invalid?: boolean;
  containerClassName?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const TextInput = forwardRef(function TextInput({
  type = "text",
  unit,
  invalid,
  className,
  containerClassName,
  ...props
}: TextInputProps, ref: Ref<HTMLInputElement>) {
  const classes = twClassNames(
    "rounded border-gray-300 shadow-sm w-full form-input",
    "placeholder-gray-300",
    className,
    {
      "rounded-r rounded-l-none": unit,
      "border-red-300 text-red-900 pr-10": invalid,
    },
  );

  return (
    <div className={twClassNames("relative", containerClassName)}>
      <div className="flex justify-end">
        {unit && (
          <div className="text-black-400 flex items-center justify-center rounded-l border-y border-l bg-gray-100 px-4 py-2">{unit}</div>
        )}
        <input
          {...props}
          ref={ref}
          type={type}
          className={classes}
        />
        {invalid && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
          </div>
        )}
      </div>
    </div>
  );
});
