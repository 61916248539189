/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Money } from './Money';
import {
    MoneyFromJSON,
    MoneyFromJSONTyped,
    MoneyToJSON,
} from './Money';

/**
 * 
 * @export
 * @interface Payout
 */
export interface Payout {
    /**
     * 
     * @type {string}
     * @memberof Payout
     */
    id: string;
    /**
     * 
     * @type {Money}
     * @memberof Payout
     */
    amount: Money;
    /**
     * 
     * @type {string}
     * @memberof Payout
     */
    status: PayoutStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof Payout
     */
    requestedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Payout
     */
    completedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Payout
     */
    failedMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof Payout
     */
    rejectedMessage?: string;
}


/**
 * @export
 */
export const PayoutStatusEnum = {
    Requested: 'requested',
    InProgress: 'in_progress',
    Completed: 'completed',
    Rejected: 'rejected',
    Canceled: 'canceled',
    Failed: 'failed'
} as const;
export type PayoutStatusEnum = typeof PayoutStatusEnum[keyof typeof PayoutStatusEnum];


/**
 * Check if a given object implements the Payout interface.
 */
export function instanceOfPayout(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "requestedAt" in value;

    return isInstance;
}

export function PayoutFromJSON(json: any): Payout {
    return PayoutFromJSONTyped(json, false);
}

export function PayoutFromJSONTyped(json: any, ignoreDiscriminator: boolean): Payout {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'amount': MoneyFromJSON(json['amount']),
        'status': json['status'],
        'requestedAt': (new Date(json['requested_at'])),
        'completedAt': !exists(json, 'completed_at') ? undefined : (new Date(json['completed_at'])),
        'failedMessage': !exists(json, 'failed_message') ? undefined : json['failed_message'],
        'rejectedMessage': !exists(json, 'rejected_message') ? undefined : json['rejected_message'],
    };
}

export function PayoutToJSON(value?: Payout | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'amount': MoneyToJSON(value.amount),
        'status': value.status,
        'requested_at': (value.requestedAt.toISOString()),
        'completed_at': value.completedAt === undefined ? undefined : (value.completedAt.toISOString()),
        'failed_message': value.failedMessage,
        'rejected_message': value.rejectedMessage,
    };
}

