import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { forwardRef, Ref } from "react";

export type TextAreaInputProps = {
  rows?: number;
  invalid?: boolean;
  containerClassName?: string;
} & React.InputHTMLAttributes<HTMLTextAreaElement>;

export const TextAreaInput = forwardRef(function TextInput({
  invalid,
  className,
  containerClassName,
  ...props
}: TextAreaInputProps, ref: Ref<HTMLTextAreaElement>) {
  const classes = classNames(
    "rounded border-gray-300 shadow-sm w-full form-input",
    "placeholder-gray-300",
    className,
    {
      "border-red-300 text-red-900 pr-10": invalid,
    },
  );

  return (
    <div className={classNames("relative", containerClassName)}>
      <textarea
        {...props}
        ref={ref}
        className={classes}
      />
      {invalid && (
        <div className="pointer-events-none absolute right-3 top-3 flex">
          <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
        </div>
      )}
    </div>
  );
});
