/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostInquiryRequestInquiry
 */
export interface PostInquiryRequestInquiry {
    /**
     * 
     * @type {string}
     * @memberof PostInquiryRequestInquiry
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PostInquiryRequestInquiry
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof PostInquiryRequestInquiry
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof PostInquiryRequestInquiry
     */
    kind: PostInquiryRequestInquiryKindEnum;
}


/**
 * @export
 */
export const PostInquiryRequestInquiryKindEnum = {
    Inquiry: 'inquiry',
    Contact: 'contact',
    BugReport: 'bug_report',
    FeatureRequest: 'feature_request',
    Other: 'other'
} as const;
export type PostInquiryRequestInquiryKindEnum = typeof PostInquiryRequestInquiryKindEnum[keyof typeof PostInquiryRequestInquiryKindEnum];


/**
 * Check if a given object implements the PostInquiryRequestInquiry interface.
 */
export function instanceOfPostInquiryRequestInquiry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "message" in value;
    isInstance = isInstance && "kind" in value;

    return isInstance;
}

export function PostInquiryRequestInquiryFromJSON(json: any): PostInquiryRequestInquiry {
    return PostInquiryRequestInquiryFromJSONTyped(json, false);
}

export function PostInquiryRequestInquiryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostInquiryRequestInquiry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'email': json['email'],
        'message': json['message'],
        'kind': json['kind'],
    };
}

export function PostInquiryRequestInquiryToJSON(value?: PostInquiryRequestInquiry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'email': value.email,
        'message': value.message,
        'kind': value.kind,
    };
}

