import classNames from "classnames";
import { forwardRef, ReactNode, Ref } from "react";
import { useTranslation } from "react-i18next";

import { InputLabel } from "../InputLabel";
import { RadioInput } from "../RadioInput";

export type RadioInputsGroupItem = {
  value: string;
  label: string;
};

export type RadioInputsGroupProps = {
  label?: ReactNode;
  items: RadioInputsGroupItem[];
  renderItem?: (
    item: RadioInputsGroupItem,
    index: number,
    props?: React.InputHTMLAttributes<HTMLInputElement>
  ) => ReactNode;
  error?: string;
  invalid?: boolean;
  inputClassName?: string;
  labelContainerClassName?: string;
  className?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const RadioInputsGroup = forwardRef(function RadioInputGroup({
  label,
  items,
  renderItem,
  error,
  invalid,
  required,
  inputClassName,
  labelContainerClassName,
  className,
  ...props
}: RadioInputsGroupProps, ref: Ref<HTMLDivElement>) {
  const { t } = useTranslation();

  return (
    <div ref={ref} className={className}>
      {(label || required) && (
        <InputLabel label={label} required={required} className={classNames("mb-2", labelContainerClassName)} />
      )}
      <div className="mt-1 flex flex-wrap gap-x-4 gap-y-3">
        {items.map((item, index) => renderItem ? renderItem(item, index, props) : (
          <RadioInput {...props} key={item.value} label={item.label} value={item.value} className={inputClassName} />
        ))}
      </div>
      {error && (
        <div className="mt-2 text-sm text-red-600">
          {t(error, { ns: "validation" })}
        </div>
      )}
    </div>
  );
});
