import { immerable } from "immer";

import * as api from "shared/services/api/web/models";

import { Proposal, ProposalType } from "./proposal";
import { Support, SupportType } from "./support";
import { User, UserType } from "./user";

export type CommentType = api.Comment;

export class Comment implements CommentType {
  [immerable] = true;

  id = "";
  commentableType = "Proposal";
  body = "";
  liked = false;
  likesCount = 0;
  commentsCount = 0;
  clipped = false;
  clipsCount = 0;
  user: UserType = new User();
  prioritizedStartAt?: Date;
  prioritizedEndAt?: Date;
  support?: SupportType;
  proposal?: ProposalType;

  constructor(data: Partial<CommentType> = {}) {
    Object.assign(this, data);
  }

  getUser() {
    return new User(this.user);
  }

  getSupport() {
    if (!this.support) return null;

    return new Support(this.support);
  }

  getProposal() {
    if (!this.proposal) return null;

    return new Proposal(this.proposal);
  }

  getForceProposal() {
    return new Proposal(this.proposal);
  }

  isProposal(): this is Comment & { proposal: ProposalType } {
    return this.commentableType === "Proposal";
  }
}
