/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RequestPayout201ResponseAllOfData } from './RequestPayout201ResponseAllOfData';
import {
    RequestPayout201ResponseAllOfDataFromJSON,
    RequestPayout201ResponseAllOfDataFromJSONTyped,
    RequestPayout201ResponseAllOfDataToJSON,
} from './RequestPayout201ResponseAllOfData';

/**
 * 
 * @export
 * @interface RequestPayout201Response
 */
export interface RequestPayout201Response {
    /**
     * 
     * @type {RequestPayout201ResponseAllOfData}
     * @memberof RequestPayout201Response
     */
    data: RequestPayout201ResponseAllOfData;
    /**
     * 
     * @type {object}
     * @memberof RequestPayout201Response
     */
    meta: object;
}

/**
 * Check if a given object implements the RequestPayout201Response interface.
 */
export function instanceOfRequestPayout201Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function RequestPayout201ResponseFromJSON(json: any): RequestPayout201Response {
    return RequestPayout201ResponseFromJSONTyped(json, false);
}

export function RequestPayout201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestPayout201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': RequestPayout201ResponseAllOfDataFromJSON(json['data']),
        'meta': json['meta'],
    };
}

export function RequestPayout201ResponseToJSON(value?: RequestPayout201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': RequestPayout201ResponseAllOfDataToJSON(value.data),
        'meta': value.meta,
    };
}

