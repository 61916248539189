/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateDirectUpload200ResponseDirectUpload } from './CreateDirectUpload200ResponseDirectUpload';
import {
    CreateDirectUpload200ResponseDirectUploadFromJSON,
    CreateDirectUpload200ResponseDirectUploadFromJSONTyped,
    CreateDirectUpload200ResponseDirectUploadToJSON,
} from './CreateDirectUpload200ResponseDirectUpload';

/**
 * 
 * @export
 * @interface CreateDirectUpload200Response
 */
export interface CreateDirectUpload200Response {
    /**
     * 
     * @type {string}
     * @memberof CreateDirectUpload200Response
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectUpload200Response
     */
    filename?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectUpload200Response
     */
    contentType?: string;
    /**
     * 
     * @type {object}
     * @memberof CreateDirectUpload200Response
     */
    metadata?: object;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectUpload200Response
     */
    serviceName?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateDirectUpload200Response
     */
    byteSize?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectUpload200Response
     */
    checksum?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectUpload200Response
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectUpload200Response
     */
    signedId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectUpload200Response
     */
    url?: string;
    /**
     * 
     * @type {CreateDirectUpload200ResponseDirectUpload}
     * @memberof CreateDirectUpload200Response
     */
    directUpload?: CreateDirectUpload200ResponseDirectUpload;
}

/**
 * Check if a given object implements the CreateDirectUpload200Response interface.
 */
export function instanceOfCreateDirectUpload200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateDirectUpload200ResponseFromJSON(json: any): CreateDirectUpload200Response {
    return CreateDirectUpload200ResponseFromJSONTyped(json, false);
}

export function CreateDirectUpload200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDirectUpload200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'filename': !exists(json, 'filename') ? undefined : json['filename'],
        'contentType': !exists(json, 'content_type') ? undefined : json['content_type'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'serviceName': !exists(json, 'service_name') ? undefined : json['service_name'],
        'byteSize': !exists(json, 'byte_size') ? undefined : json['byte_size'],
        'checksum': !exists(json, 'checksum') ? undefined : json['checksum'],
        'createdAt': !exists(json, 'created_at') ? undefined : json['created_at'],
        'signedId': !exists(json, 'signed_id') ? undefined : json['signed_id'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'directUpload': !exists(json, 'direct_upload') ? undefined : CreateDirectUpload200ResponseDirectUploadFromJSON(json['direct_upload']),
    };
}

export function CreateDirectUpload200ResponseToJSON(value?: CreateDirectUpload200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'filename': value.filename,
        'content_type': value.contentType,
        'metadata': value.metadata,
        'service_name': value.serviceName,
        'byte_size': value.byteSize,
        'checksum': value.checksum,
        'created_at': value.createdAt,
        'signed_id': value.signedId,
        'url': value.url,
        'direct_upload': CreateDirectUpload200ResponseDirectUploadToJSON(value.directUpload),
    };
}

