import { immerable } from "immer";

import { ImageType } from "./image";

export type MagazineEditorType = {
  id: number;
  name: string;
  introduction: string;
  thumbnail?: ImageType;
};

export class MagazineEditor implements MagazineEditorType {
  [immerable] = true;

  id = 0;
  name = "";
  introduction = "";
  thumbnail?: ImageType;

  constructor(data: Partial<MagazineEditorType> = {}) {
    Object.assign(this, data);
  }
}
