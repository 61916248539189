/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InquiryReply
 */
export interface InquiryReply {
    /**
     * 
     * @type {string}
     * @memberof InquiryReply
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof InquiryReply
     */
    role: InquiryReplyRoleEnum;
    /**
     * 
     * @type {Date}
     * @memberof InquiryReply
     */
    repliedAt: Date;
}


/**
 * @export
 */
export const InquiryReplyRoleEnum = {
    User: 'user',
    Support: 'support'
} as const;
export type InquiryReplyRoleEnum = typeof InquiryReplyRoleEnum[keyof typeof InquiryReplyRoleEnum];


/**
 * Check if a given object implements the InquiryReply interface.
 */
export function instanceOfInquiryReply(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "message" in value;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "repliedAt" in value;

    return isInstance;
}

export function InquiryReplyFromJSON(json: any): InquiryReply {
    return InquiryReplyFromJSONTyped(json, false);
}

export function InquiryReplyFromJSONTyped(json: any, ignoreDiscriminator: boolean): InquiryReply {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': json['message'],
        'role': json['role'],
        'repliedAt': (new Date(json['replied_at'])),
    };
}

export function InquiryReplyToJSON(value?: InquiryReply | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
        'role': value.role,
        'replied_at': (value.repliedAt.toISOString()),
    };
}

