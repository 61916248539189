/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RequestPayoutRequestPayout } from './RequestPayoutRequestPayout';
import {
    RequestPayoutRequestPayoutFromJSON,
    RequestPayoutRequestPayoutFromJSONTyped,
    RequestPayoutRequestPayoutToJSON,
} from './RequestPayoutRequestPayout';

/**
 * 
 * @export
 * @interface RequestPayoutRequest
 */
export interface RequestPayoutRequest {
    /**
     * 
     * @type {RequestPayoutRequestPayout}
     * @memberof RequestPayoutRequest
     */
    payout: RequestPayoutRequestPayout;
}

/**
 * Check if a given object implements the RequestPayoutRequest interface.
 */
export function instanceOfRequestPayoutRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "payout" in value;

    return isInstance;
}

export function RequestPayoutRequestFromJSON(json: any): RequestPayoutRequest {
    return RequestPayoutRequestFromJSONTyped(json, false);
}

export function RequestPayoutRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestPayoutRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'payout': RequestPayoutRequestPayoutFromJSON(json['payout']),
    };
}

export function RequestPayoutRequestToJSON(value?: RequestPayoutRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'payout': RequestPayoutRequestPayoutToJSON(value.payout),
    };
}

