/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateBestProposalsRequestBestProposalsInner
 */
export interface CreateBestProposalsRequestBestProposalsInner {
    /**
     * 
     * @type {string}
     * @memberof CreateBestProposalsRequestBestProposalsInner
     */
    proposalId: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateBestProposalsRequestBestProposalsInner
     */
    best: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateBestProposalsRequestBestProposalsInner
     */
    rewardAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateBestProposalsRequestBestProposalsInner
     */
    thanksComment?: string;
}

/**
 * Check if a given object implements the CreateBestProposalsRequestBestProposalsInner interface.
 */
export function instanceOfCreateBestProposalsRequestBestProposalsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "proposalId" in value;
    isInstance = isInstance && "best" in value;

    return isInstance;
}

export function CreateBestProposalsRequestBestProposalsInnerFromJSON(json: any): CreateBestProposalsRequestBestProposalsInner {
    return CreateBestProposalsRequestBestProposalsInnerFromJSONTyped(json, false);
}

export function CreateBestProposalsRequestBestProposalsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBestProposalsRequestBestProposalsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'proposalId': json['proposal_id'],
        'best': json['best'],
        'rewardAmount': !exists(json, 'reward_amount') ? undefined : json['reward_amount'],
        'thanksComment': !exists(json, 'thanks_comment') ? undefined : json['thanks_comment'],
    };
}

export function CreateBestProposalsRequestBestProposalsInnerToJSON(value?: CreateBestProposalsRequestBestProposalsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'proposal_id': value.proposalId,
        'best': value.best,
        'reward_amount': value.rewardAmount,
        'thanks_comment': value.thanksComment,
    };
}

