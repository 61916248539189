/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { VoteTopicRequestVote } from './VoteTopicRequestVote';
import {
    VoteTopicRequestVoteFromJSON,
    VoteTopicRequestVoteFromJSONTyped,
    VoteTopicRequestVoteToJSON,
} from './VoteTopicRequestVote';

/**
 * 
 * @export
 * @interface VoteTopicRequest
 */
export interface VoteTopicRequest {
    /**
     * 
     * @type {VoteTopicRequestVote}
     * @memberof VoteTopicRequest
     */
    vote: VoteTopicRequestVote;
}

/**
 * Check if a given object implements the VoteTopicRequest interface.
 */
export function instanceOfVoteTopicRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "vote" in value;

    return isInstance;
}

export function VoteTopicRequestFromJSON(json: any): VoteTopicRequest {
    return VoteTopicRequestFromJSONTyped(json, false);
}

export function VoteTopicRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): VoteTopicRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vote': VoteTopicRequestVoteFromJSON(json['vote']),
    };
}

export function VoteTopicRequestToJSON(value?: VoteTopicRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vote': VoteTopicRequestVoteToJSON(value.vote),
    };
}

