/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Vote } from './Vote';
import {
    VoteFromJSON,
    VoteFromJSONTyped,
    VoteToJSON,
} from './Vote';

/**
 * 
 * @export
 * @interface VoteTopic201ResponseAllOfData
 */
export interface VoteTopic201ResponseAllOfData {
    /**
     * 
     * @type {Vote}
     * @memberof VoteTopic201ResponseAllOfData
     */
    vote: Vote;
}

/**
 * Check if a given object implements the VoteTopic201ResponseAllOfData interface.
 */
export function instanceOfVoteTopic201ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "vote" in value;

    return isInstance;
}

export function VoteTopic201ResponseAllOfDataFromJSON(json: any): VoteTopic201ResponseAllOfData {
    return VoteTopic201ResponseAllOfDataFromJSONTyped(json, false);
}

export function VoteTopic201ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): VoteTopic201ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vote': VoteFromJSON(json['vote']),
    };
}

export function VoteTopic201ResponseAllOfDataToJSON(value?: VoteTopic201ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vote': VoteToJSON(value.vote),
    };
}

