/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestPayoutRequestPayout
 */
export interface RequestPayoutRequestPayout {
    /**
     * 
     * @type {number}
     * @memberof RequestPayoutRequestPayout
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof RequestPayoutRequestPayout
     */
    currency: RequestPayoutRequestPayoutCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestPayoutRequestPayout
     */
    stripeBankAccountId: string;
}


/**
 * @export
 */
export const RequestPayoutRequestPayoutCurrencyEnum = {
    Jpy: 'jpy',
    Usd: 'usd'
} as const;
export type RequestPayoutRequestPayoutCurrencyEnum = typeof RequestPayoutRequestPayoutCurrencyEnum[keyof typeof RequestPayoutRequestPayoutCurrencyEnum];


/**
 * Check if a given object implements the RequestPayoutRequestPayout interface.
 */
export function instanceOfRequestPayoutRequestPayout(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "stripeBankAccountId" in value;

    return isInstance;
}

export function RequestPayoutRequestPayoutFromJSON(json: any): RequestPayoutRequestPayout {
    return RequestPayoutRequestPayoutFromJSONTyped(json, false);
}

export function RequestPayoutRequestPayoutFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestPayoutRequestPayout {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'currency': json['currency'],
        'stripeBankAccountId': json['stripe_bank_account_id'],
    };
}

export function RequestPayoutRequestPayoutToJSON(value?: RequestPayoutRequestPayout | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'currency': value.currency,
        'stripe_bank_account_id': value.stripeBankAccountId,
    };
}

