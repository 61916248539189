import { useCallback } from "react";
import { FieldValues, FieldPath, useFormContext } from "react-hook-form";

import { ToggleInput } from "../ToggleInput";
import { ToggleInputGroup, ToggleInputGroupProps } from "../ToggleInputGroup";

type Props<Data extends FieldValues = FieldValues> = {
  name: FieldPath<Data>;
} & Omit<ToggleInputGroupProps, "name">;

export const ToggleControlGroup = <Data extends FieldValues = FieldValues>({
  name,
  position,
  inputLabel,
  ...props
}: Props<Data>) => {
  const { register, formState, getFieldState } = useFormContext<Data>();
  const fieldState = getFieldState(name, formState);

  const renderItem = useCallback((props: React.InputHTMLAttributes<HTMLInputElement>) => {
    return (
      <ToggleInput
        {...props}
        {...register(name)}
        label={inputLabel}
        position={position}
      />
    );
  }, [name, inputLabel, position]);

  return (
    <ToggleInputGroup
      {...props}
      renderItem={renderItem}
      invalid={!!fieldState.error}
      error={fieldState.error?.message}
    />
  );
};
