import { immerable } from "immer";

import * as api from "shared/services/api/web/models";

import { MoneyType } from "./money";
import { Proposal, ProposalType } from "./proposal";

export type TopicBestProposalType = api.TopicBestProposal;

export class TopicBestProposal implements TopicBestProposalType {
  [immerable] = true;

  id = "";
  proposal: ProposalType = new Proposal();
  best = false;
  rewardAmount?: MoneyType;
  thanksComment = "";

  constructor(data: Partial<TopicBestProposalType> = {}) {
    Object.assign(this, data);
  }

  getProposal() {
    return new Proposal(this.proposal);
  }
}
