import { immerable } from "immer";

import * as api from "shared/services/api/web/models";

export type CategoryType = api.Category;

export class Category implements CategoryType {
  [immerable] = true;

  id = "";
  name = "";
  topicsCount = 0;
  displayPriority = 0;
  isRoot = false;
  parent?: CategoryType;

  constructor(data: Partial<CategoryType> = {}) {
    Object.assign(this, data);
  }

  getParent() {
    return new Category(this.parent);
  }
}
