/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUserProfileRequestUser
 */
export interface UpdateUserProfileRequestUser {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProfileRequestUser
     */
    handle?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProfileRequestUser
     */
    nickname?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProfileRequestUser
     */
    jobTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProfileRequestUser
     */
    introduction?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProfileRequestUser
     */
    thumbnailImage?: string;
}

/**
 * Check if a given object implements the UpdateUserProfileRequestUser interface.
 */
export function instanceOfUpdateUserProfileRequestUser(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateUserProfileRequestUserFromJSON(json: any): UpdateUserProfileRequestUser {
    return UpdateUserProfileRequestUserFromJSONTyped(json, false);
}

export function UpdateUserProfileRequestUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserProfileRequestUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'handle': !exists(json, 'handle') ? undefined : json['handle'],
        'nickname': !exists(json, 'nickname') ? undefined : json['nickname'],
        'jobTitle': !exists(json, 'job_title') ? undefined : json['job_title'],
        'introduction': !exists(json, 'introduction') ? undefined : json['introduction'],
        'thumbnailImage': !exists(json, 'thumbnail_image') ? undefined : json['thumbnail_image'],
    };
}

export function UpdateUserProfileRequestUserToJSON(value?: UpdateUserProfileRequestUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'handle': value.handle,
        'nickname': value.nickname,
        'job_title': value.jobTitle,
        'introduction': value.introduction,
        'thumbnail_image': value.thumbnailImage,
    };
}

