import { differenceInSeconds, isBefore } from "date-fns";
import { immerable } from "immer";

import * as api from "shared/services/api/web/models";

import { Position, PositionType } from "./position";
import { Support, SupportType } from "./support";
import { Topic, TopicType } from "./topic";
import { TopicBestProposal, TopicBestProposalType } from "./topic_best_proposal";
import { User, UserType } from "./user";

export type ProposalType = api.Proposal;

export type ProposalBodyType = api.ProposalBodyTypeEnum;
export const ProposalBodyType = api.ProposalBodyTypeEnum;

export type ProposalBest = api.ProposalBestEnum;
export const ProposalBest = api.ProposalBestEnum;

export type ProposalStatus = api.ProposalStatusEnum;
export const ProposalStatus = api.ProposalStatusEnum;

export class Proposal implements ProposalType {
  [immerable] = true;

  id = "";
  topicId = "";
  threadId = "";
  body = "";
  bodyText = "";
  bodyData = {};
  bodyType: ProposalBodyType = ProposalBodyType.Editorjs;
  best: ProposalBest = ProposalBest.Normal;
  topicBestProposal?: TopicBestProposalType;
  thanksComment?: string;
  liked = false;
  likesCount = 0;
  clipped = false;
  clipsCount = 0;
  commentsCount = 0;
  supported = false;
  supportsCount = 0;
  status: ProposalStatus = ProposalStatus.Draft;
  user: UserType = new User();
  topic?: TopicType;
  position?: PositionType;
  prioritizedEndAt?: Date;
  prioritizedStartAt?: Date;
  supports?: SupportType[] = [];
  threadProposals: ProposalType[] = [];
  asAnonymous = false;

  constructor(data: Partial<ProposalType> = {}) {
    Object.assign(this, data);
  }

  getUser() {
    return new User(this.user);
  }

  getTopic() {
    return new Topic(this.topic);
  }

  getPosition() {
    return this.position ? new Position(this.position) : undefined;
  }

  getSupports() {
    return this.supports ? this.supports.map((support) => new Support(support)) : [];
  }

  getBestProposal() {
    return this.topicBestProposal ? new TopicBestProposal(this.topicBestProposal) : undefined;
  }

  getThreadProposals() {
    return this.threadProposals.map((proposal) => new Proposal(proposal));
  }

  getPrioritizedStartAt() {
    return this.prioritizedStartAt || new Date();
  }

  getPrioritizedEndAt() {
    return this.prioritizedEndAt || new Date();
  }

  getPrioritizedRemainingSeconds() {
    const seconds = differenceInSeconds(this.getPrioritizedEndAt(), new Date());
    return seconds > 0 ? seconds : 0;
  }

  isLoaded() {
    return this.id !== "";
  }

  isPrioritized() {
    return this.prioritizedEndAt && isBefore(new Date(), this.prioritizedEndAt);
  }

  isBestProposal() {
    return this.best === ProposalBest.Best;
  }

  isBestOrBetterProposal() {
    return this.best === ProposalBest.Best || this.best === ProposalBest.Better;
  }

  hasBestProposal(): this is Proposal & { topicBestProposal: TopicBestProposalType } {
    return !!this.topicBestProposal;
  }
}
