/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BankAccount
 */
export interface BankAccount {
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    bankName: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    accountType?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    accountHolderName?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    last4: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    status: BankAccountStatusEnum;
}


/**
 * @export
 */
export const BankAccountStatusEnum = {
    New: 'new',
    Validated: 'validated',
    Verified: 'verified',
    VerificationFailed: 'verification_failed',
    Errored: 'errored'
} as const;
export type BankAccountStatusEnum = typeof BankAccountStatusEnum[keyof typeof BankAccountStatusEnum];


/**
 * Check if a given object implements the BankAccount interface.
 */
export function instanceOfBankAccount(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "bankName" in value;
    isInstance = isInstance && "last4" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function BankAccountFromJSON(json: any): BankAccount {
    return BankAccountFromJSONTyped(json, false);
}

export function BankAccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): BankAccount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'bankName': json['bank_name'],
        'accountType': !exists(json, 'account_type') ? undefined : json['account_type'],
        'accountHolderName': !exists(json, 'account_holder_name') ? undefined : json['account_holder_name'],
        'last4': json['last4'],
        'status': json['status'],
    };
}

export function BankAccountToJSON(value?: BankAccount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'bank_name': value.bankName,
        'account_type': value.accountType,
        'account_holder_name': value.accountHolderName,
        'last4': value.last4,
        'status': value.status,
    };
}

