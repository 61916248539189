import { immerable } from "immer";

export type MagazineTagType = {
  id: number;
  name: string;
  slug: string;
};

export class MagazineTag implements MagazineTagType {
  [immerable] = true;

  id = 0;
  name = "";
  slug = "";

  constructor(data: Partial<MagazineTagType> = {}) {
    Object.assign(this, data);
  }
}
