/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TopicNote
 */
export interface TopicNote {
    /**
     * 
     * @type {string}
     * @memberof TopicNote
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TopicNote
     */
    body: string;
    /**
     * 
     * @type {string}
     * @memberof TopicNote
     */
    bodyText: string;
    /**
     * 
     * @type {object}
     * @memberof TopicNote
     */
    bodyData?: object;
    /**
     * 
     * @type {string}
     * @memberof TopicNote
     */
    bodyType: TopicNoteBodyTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof TopicNote
     */
    postedAt: Date;
}


/**
 * @export
 */
export const TopicNoteBodyTypeEnum = {
    Markdown: 'markdown',
    Editorjs: 'editorjs'
} as const;
export type TopicNoteBodyTypeEnum = typeof TopicNoteBodyTypeEnum[keyof typeof TopicNoteBodyTypeEnum];


/**
 * Check if a given object implements the TopicNote interface.
 */
export function instanceOfTopicNote(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "body" in value;
    isInstance = isInstance && "bodyText" in value;
    isInstance = isInstance && "bodyType" in value;
    isInstance = isInstance && "postedAt" in value;

    return isInstance;
}

export function TopicNoteFromJSON(json: any): TopicNote {
    return TopicNoteFromJSONTyped(json, false);
}

export function TopicNoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopicNote {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'body': json['body'],
        'bodyText': json['body_text'],
        'bodyData': !exists(json, 'body_data') ? undefined : json['body_data'],
        'bodyType': json['body_type'],
        'postedAt': (new Date(json['posted_at'])),
    };
}

export function TopicNoteToJSON(value?: TopicNote | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'body': value.body,
        'body_text': value.bodyText,
        'body_data': value.bodyData,
        'body_type': value.bodyType,
        'posted_at': (value.postedAt.toISOString()),
    };
}

