import { useEffect, useMemo } from "react";
import { FieldValues, FieldPath, useFormContext, PathValue } from "react-hook-form";

import { ErrorMessage } from "../ErrorMessage";

type Props<Data extends FieldValues = FieldValues> = {
  name: FieldPath<Data>;
  value: PathValue<Data, FieldPath<Data>>;
  overrideErrorMessage?: (error: string) => string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const HiddenControlGroup = <Data extends FieldValues = FieldValues>({
  name,
  value,
  overrideErrorMessage,
  ...props
}: Props<Data>) => {
  const { register, setValue, getFieldState } = useFormContext<Data>();
  const { error } = getFieldState(name);

  const errorMessage = useMemo(() => {
    if (!error?.message) return undefined;
    return overrideErrorMessage ? overrideErrorMessage(error.message) : error.message;
  }, [error, overrideErrorMessage]);

  useEffect(() => {
    setValue(name, value);
  }, [name, value]);

  return (
    <>
      <input
        {...props}
        {...register(name)}
        type="hidden"
      />
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </>
  );
};
