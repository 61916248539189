import qs from "qs";

export const routes = {
  CATEGORY_TOPICS: (id: string) => `/categories/${id}/topics`,
  CLIPPED_TOPICS: "/clipped/topics",
  CLIPPED_PROPOSALS: "/clipped/proposals",
  CLIPPED_COMMENTS: "/clipped/comments",
  DOCUMENTS_PRIVACY_POLICY: "/documents/privacy_policy",
  DOCUMENTS_TERMS_OF_SERVICE: "/documents/terms_of_service",
  DOCUMENTS_TOKUSHOHO: "/documents/tokushoho",
  FAQ: "https://western-day-f05.notion.site/2468305f909c47e985ff8f9291f5a14d",
  INQUIRIES_NEW: "/inquiries/new",
  INQUIRIES_SHOW: (id: string) => `/inquiries/${id}`,
  LANDING_PAGE: "https://toiny.net/magazine/articles/released-toiny-beta",
  MAGAZINE_TOP: "/magazine",
  MAGAZINE_CATEGORIES_SHOW: (id: string) => `/magazine/categories/${id}`,
  MAGAZINE_ARTICLES_SHOW: (id: string) => `/magazine/articles/${id}`,
  MYPAGE: "/mypage",
  MYPAGE_EMAIL_EDIT: "/mypage/email/edit",
  MYPAGE_EMAIL_MARKETING_AGREEMENTS: "/mypage/email_marketing_agreements",
  MYPAGE_PASSWORD_EDIT: "/mypage/password/edit",
  MYPAGE_PAYMENT_METHODS: "/mypage/payment_methods",
  MYPAGE_PAYMENT_METHODS_NEW: "/mypage/payment_methods/new",
  MYPAGE_PAYOUTS: (currency: string) => `/mypage/payouts?${qs.stringify({ currency })}`,
  MYPAGE_PROFILE_EDIT: "/mypage/profile/edit",
  MYPAGE_PROVIDERS: "/mypage/providers",
  MYPAGE_REVENUES: "/mypage/revenues",
  MYPAGE_STRIPE_ACCOUNT: "/mypage/stripe/account",
  MYPAGE_STRIPE_CONNECT: "/mypage/stripe/connect",
  MYPAGE_DEACTIVATE: "/mypage/deactivate",
  NOTIFICATIONS: "/notifications",
  SEARCH_TOPICS: (keyword?: string) => `/search/topics?${qs.stringify({ keyword })}`,
  SEARCH_PROPOSALS: (keyword?: string) => `/search/proposals?${qs.stringify({ keyword })}`,
  SEARCH_USERS: (keyword?: string) => `/search/users?${qs.stringify({ keyword })}`,
  TOPICS_SHOW: (id: string) => `/topics/${id}`,
  TOPICS_NEW: "/topics/new",
  TOPICS_PROPOSALS_SHOW: (id: string, proposalId: string) => `/topics/${id}/proposals/${proposalId}`,
  TOPICS_PROPOSALS_NEW: (id: string, positionId?: string, threadId?: string) => `/topics/${id}/proposals/new?${qs.stringify({ positionId, threadId })}`,
  TOPICS_POSITIONS_SHOW: (id: string, positionId: string) => `/topics/${id}/positions/${positionId}`,
  TOPICS_VOTES: (id: string) => `/topics/${id}/votes`,
  TOPICS_NOTES_NEW: (id: string) => `/topics/${id}/notes/new`,
  TOPICS_SELECT_BEST_PROPOSAL: (id: string) => `/topics/${id}/select_best_proposal`,
  PROPOSALS_SUPPORTERS: (id: string) => `/proposals/${id}/supporters`,
  SNS_TWITTER: "https://twitter.com/toiny_net",
  TOP: "/",
  USERS_SIGN_IN: (origin?: string | null) => `/users/sign_in?${qs.stringify({ origin })}`,
  USERS_SIGN_UP: (origin?: string | null) => `/users/sign_up?${qs.stringify({ origin })}`,
  USERS_PASSWORD_NEW: "/users/password/new",
  USERS_AUTH_WITH_TWITTER: (params: { return_to?: string } = {}) =>
    `${process.env.NEXT_PUBLIC_API_ORIGIN}/users/auth/twitter?${qs.stringify(params)}`,
  USERS_SHOW: (id: string) => `/users/${id}`,
  USERS_TOPICS: (id: string) => `/users/${id}/topics`,
  USERS_COMMENTS: (id: string) => `/users/${id}/comments`,
};
