import { immerable } from "immer";

import * as api from "shared/services/api/web/models";

import { AttachableImageType } from "./attachable_image";
import { Category, CategoryType } from "./category";
import { Money, MoneyType } from "./money";
import { Proposal, ProposalType } from "./proposal";
import { User, UserType } from "./user";
import { UserMeType } from "./user_me";

export type TopicType = api.Topic;

export type TopicBodyType = api.TopicBodyTypeEnum;
export const TopicBodyType = api.TopicBodyTypeEnum;

export type TopicRewardType = api.TopicRewardTypeEnum;
export const TopicRewardType = api.TopicRewardTypeEnum;

export type TopicStatus = api.TopicStatusEnum;
export const TopicStatus = api.TopicStatusEnum;

export type TopicVisibility = api.TopicVisibilityEnum;
export const TopicVisibility = api.TopicVisibilityEnum;

export type TopicCoverImageType = AttachableImageType & {
  webp: AttachableImageType;
};

export class Topic implements TopicType {
  [immerable] = true;

  id = "";
  title = "";
  body = "";
  bodyText = "";
  bodyData = {};
  bodyType: TopicBodyType = TopicBodyType.Editorjs;
  rewardType: TopicRewardType = TopicRewardType.None;
  rewardAmount?: MoneyType;
  rewardAnswersAmount = 1;
  likesCount = 0;
  proposalsCount = 0;
  clipped = false;
  clipsCount = 0;
  supported = false;
  supportsCount = 0;
  fixed = false;
  status: TopicStatus = TopicStatus.Pending;
  visibility: TopicVisibility = TopicVisibility.Open;
  user: UserType = new User();
  categories: CategoryType[] = [];
  proposal?: ProposalType;
  expiresAt?: Date;
  publicExpiresAt?: Date;
  rewardExpiresAt?: Date;
  publishedAt = new Date();
  coverImage?: TopicCoverImageType;
  isOwner = false;

  constructor(data: Partial<TopicType> = {}) {
    Object.assign(this, data);
  }

  getCategory(): Category | undefined {
    return this.categories.length > 0 ? new Category(this.categories[0]) : undefined;
  }

  getCategories(): Category[] {
    return this.categories.map((category) => new Category(category));
  }

  getUser() {
    return new User(this.user);
  }

  getProposal(): Proposal | undefined {
    return this.proposal ? new Proposal(this.proposal) : undefined;
  }

  hasReward() {
    return this.rewardType !== TopicRewardType.None;
  }

  getRewardAmount() {
    return new Money(this.rewardAmount);
  }

  rewardTypeIsAllForBest() {
    return this.rewardType === TopicRewardType.AllForBest;
  }

  rewardTypeIsDistributeByOwner() {
    return this.rewardType === TopicRewardType.DistributeByOwner;
  }

  rewardTypeIsDistributeByAuto() {
    return this.rewardType === TopicRewardType.DistributeByAuto;
  }

  hasCoverImage(): this is Topic & { coverImage: TopicCoverImageType } {
    return !!this.coverImage;
  }

  hasProposal() {
    return !!this.proposal;
  }

  isOpen() {
    return this.visibility === TopicVisibility.Open;
  }

  isClosed() {
    return this.visibility === TopicVisibility.Closed;
  }

  isFixed() {
    return this.fixed;
  }

  isExpired() {
    return this.expiresAt && new Date() > new Date(this.expiresAt);
  }
}

// TODO: helperメソッドは別ファイルに移動する
export function formatTopicRewardType(type: TopicRewardType) {
  switch (type) {
    case TopicRewardType.AllForBest:
      return "All for Best";
    case TopicRewardType.DistributeByOwner:
      return "Distribute by Owner";
    default:
      return "-";
  }
}
