/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResponseErrorBadRequest } from './ResponseErrorBadRequest';
import {
    ResponseErrorBadRequestFromJSON,
    ResponseErrorBadRequestFromJSONTyped,
    ResponseErrorBadRequestToJSON,
} from './ResponseErrorBadRequest';
import type { ResponseErrorInvalidRecord } from './ResponseErrorInvalidRecord';
import {
    ResponseErrorInvalidRecordFromJSON,
    ResponseErrorInvalidRecordFromJSONTyped,
    ResponseErrorInvalidRecordToJSON,
} from './ResponseErrorInvalidRecord';
import type { ResponseErrorInvalidRecordAllOfError } from './ResponseErrorInvalidRecordAllOfError';
import {
    ResponseErrorInvalidRecordAllOfErrorFromJSON,
    ResponseErrorInvalidRecordAllOfErrorFromJSONTyped,
    ResponseErrorInvalidRecordAllOfErrorToJSON,
} from './ResponseErrorInvalidRecordAllOfError';

/**
 * 
 * @export
 * @interface ConnectUserProvider400Response
 */
export interface ConnectUserProvider400Response {
    /**
     * 
     * @type {ResponseErrorInvalidRecordAllOfError}
     * @memberof ConnectUserProvider400Response
     */
    error: ResponseErrorInvalidRecordAllOfError;
}

/**
 * Check if a given object implements the ConnectUserProvider400Response interface.
 */
export function instanceOfConnectUserProvider400Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "error" in value;

    return isInstance;
}

export function ConnectUserProvider400ResponseFromJSON(json: any): ConnectUserProvider400Response {
    return ConnectUserProvider400ResponseFromJSONTyped(json, false);
}

export function ConnectUserProvider400ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectUserProvider400Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': ResponseErrorInvalidRecordAllOfErrorFromJSON(json['error']),
    };
}

export function ConnectUserProvider400ResponseToJSON(value?: ConnectUserProvider400Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': ResponseErrorInvalidRecordAllOfErrorToJSON(value.error),
    };
}

