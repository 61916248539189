/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Money } from './Money';
import {
    MoneyFromJSON,
    MoneyFromJSONTyped,
    MoneyToJSON,
} from './Money';

/**
 * 
 * @export
 * @interface Balance
 */
export interface Balance {
    /**
     * 
     * @type {Money}
     * @memberof Balance
     */
    amount: Money;
    /**
     * 
     * @type {string}
     * @memberof Balance
     */
    status: BalanceStatusEnum;
}


/**
 * @export
 */
export const BalanceStatusEnum = {
    Available: 'available',
    Pending: 'pending'
} as const;
export type BalanceStatusEnum = typeof BalanceStatusEnum[keyof typeof BalanceStatusEnum];


/**
 * Check if a given object implements the Balance interface.
 */
export function instanceOfBalance(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function BalanceFromJSON(json: any): Balance {
    return BalanceFromJSONTyped(json, false);
}

export function BalanceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Balance {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': MoneyFromJSON(json['amount']),
        'status': json['status'],
    };
}

export function BalanceToJSON(value?: Balance | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': MoneyToJSON(value.amount),
        'status': value.status,
    };
}

