import { useCallback } from "react";
import { FieldValues, FieldPath, useFormContext } from "react-hook-form";

import { CheckboxInput } from "../CheckboxInput";
import { CheckboxInputsGroup, CheckboxInputsGroupProps, CheckboxInputsGroupItem } from "../CheckboxInputsGroup";

type Props<Data extends FieldValues = FieldValues> = {
  name: FieldPath<Data>;
} & Omit<CheckboxInputsGroupProps, "name">;

export const CheckboxControlsGroup = <Data extends FieldValues = FieldValues>({
  name,
  ...props
}: Props<Data>) => {
  const { register, formState, getFieldState } = useFormContext<Data>();
  const fieldState = getFieldState(name, formState);

    const renderItem = useCallback((item: CheckboxInputsGroupItem) => {
    return (
      <CheckboxInput
        key={item.value}
        {...register(name)}
        value={item.value}
        label={item.label}
      />
    );
  }, [name]);

  return (
    <CheckboxInputsGroup
      {...props}
      renderItem={renderItem}
      invalid={!!fieldState.error}
      error={fieldState.error?.message}
    />
  );
};
