/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Money } from './Money';
import {
    MoneyFromJSON,
    MoneyFromJSONTyped,
    MoneyToJSON,
} from './Money';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Supporter
 */
export interface Supporter {
    /**
     * 
     * @type {User}
     * @memberof Supporter
     */
    user: User;
    /**
     * 
     * @type {Array<Money>}
     * @memberof Supporter
     */
    amounts: Array<Money>;
    /**
     * 
     * @type {number}
     * @memberof Supporter
     */
    sumAmountCents: number;
}

/**
 * Check if a given object implements the Supporter interface.
 */
export function instanceOfSupporter(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "amounts" in value;
    isInstance = isInstance && "sumAmountCents" in value;

    return isInstance;
}

export function SupporterFromJSON(json: any): Supporter {
    return SupporterFromJSONTyped(json, false);
}

export function SupporterFromJSONTyped(json: any, ignoreDiscriminator: boolean): Supporter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': UserFromJSON(json['user']),
        'amounts': ((json['amounts'] as Array<any>).map(MoneyFromJSON)),
        'sumAmountCents': json['sum_amount_cents'],
    };
}

export function SupporterToJSON(value?: Supporter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UserToJSON(value.user),
        'amounts': ((value.amounts as Array<any>).map(MoneyToJSON)),
        'sum_amount_cents': value.sumAmountCents,
    };
}

