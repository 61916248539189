/* tslint:disable */
/* eslint-disable */
/**
 * Web
 * Web向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: scholar@hayabusa-lab.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Money
 */
export interface Money {
    /**
     * 
     * @type {number}
     * @memberof Money
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof Money
     */
    amountCents: number;
    /**
     * 
     * @type {string}
     * @memberof Money
     */
    currency: MoneyCurrencyEnum;
}


/**
 * @export
 */
export const MoneyCurrencyEnum = {
    Jpy: 'jpy',
    Usd: 'usd'
} as const;
export type MoneyCurrencyEnum = typeof MoneyCurrencyEnum[keyof typeof MoneyCurrencyEnum];


/**
 * Check if a given object implements the Money interface.
 */
export function instanceOfMoney(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "amountCents" in value;
    isInstance = isInstance && "currency" in value;

    return isInstance;
}

export function MoneyFromJSON(json: any): Money {
    return MoneyFromJSONTyped(json, false);
}

export function MoneyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Money {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'amountCents': json['amount_cents'],
        'currency': json['currency'],
    };
}

export function MoneyToJSON(value?: Money | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'amount_cents': value.amountCents,
        'currency': value.currency,
    };
}

