import { immerable } from "immer";

import * as api from "shared/services/api/web/models";

import { Money, MoneyType } from "./money";

export type BalanceType = api.Balance;

export type BalanceStatus = api.BalanceStatusEnum;
export const BalanceStatus = api.BalanceStatusEnum;

export class Balance implements BalanceType {
  [immerable] = true;

  amount: MoneyType = new Money();
  status: BalanceStatus = BalanceStatus.Pending;

  constructor(data: Partial<BalanceType> = {}) {
    Object.assign(this, data);
  }

  isPending() {
    return this.status === BalanceStatus.Pending;
  }

  isAvailable() {
    return this.status === BalanceStatus.Available;
  }
}
